import React, { useState, useCallback, useRef, useEffect } from 'react';
import Lottie from 'react-lottie';
import { HiVolumeUp } from 'react-icons/hi';
import { Container } from './styles';
import playIcon from '~/assets/animations/play.json';
import playModal from '~/assets/animations/play-modal.json';

interface IPlayer {
  id: string;
  src: string;
  thumbnail: string;
  onTimeUpdate?(event: React.SyntheticEvent<HTMLVideoElement, Event>): void;
  onPlay?(event: React.SyntheticEvent<HTMLVideoElement, Event>): void;
  onEnded?(event: React.SyntheticEvent<HTMLVideoElement, Event>): void;
  onLoad?(video: any): void;
  autoPlay?: boolean;
  className?: string;
  muted?: boolean;
  buttonId?: string;
}

const Player: React.FC<IPlayer> = ({
  id,
  src,
  thumbnail,
  onTimeUpdate,
  onPlay,
  onEnded,
  onLoad,
  autoPlay,
  className,
  muted,
  buttonId,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [play, setPlay] = useState(false);
  const [volume, setVolume] = useState<number>(1);

  useEffect(() => {
    if (autoPlay && videoRef.current) {
      setPlay(true);
      videoRef.current.play();
    }
  }, [autoPlay]);

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const handleVideoEnded = (
    e: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.setAttribute('poster', thumbnail);
      video.pause();
    }

    if (onEnded) {
      onEnded(e);
    }
  };

  const handleClick = useCallback(() => {
    const vid = document.getElementById(id) as HTMLVideoElement;
    if (vid) {
      if (!play) {
        vid.play();
      } else {
        vid.pause();
      }
    }

    setPlay((state) => !state);
  }, [id, play]);

  return (
    <Container className={`video w-100 position-relative ${className}`}>
      <video
        playsInline
        poster={thumbnail}
        onClick={handleClick}
        id={id}
        onTimeUpdate={(e) => {
          if (onTimeUpdate) {
            onTimeUpdate(e);
          }
        }}
        onPlay={onPlay}
        onEnded={(e) => {
          if (onEnded) {
            onEnded(e);
          }
          handleVideoEnded(e);
        }}
        onPause={() => setPlay(false)}
        ref={videoRef}
        onLoadedData={onLoad}
        muted={muted}
      >
        <source src={src} type="video/mp4" />
        <track default kind="captions" srcLang="en" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      {false && (
        <div className="volume d-flex align-items-center justify-content-end mt-n5 pe-4">
          <HiVolumeUp color="#116bff" size={30} className="me-2" />
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
          />
        </div>
      )}
      <button
        id={buttonId}
        type="button"
        className={`${
          play ? 'd-none' : 'd-block'
        } position-absolute border-0 bg-transparent zoom w-100 h-100`}
        onClick={handleClick}
      >
        <Lottie
          options={{
            animationData: playIcon,
            autoplay: true,
            loop: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={id === 'video' ? 276 : 150}
          width={id === 'video' ? 276 : 150}
        />
      </button>
    </Container>
  );
};

export default Player;
