/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Home';
import PrivacyPolicy from '~/pages/PrivacyPolicy';
import TermsService from '~/pages/TermsService';
import RefundPolicy from '~/pages/RefundPolicy';
import CookiePolicy from '~/pages/CookiePolicy';
import SpamPolicy from '~/pages/SpamPolicy';
import Dmca from '~/pages/Dmca';
import ContactConsent from '~/pages/ContactConsent';
import WebinarLive from '~/pages/WebinarLive';
import BlogArticles from '~/pages/BlogArticles';
import BlogAll from '~/pages/BlogAll';
import Webinar from '~/pages/Webinar';
import Discover from '~/pages/Discover';
import LiveVideo from '~/pages/LiveVideo';
import Slo from '~/pages/Slo';
import TestStripe from '~/pages/TestStripe';
import Thanks from '~/pages/Thanks';
import Slo2 from '~/pages/Slo2';
import Slo2DownSell from '~/pages/Slo2DownSell';
import Banners from '~/pages/Banners';
import SloBlackFriday from '~/pages/SloBlackFriday';
import SloPEProUpSell1 from '~/pages/SloPEProUpSell1';
import SloDs1 from '~/pages/SloDs1';
import SloDs2 from '~/pages/SloDs2';
import SloPEProUpSell2 from '~/pages/SloPEProUpSell2';
import SloPEProUpSellPro from '~/pages/SloPEProUpSellPro';
import SloDs1Pro from '~/pages/SloDs1Pro';
import SloDs2Pro from '~/pages/SloDs2Pro';
import SloCongratulations from '~/pages/SloCongratulations';
import VipLastChance from '~/pages/VipLastChance';
import Lp from '~/pages/Lp';
import Lp2 from '~/pages/Lp2';
import PeWelcome from '~/pages/PeWelcome';
import VipUpSell from '~/pages/VipUpSell';
import HomeEmail from '~/pages/HomeEmail';
import UltimateGuide from '~/pages/UltimateGuide';
import ThanksUltimateGuide from '~/pages/ThanksUltimateGuide';
import Mc from '~/pages/Mc';
import Aic from '~/pages/Aic';
import LiveVideoEmail from '~/pages/LiveVideoEmail';
import Agency from '~/pages/Agency';

interface IRoutesProps {
  setPathname: React.Dispatch<React.SetStateAction<string>>;
}

const routes: React.FC<IRoutesProps> = ({ setPathname }) => {
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname, setPathname]);

  return (
    <Switch>
      {/* <Route
        path={`${process.env.PUBLIC_URL}/`}
        component={() => (
          <div className="d-flex align-items-center justify-content-center w-100 vh-100">
            <p className="h3 text-center">
              Sorry for the inconvenience but we're currently undergoing
              maintenance
            </p>
          </div>
        )}
      /> */}
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route
        path={`${process.env.PUBLIC_URL}/test-stripe`}
        component={TestStripe}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/i/:reference`}
        exact
        component={Home}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/i/:reference/:origin`}
        exact
        component={Home}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/privacy-policy`}
        exact
        component={PrivacyPolicy}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/terms-service`}
        exact
        component={TermsService}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/refund-policy`}
        exact
        component={RefundPolicy}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cookie-policy`}
        exact
        component={CookiePolicy}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/spam-policy`}
        exact
        component={SpamPolicy}
      />
      <Route path={`${process.env.PUBLIC_URL}/dmca`} exact component={Dmca} />
      <Route
        path={`${process.env.PUBLIC_URL}/contact-consent`}
        exact
        component={ContactConsent}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/blog`}
        component={BlogAll}
        exact
      />

      <Route
        path={`${process.env.PUBLIC_URL}/blog/:slug`}
        component={BlogArticles}
        exact
      />

      <Route
        path={`${process.env.PUBLIC_URL}/blog/:slug/i/:reference`}
        component={BlogArticles}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/blog/:slug/i/:reference/:origin`}
        component={BlogArticles}
        exact
      />

      <Route
        path={`${process.env.PUBLIC_URL}/banners/:fileName/i/:reference`}
        component={Banners}
        exact
      />
      <Route path={`${process.env.PUBLIC_URL}/lp`} component={Lp} exact />
      <Route
        path={`${process.env.PUBLIC_URL}/lp/i/:reference`}
        component={Lp}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/ultimate-guide`}
        component={UltimateGuide}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/ultimate-guide/i/:reference`}
        component={UltimateGuide}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/vip`}
        component={VipUpSell}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/email`}
        component={HomeEmail}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/pe-welcome`}
        component={PeWelcome}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/thanks`}
        component={ThanksUltimateGuide}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/live-video`}
        component={LiveVideo}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/live-video/i/:reference`}
        component={LiveVideo}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/live-video-email`}
        component={LiveVideoEmail}
        exact
      />
      <Route path={`${process.env.PUBLIC_URL}/mc`} component={Mc} exact />
      <Route path={`${process.env.PUBLIC_URL}/aic`} component={Aic} exact />
      <Route
        path={`${process.env.PUBLIC_URL}/agency`}
        component={Agency}
        exact
      />
    </Switch>
  );
};

export default routes;
