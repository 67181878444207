import React from 'react';
import { useParams } from 'react-router-dom';

interface IParams {
  fileName: string;
  reference: string;
}

const Banners: React.FC = () => {
  const params = useParams<IParams>();

  return (
    <a
      href={`https://autoaffiliate.ai/i/${params.reference}`}
      target="_blank"
      rel="noopener noreferrer"
      id="1417901"
    >
      <img
        src={`https://cdn.autoaffiliate.ai/material-promotion/${params.fileName}`}
        alt="Banner AutoAffiliate"
      />
    </a>
  );
};

export default Banners;
