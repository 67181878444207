import React from 'react';

import { Helmet } from 'react-helmet';
import { Container } from './styles';

const SpamPolicy: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col pt-5 my-5">
            <h1>AutoAffiliate.ai Anti-Spam Policy</h1>
            <h2>Anti-Spam Policy</h2>
            <p>
              At AutoAffiliate, we prioritize the privacy and security of our
              users and are firmly committed to maintaining a spam-free
              environment. Recognizing the importance of responsible email
              communication, we have established this Anti-Spam Policy to
              outline our practices and demonstrate our commitment to ethical
              and legal standards.
            </p>
            <p className="h5 fw-bold">Our Commitment:</p>

            <ul className="my-4">
              <li>
                <b>Strict Opt-In Policy:</b> AutoAffiliate adheres to a
                stringent opt-in policy. We only send commercial emails to
                individuals who have explicitly subscribed to receive them
                through a double opt-in verification process. This ensures each
                subscriber has actively confirmed their desire to receive emails
                from us, thereby reducing the risk of unsolicited emails.
              </li>
              <li>
                <b>Unsubscribe Option:</b> We respect your decision to opt out
                of our communications at any time. Every commercial email from
                AutoAffiliate includes a clear, easy-to-find, and simple-to-use
                unsubscribe link. Clicking this link will promptly remove you
                from our mailing list, ceasing all commercial communications.
              </li>
              <li>
                <b>Compliance with Legal Requirements:</b> AutoAffiliate's email
                practices are designed to comply with all relevant laws and
                regulations, including the CAN-SPAM Act. Our emails contain
                accurate header and subject line information, a valid physical
                address, and an explanation of how the recipient can opt out of
                receiving future communications.
              </li>
              <li>
                <b>Email Authentication and Validation:</b> We implement robust
                email authentication protocols, such as SPF (Sender Policy
                Framework) and DKIM (DomainKeys Identified Mail), to prevent
                email spoofing and phishing. These technologies help ensure the
                integrity and authenticity of our communications.
              </li>
              <li>
                <b>Regular Monitoring and Enforcement:</b> Our team regularly
                monitors compliance with our Anti-Spam Policy. We promptly
                address and resolve any reports of spamming or non-compliance.
                Allegations of unsolicited emails can be reported to
                support@AutoAffiliate.ai for investigation and resolution.
              </li>
              <li>
                <b>Secure Data Handling:</b> Protecting your data is of utmost
                importance to us. AutoAffiliate follows strict data protection
                protocols to prevent unauthorized access, misuse, or disclosure
                of your information. We do not sell or share your personal data
                with third parties for marketing purposes without your explicit
                consent.
              </li>
              <li>
                <b>User Feedback and Support:</b> We value your feedback and
                encourage reporting suspected spam or policy violations. Our
                dedicated team is available to address your concerns and take
                necessary actions to prevent similar issues in the future.
              </li>
            </ul>
            <h3 className="h5 fw-bold pt-4 mb-4">Reporting Spam:</h3>
            <p>
              If you receive what you believe to be spam from an AutoAffiliate
              user, please report it to our support team at{' '}
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai
              </a>{' '}
              with a detailed description of the issue. We take user reports
              seriously and strive to address them promptly and efficiently.
            </p>
            <h3 className="h5 fw-bold pt-4 mb-4">Policy Updates:</h3>
            <p>
              AutoAffiliate reserves the right to modify this Anti-Spam Policy
              at any time to reflect changes in laws or our internal practices.
              Any significant changes will be communicated through our website
              or via direct communication to our users.
            </p>
            <h3 className="h5 fw-bold pt-4 mb-4">Contact Us:</h3>
            <p>
              For further information about our Anti-Spam Policy or if you have
              any questions or concerns, please contact us at:
            </p>
            <p className="mb-0">
              <b>Email:</b>{' '}
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai
              </a>
            </p>
            <p>
              <b>Address:</b> SmartTech Labs Inc. DBA AutoAffiliate, 30 N Gould
              St Ste R, Sheridan, WY, 92801
            </p>
            <p>
              We are dedicated to ensuring a spam-free experience for all our
              users and appreciate your cooperation and understanding in
              maintaining the integrity of our communications.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SpamPolicy;
