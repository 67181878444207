import styled from 'styled-components';

interface IBackground {
  bgImage: string;
}

interface ICopyAlertProps {
  show: boolean;
}

export const Container = styled.div`
  margin-top: 16px;

  .container {
    max-width: 1400px;
  }

  .bg-shadown {
    box-shadow: -1px 31px 99px -73px rgba(0, 0, 0, 0.75);
  }

  .slick-dots {
    bottom: -60px;
  }

  .more {
    border-radius: 16px;
    border: 1px solid #116bff;
    background: #021027;
    width: 237px;
    color: #fff;
    font-family: 'Archivo';
    font-size: 24px;
    font-weight: 500;
    padding: 12px;
  }

  @media screen and (max-width: 991px) {
    margin-top: 0.7rem;
  }
`;

export const BgImage = styled.div<IBackground>`
  //background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.64) 94.79%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url(${(props) => props.bgImage}),
    lightgray 0.536px -623.425px / 100.5% 187.716% no-repeat;

  height: 750px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px;
  padding: 80px;

  span {
    color: #fff;
    font-family: 'Source Sans Pro';
    font-size: 22px;
    font-weight: 600;
    line-height: 160%;
  }

  h2 {
    color: #fff;
    font-family: 'Archivo';
    font-size: 70px;
    font-weight: 600;
    line-height: 100%;
  }

  p {
    color: #fff;
    font-family: 'Source Sans Pro';
    font-size: 22px;
    font-weight: 300;
    line-height: 160%;
  }

  .banner-title {
    max-width: 1244px;
  }

  @media screen and (max-width: 1400px) {
    height: 550px;
    padding: 60px;

    span {
      font-size: 18px;
    }

    h2 {
      font-size: 50px;
    }

    p {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 991px) {
    height: 550px;
    padding: 30px;

    span {
      font-size: 22px;
    }

    h2 {
      font-size: 42px;
    }

    p {
      font-size: 22px;
    }
  }
`;

export const AllArticles = styled.div`
  h2 {
    color: #000;
    font-family: 'Archivo';
    font-size: 28px;
    font-weight: 500;
    line-height: 125%;
  }

  h3 {
    color: #151517;
    font-family: 'Archivo';
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    min-height: 52px;
  }

  p {
    color: #676767;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    min-height: 116px;
  }

  a {
    color: #000;
    font-family: 'Archivo';
    font-size: 18px;
    font-weight: 500;
    line-height: 125%;
    transition-duration: 0.2s;
    :hover {
      opacity: 0.8;
    }
  }

  button {
    color: #116bff;
    font-family: 'Archivo';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.72px;
    transition-duration: 0.2s;
    :hover {
      opacity: 0.8;
    }
  }

  .num-clap {
    color: #4c4c4c;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 300;
    line-height: 160%;
  }

  .thumb {
    border-radius: 20px;
    background: #f5f3ed;
    box-shadow: 0px 19px 70px 0px rgba(0, 0, 0, 0.15);
  }

  .bg-gradient-others::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }

  @media screen and (max-width: 1400px) {
    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    p,
    a,
    button {
      font-size: 16px;
    }
  }
`;

export const Discover = styled.div`
  .zoom {
    zoom: 0.6;
  }

  .bg-discover button {
    font-family: 'Archivo', sans-serif;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: #fff;
    background: #116bff;
    border-radius: 20px;
    padding: 22px 50px;
    border: none;
    transition-duration: 0.3s;

    :hover {
      opacity: 0.8;
    }
  }

  .bg-discover {
    background: #021027;
    border-radius: 65px;
  }

  .bg-discover {
    h2,
    h3,
    p {
      font-family: 'Poppins', sans-serif;
    }
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #37ebaa;
    }
    h2 {
      font-weight: 600;
      font-size: 56px !important;
      line-height: 60px;
      color: #f3f4f8;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #b1b1b5;
    }
  }

  .discover1 {
    top: 5%;
    left: 5%;
  }

  .discover2 {
    top: 42%;
    left: 8%;
  }

  .discover3 {
    top: 73%;
    left: 20%;
  }

  .discover4 {
    top: 5%;
    right: 5%;
  }

  .discover5 {
    top: 35%;
    right: 3%;
  }

  .discover6 {
    top: 67%;
    right: 10%;
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-weight: 300;
      font-size: 38px !important;
      span:first-child {
        font-size: 56px;
      }
      span:nth-child(2) {
        font-weight: 500;
        font-size: 53px;
      }
    }

    h3 {
      font-size: 22px;
    }
    button {
      font-size: 24px;
      padding: 22px 69px;
    }

    .bg-discover h2 {
      font-size: 46px !important;
      line-height: 60px;
    }

    .bg-discover p {
      font-size: 18px;
      line-height: 32px;
    }

    .discover1 {
      top: 8%;
      left: 5%;
      width: 130px;
    }

    .discover4 {
      top: 2%;
      right: 2%;
    }

    .discover5 {
      top: 67%;
      right: 0%;
      width: 172px;
    }

    .discover6 {
      top: 51%;
      right: auto;
      left: 2%;
      width: 130px;
    }

    .disclaimer p {
      font-size: 16px;
    }

    .disclaimer h2 {
      font-size: 26px;
    }
  }

  @media screen and (min-width: 576px) {
    h2 {
      font-weight: 300;
      font-size: 38px;
      span:first-child {
        font-size: 56px;
      }
      span:nth-child(2) {
        font-weight: 500;
        font-size: 53px;
      }
    }

    h3 {
      font-size: 22px;
    }

    .bg-discover h2 {
      font-size: 40px;
    }

    .discover3 {
      left: 7%;
    }
  }

  @media screen and (min-width: 768px) {
    button {
      padding: 22px 82px;
    }

    .disclaimer {
      border: none;
    }

    .disclaimer p {
      font-family: 'Inter', sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 133.34%;
      color: #797979;
      span {
        font-weight: 500;
      }
    }
  }

  @media screen and (min-width: 992px) {
    h2 {
      font-weight: 500;
      font-size: 53px;
      line-height: 65px;
    }

    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }
`;
