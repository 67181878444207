/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { IoIosShareAlt } from 'react-icons/io';
import { FiArrowUpRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useResize } from '~/hooks/Resize';
import api from '~/services/api';
import { Container, AllArticles, BgImage } from './styles';

import clap from '~/assets/icons/clap-icon.svg';
import ModalShareDesk from '~/components/ModalShareDesk';

interface IArticleFiles {
  id: string;
  file_url: string;
}

interface IArticle {
  id: string;
  title: string;
  meta: string;
  body: string;
  likes: number;
  slug: string;
  articleFiles: IArticleFiles[];
}

const BlogAll: React.FC = () => {
  const { width } = useResize();
  const [highlightsArticles, setHighlightsArticles] = useState<IArticle[]>([]);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [reference, setReference] = useState('teste');
  const [show, setShow] = useState(false);
  const [slug, setSlug] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    arrows: false,
  };

  useEffect(() => {
    const referenceData = localStorage.getItem('@AutoAffiliate:reference');
    if (referenceData) {
      setReference(referenceData);
    }
  }, []);

  const handleLoadArticles = useCallback(async (page) => {
    const response = await api.get('articles', {
      params: {
        page,
      },
    });

    if (page === 1) {
      setArticles(response.data.data);
    } else {
      setArticles((state) => [...state, ...response.data.data]);
    }
    setTotalPages(response.data.totalPages);
  }, []);

  useEffect(() => {
    api
      .get(`articles/highlight`)
      .then((response) => {
        handleLoadArticles(1);
        setHighlightsArticles(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [handleLoadArticles]);

  const handleShareMobile = useCallback(
    async (slugArticle) => {
      try {
        await (navigator as any).share({
          url: `https://autoaffiliate.ai/blog/${slugArticle}/i/${reference}`,
        });
        console.log('Share successful');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    },
    [reference]
  );

  const handleShareDesk = useCallback((slugOthersArticle) => {
    setShow(true);
    setSlug(slugOthersArticle);
  }, []);

  const handleClickLoadMore = useCallback(
    (page) => {
      if (page <= totalPages) {
        handleLoadArticles(page);
        setCurrentPage(page);
      }
    },
    [handleLoadArticles, totalPages]
  );

  const handleClickLike = useCallback(
    async (articleIndex) => {
      const newArticles = articles.slice();
      const isLiked = localStorage.getItem(
        `@AutoAffiliate:likeArticle-${newArticles[articleIndex].id}`
      );
      await api.patch(`articles/like/${newArticles[articleIndex].id}`, {
        like: isLiked ? -1 : 1,
      });

      newArticles[articleIndex].likes += isLiked ? -1 : 1;

      if (isLiked) {
        localStorage.removeItem(
          `@AutoAffiliate:likeArticle-${newArticles[articleIndex].id}`
        );
      } else {
        localStorage.setItem(
          `@AutoAffiliate:likeArticle-${newArticles[articleIndex].id}`,
          'true'
        );
      }

      setArticles(newArticles);
    },
    [articles]
  );

  return (
    <Container>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col bg-shadown">
            <Slider {...settings}>
              {highlightsArticles.map((item) => (
                <Link
                  to={`${process.env.PUBLIC_URL}/blog/${item.slug}/i/${reference}`}
                >
                  <BgImage bgImage={item.articleFiles[0].file_url}>
                    <div className="mx-auto h-100 d-flex align-items-end banner-title e">
                      <div className="">
                        <span>Featured</span>
                        <h2>{item.title}</h2>
                        <p>{item.meta}</p>
                      </div>
                    </div>
                  </BgImage>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <AllArticles className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="pt-5 my-5">Recent articles</h2>
          </div>
          {articles.map((article, index) => (
            <div className="col-md-6 col-lg-4 px-xl-4 mb-5">
              <div>
                <Link
                  to={`${process.env.PUBLIC_URL}/blog/${article.slug}/i/${reference}`}
                >
                  <div className="position-relative bg-gradient-others">
                    <img
                      src={article.articleFiles[0].file_url}
                      alt="Image"
                      className="thumb w-100"
                    />
                  </div>
                  <h3 className="mt-5">{article.title}</h3>
                  <p className="py-3 mb-0">{article.meta}</p>
                </Link>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog/${article.slug}/i/${reference}`}
                    >
                      Read article <FiArrowUpRight color="#000" size={22} />
                    </Link>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="border-0"
                      onClick={() => handleClickLike(index)}
                    >
                      <span className="num-clap me-3">
                        <img src={clap} alt="Clap" className="mx-2" />
                        {article.likes}
                      </span>
                    </button>
                    <button
                      type="button"
                      className="btn-share-it bg-transparent border-0"
                      onClick={() => {
                        if (width > 991) {
                          handleShareDesk(article.slug);
                        } else {
                          handleShareMobile(article.slug);
                        }
                      }}
                    >
                      Share it <IoIosShareAlt color="#116BFF" size={30} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </AllArticles>
      {currentPage !== totalPages && (
        <div className="text-center my-5">
          <button
            type="button"
            className="more"
            onClick={() => handleClickLoadMore(currentPage + 1)}
          >
            Load more
          </button>
        </div>
      )}

      <ModalShareDesk
        show={show}
        onHide={() => setShow(false)}
        reference={reference}
        slug={slug}
      />
    </Container>
  );
};

export default BlogAll;
