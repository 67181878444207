import React from 'react';

import { Helmet } from 'react-helmet';
import { Container } from './styles';

const CookiePolicy: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col pt-5 my-5">
            <h1>
              AutoAffiliate.ai Cookie and <br className="d-none d-xl-block" />
              Consent Policy
            </h1>
            <h2>Cookie and Consent Policy</h2>
            <p>
              <b>Effective Date: June 15, 2023</b>
            </p>
            <p>
              Welcome to AutoAffiliate.ai. We respect your privacy and are
              committed to protecting it through our compliance with this
              policy. This policy explains how we use cookies and similar
              technologies to recognize you when you visit our website. It
              explains what these technologies are and why we use them, as well
              as your rights to control our use of them.
            </p>
            <h3 className="h4 fw-semibold pt-4 mb-4">Consent:</h3>
            <p>
              By using AutoAffiliate.ai, you consent to the use of cookies in
              accordance with this Cookie and Consent Policy. This includes
              cookies that are necessary for the operation of our site, as well
              as those that are used for analytics, marketing, and social media.
            </p>
            <h3 className="h4 fw-semibold pt-4 mb-4">GDPR Compliance:</h3>
            <p>
              In compliance with the General Data Protection Regulation (GDPR),
              we obtain clear and affirmative consent from users in the European
              Union (EU) before placing non-essential cookies on their devices.
              We respect your right to privacy and ensure all data collected by
              cookies is processed in accordance with GDPR and other applicable
              laws.
            </p>
            <h3 className="h4 fw-semibold pt-4 mb-4">
              Detailed Cookie Information:
            </h3>
            <p>Our website uses the following types of cookies:</p>
            <ul className="my-4">
              <li>
                <b>Essential Cookies:</b> Necessary for the website to function
                and cannot be switched off in our systems.
              </li>
              <li>
                <b>Analytical/Performance Cookies:</b> Allow us to recognize and
                count the number of visitors and see how visitors move around
                our website.
              </li>
              <li>
                <b>Functionality Cookies:</b> Enable us to provide enhanced
                functionality and personalization.
              </li>
              <li>
                <b>Targeting Cookies:</b> Record your visit to our website, the
                pages you have visited, and the links you have followed.
              </li>
            </ul>
            <p>
              For a detailed list of the cookies used on our website, including
              their purpose and lifespan, please reach out to
              support@autoaffilaite.ai
            </p>
            <h3 className="h4 fw-semibold pt-4 mb-4">
              User Rights Under GDPR:
            </h3>
            <p>You have the following rights under GDPR:</p>
            <ul className="my-4">
              <li className="fw-semibold">
                The right to access your personal data.
              </li>
              <li className="fw-semibold">The right to rectification.</li>
              <li className="fw-semibold">The right to erasure.</li>
              <li className="fw-semibold">The right to restrict processing.</li>
              <li className="fw-semibold">The right to data portability.</li>
              <li className="fw-semibold">
                The right to object to processing.
              </li>
            </ul>
            <h3 className="h4 fw-semibold pt-4 mb-4">
              How to Exercise Your Rights:
            </h3>
            <p>
              To exercise your GDPR rights, please contact our Data Protection
              Officer (DPO) at DPO@autoaffilaite.ai or use the mechanism
              provided on our website to submit requests.
            </p>
            <h3 className="h4 fw-semibold pt-4 mb-4">
              Cross-Border Data Transfers:
            </h3>
            <p>
              We comply with GDPR provisions for cross-border data transfers,
              ensuring adequate protection of data transferred outside the EU.
            </p>
            <h3 className="h4 fw-semibold pt-4 mb-4">Consent Withdrawal:</h3>
            <p>
              You can withdraw your consent at any time by modifying your cookie
              settings or contacting us directly. Instructions for deleting or
              blocking cookies can be found in your browser settings.
            </p>
            <h3 className="h4 fw-semibold pt-4 mb-4">Age Restrictions:</h3>
            <p>
              We do not knowingly collect personal data from children under the
              age of 16 without parental consent. If we learn we have collected
              or received personal data from a child under 16, we will delete
              that information.
            </p>
            <h3 className="h4 fw-semibold pt-4 mb-4">
              Updates and Changes to the Policy:
            </h3>
            <p>
              We may update this Cookie and Consent Policy periodically. We will
              notify you of any changes by posting the new policy on this page
              and updating the effective date.
            </p>
            <h3 className="h4 fw-semibold pt-4 mb-4">Contact Information:</h3>
            <p>
              For any questions or concerns regarding this policy or our privacy
              practices, please contact us at:
            </p>
            <p className="mb-0">
              <b>Email:</b>{' '}
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai
              </a>
            </p>
            <p>
              <b>Address:</b> SmartTech Labs Inc. DBA AutoAffiliate, 30 N Gould
              St Ste R, Sheridan, WY, 92801
            </p>
            <p>
              Your continued use of AutoAffiliate.ai, following the posting of
              changes to this policy, will be deemed your acceptance of those
              changes.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CookiePolicy;
