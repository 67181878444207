import styled from 'styled-components';

export const Container = styled.div`
  p {
    font-family: 'Inter', sans-serif;
  }
  .center {
    top: 13px;
    left: 13px;
  }
`;
