import React from 'react';

import { Helmet } from 'react-helmet';
import { Container } from './styles';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col pt-5 my-5">
            <h1>AutoAffiliate.ai Privacy Policy</h1>
            <h2>Privacy Policy</h2>
            <p>
              This Privacy Policy governs your use of AutoAffiliate.ai ("the
              Site") owned by SmartTech Labs, Inc., and its subsidiaries,
              affiliates, and partners. Your privacy is paramount to us, and we
              are committed to protecting it through our compliance with this
              policy. This document outlines our practices regarding the
              collection, use, and disclosure of your information through our
              Services, applicable to both Visitors and Members. By using our
              Services, you agree to the practices described in this policy.
            </p>
            <p>
              <b>Data Controller</b>
            </p>

            <p>
              For compliance with the EU General Data Protection Regulation
              (GDPR) and other applicable laws, SmartTech Labs, Inc., located at
              30 N Gould St Ste 21152, Sheridan, WY, 92801, USA, is the data
              controller. Questions or concerns regarding our Privacy Policy or
              practices should be directed to{' '}
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai
              </a>{' '}
              or{' '}
              <a
                href="mailto:privacy@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                privacy@AutoAffiliate.ai.
              </a>
            </p>
            <h2>AutoAffiliate's Privacy Principles</h2>
            <p>
              AutoAffiliate adheres to the following principles to protect your
              privacy:
            </p>
            <ul>
              <li>
                We only collect essential personally identifiable information
                (PII).
              </li>
              <li>
                We use your PII solely for stated purposes unless you consent
                otherwise.
              </li>
              <li>We retain your PII for no longer than necessary.</li>
              <li>
                Except as specified herein, we do not share your PII with third
                parties.
              </li>
            </ul>
            <h2>Information We Collect</h2>
            <p>
              We collect personally identifiable information when you request
              information from us, sign up for email lists, register for an
              account, participate in promotions, surveys, purchase products or
              services, or contact us. To process your purchases, we may collect
              contact information (such as name, email, or shipping address) and
              financial information (such as credit card number, expiration
              date).
            </p>
            <p>
              We may also collect information from mobile devices used to access
              our services and automatically receive information from your
              browser.
            </p>
            <p>
              In addition to the information you provide directly, we also
              collect data through automated means such as cookies, web beacons,
              and server logs. This includes details about your device, browsing
              actions, and patterns whenever you interact with our Site. We use
              this information primarily to maintain the quality of our service
              and to gather general statistics regarding Site usage.
            </p>
            <p>
              We require your explicit consent for processing personal data.
              When we collect your data, we will inform you of the specific
              purpose and obtain your consent where necessary. You have the
              right to withdraw your consent at any time by contacting us at{' '}
              <a
                href="mailto:privacy@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                privacy@AutoAffiliate.ai.
              </a>
            </p>
            <h2>Text Message Communication & Privacy Policy</h2>

            <p>Effective Date: July 1st, 2023</p>
            <ul className="style-none">
              <li>
                <span className="fw-bold">1.</span>
                <span>
                  <b>Consent to Receive Text Messages:</b> By voluntarily opting
                  into AutoAffiliate's text message alert program, you give your
                  consent to receive SMS messages from AutoAffiliate. The
                  purpose of these messages is to remind you to attend webinars
                  you registered for and occasionally inform you about other
                  products and free training sessions from AutoAffiliate that
                  you may be interested in.
                </span>
              </li>
              <li>
                <span className="fw-bold">2.</span>
                <span>
                  <b>Carriers' Responsibility:</b> Please be aware that the
                  carriers are not responsible for delayed or undelivered
                  messages. While we strive to deliver messages promptly,
                  factors such as network congestion or technical issues may
                  cause delays or failures in message delivery.
                </span>
              </li>
              <li>
                <span className="fw-bold">3.</span>
                <span>
                  <b>Message Frequency:</b> The frequency of messages may vary.
                  We aim to send messages when necessary, such as webinar
                  reminders or important updates. However, please note that we
                  will not inundate you with excessive messages.
                </span>
              </li>
              <li>
                <span className="fw-bold">4.</span>
                <span>
                  <b>Commitment to Privacy and Data Protection:</b>{' '}
                  AutoAffiliate is committed to building trust with users by
                  promoting and complying with business practices that help
                  protect customer privacy and data. We prioritize the security
                  and confidentiality of your personal information. For more
                  details about how we handle your data, please refer to our
                  privacy policy, which can be accessed online.
                </span>
              </li>
              <li>
                <span className="fw-bold">5.</span>
                <span>
                  <b>Charges and Rates:</b> AutoAffiliate does not charge any
                  fees for this text message service. However, please be aware
                  that message and data rates from your mobile carrier may
                  apply. You are responsible for any charges imposed by your
                  mobile carrier for receiving these text messages. By
                  participating in our programs, you approve these charges from
                  your mobile carrier.
                </span>
              </li>
              <li>
                <span className="fw-bold">6.</span>
                <span>
                  <b>Terms and Conditions:</b> Participation in programs using
                  the provided short code has a standard rating, meaning there
                  is no premium content associated with the messages you
                  receive. Text message charges may appear on your bill or be
                  deducted from your prepaid mobile balance.
                </span>
              </li>
              <li>
                <span className="fw-bold">7.</span>
                <span>
                  <b>Right to Cancel Service:</b> AutoAffiliate reserves the
                  right to cancel this SMS service, either in whole or in part,
                  at any time without prior notice. We will make reasonable
                  efforts to notify you of any service changes or
                  discontinuations. However, please note that information in any
                  message may be subject to certain delays and/or backlogs.
                </span>
              </li>
              <li>
                <span className="fw-bold">8.</span>
                <span>
                  <b>Managing Text Message Preferences:</b> You are responsible
                  for managing the types of text messages you receive from
                  AutoAffiliate. If you have any questions or need assistance,
                  you can contact our support team at{' '}
                  <a
                    href="mailto:support@AutoAffiliate.ai"
                    target="_blank"
                    rel="noreferrer"
                  >
                    support@AutoAffiliate.ai
                  </a>{' '}
                  or call (844) 777-0500.
                </span>
              </li>
              <li>
                <span className="fw-bold">9.</span>
                <span>
                  <b>Opting Out:</b> If you wish to stop receiving text messages
                  from AutoAffiliate, simply reply STOP to any SMS message
                  received from us. Upon receiving your opt-out request, you
                  will receive one (1) final message from AutoAffiliate
                  confirming that you have been deactivated in our system. After
                  this confirmation message, no additional text messages will be
                  sent to you unless you choose to reactivate your subscription.
                </span>
              </li>
              <li>
                <span className="fw-bold">10.</span>
                <span>
                  <b>Help and Support:</b> If you require assistance or have any
                  questions about our text message service, you can send a text
                  message with the word "HELP" to receive help or contact our
                  support team at{' '}
                  <a
                    href="mailto:support@AutoAffiliate.ai"
                    target="_blank"
                    rel="noreferrer"
                  >
                    support@AutoAffiliate.ai
                  </a>
                  .
                </span>
              </li>
              <li>
                <span className="fw-bold">11.</span>
                <span>
                  <b>Automatic Dialing System:</b> Please note that
                  AutoAffiliate may use an automatic dialing system to send you
                  text messages. The Federal Communications Commission defines
                  an "automatic telephone dialing system" or autodialer as
                  equipment that has the capacity to store or produce telephone
                  numbers to be called and call such numbers. By subscribing to
                  either the one-time text message program or the subscription
                  text message program, you give your consent to AutoAffiliate
                  to use an automatic dialing system to send text messages to
                  the phone or mobile phone number you have provided.
                </span>
              </li>
              <li>
                <span className="fw-bold">12.</span>
                <span>
                  <b>Participating Carriers:</b> Participating carriers in the
                  United States may include, but are not limited to, ACS/Alaska,
                  Alltel, AT&T, Bluegrass Cellular, Boost, Cellcom, Cellone
                  Nation, Cellular One of East Central Illinois, Cellular South,
                  Centennial, Chariton Valley Cellular, Cincinnati Bell, Cox
                  Communications, Cricket, EKN/Appalachian Wireless, Element
                  Mobile, GCI, Golden State Cellular, Illinois Valley Cellular,
                  Immix/Keystone Wireless, Inland Cellular, iWireless, Metro
                  PCS, Nex-Tech Wireless, nTelos, Plateau Wireless, South
                  Canaan, Sprint, T-Mobile, Thumb Cellular, United Wireless, US
                  Cellular, Verizon Wireless, Viaero Wireless, Virgin, WCC.
                  Additional carriers may be added.
                </span>
              </li>
              <li>
                <span className="fw-bold">13.</span>
                <span>
                  <b>Text Message Communication:</b> In addition to our online
                  services, AutoAffiliate may provide you with the option to
                  receive text message communications. By providing your mobile
                  phone number and opting in to receive text messages, you
                  consent to receive automated text messages from us regarding
                  important updates, promotions, and other relevant information
                  related to our services.
                </span>
              </li>
            </ul>
            <h2>
              By opting in to receive text messages, you acknowledge and agree
              to the following:
            </h2>
            <ol className="style-none">
              <li>
                <span className="fw-bold">1.</span>
                <span>
                  <b>Consent to Receive Text Messages:</b> You consent to
                  receive text messages from AutoAffiliate for the purposes
                  described above. These messages may include promotional
                  content, account notifications, alerts, and other information
                  related to our services. Please note that message and data
                  rates may apply depending on your mobile phone plan.
                </span>
              </li>
              <li>
                <span className="fw-bold">2.</span>
                <span>
                  <b>Opting Out:</b> You have the right to opt out of receiving
                  text messages at any time. To opt out, simply reply to any
                  text message from us with the word "STOP." By doing so, you
                  will no longer receive text messages from us, except for any
                  legally required notifications.
                </span>
              </li>
              <li>
                <span className="fw-bold">3.</span>
                <span>
                  <b>Privacy and Security:</b> We prioritize the privacy and
                  security of your personal information. We will not sell, rent,
                  or lease your mobile phone number or any other personally
                  identifiable information to third parties for their marketing
                  purposes without your explicit consent. However, please be
                  aware that standard text message rates and data charges from
                  your mobile service provider may apply, and we are not
                  responsible for any such charges.
                </span>
              </li>
              <li>
                <span className="fw-bold">4.</span>
                <span>
                  <b>Accuracy of Information:</b> You are responsible for
                  providing and maintaining accurate contact information,
                  including your mobile phone number. Please promptly notify us
                  of any changes to your contact information to ensure that you
                  continue to receive relevant text message communications.
                </span>
              </li>
              <li>
                <span className="fw-bold">5.</span>
                <span>
                  <b>Limitation of Liability:</b> While AutoAffiliate will make
                  reasonable efforts to ensure the timely delivery of text
                  messages, we do not guarantee the delivery or timeliness of
                  any text message communications, as delivery may be subject to
                  factors beyond our control. We are not liable for any delays,
                  failures, or inaccuracies in the delivery of text messages or
                  any consequences arising from such delays, failures, or
                  inaccuracies.
                </span>
              </li>
              <li>
                <span className="fw-bold">6.</span>
                <span>
                  <b>Third-Party Carriers:</b> AutoAffiliate utilizes
                  third-party carriers and service providers to deliver text
                  messages. While we take precautions to ensure the secure
                  transmission of messages, we are not responsible for the
                  actions or omissions of these third-party carriers or service
                  providers. You acknowledge that your mobile service provider
                  may have access to your text message communications, and their
                  use of such information is governed by their own privacy
                  policies.
                </span>
              </li>
              <li>
                <span />
                <span>
                  By opting in to receive text messages from AutoAffiliate, you
                  acknowledge that you have read and understood the terms
                  outlined above. You consent to the use of text message
                  communications as described. If you do not agree with these
                  terms, please do not provide your mobile phone number or opt
                  in to receive text messages.
                </span>
              </li>
              <li>
                <span />
                <span>
                  This AutoAffiliate Text Message Communication Policy is
                  effective as of July 1st, 2023 and may be subject to updates
                  or revisions from time to time. Please review this policy
                  periodically for any changes.
                </span>
              </li>
            </ol>
            <h2>How We Use Your Information</h2>
            <p>Information we collect may be used in the following ways:</p>
            <ul>
              <li>
                To notify you of updates to our Site or any Products or Services
                we offer.
              </li>
              <li>To respond to your requests or inquiries.</li>
              <li>
                To process and complete your transactions, including, as
                applicable, customer service, order confirmation, billing,
                delivering Products or Services, and otherwise fulfill our
                contractual obligations to you.
              </li>
              <li>
                To provide you with newsletters, articles, or programs,
                Products, or Services, alerts, and announcements.
              </li>
              <li>To share offers from other companies with you.</li>
              <li>To conduct market research.</li>
              <li>
                To analyze transactions and purchase histories to improve our
                products, services, programs, and other offerings.
              </li>
              <li>As necessary to verify and validate your identity.</li>
              <li>
                To keep records of our interactions with you if you place an
                order or otherwise deal with our representatives.
              </li>
              <li>
                To prevent, investigate or provide notice of fraud, unlawful or
                criminal activity, or unauthorized access to or use of
                personally identifiable information, our Site or data systems.
              </li>
              <li>To carry out our obligations and enforce our rights.</li>
              <li>
                To fulfill any other purpose for which you provide your
                personally identifiable information to us.
              </li>
            </ul>
            <h2>Your Rights</h2>
            <p>
              You have the right to request a copy of any personally
              identifiable information that we hold about you. If you would like
              a copy of your personally identifiable information, please contact
              us using the contact information below. We may request proof of
              your identity before sharing such information. If you discover
              that the information we hold about you is incorrect or out of
              date, you may ask us to correct that information by emailing us at{' '}
              <a
                href="mailto:privacy@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                privacy@AutoAffiliate.ai.
              </a>
            </p>
            <p>
              You may ask us to delete the personally identifiable data we hold
              about you in certain circumstances. It may not be possible for us
              to delete all of the information we hold about you where we are
              fulfilling a transaction or have a legal basis to retain the
              information, however, please contact us to discuss how we can
              assist you with your request.
            </p>
            <p>
              When we process your information on the basis that you have
              consented to such processing, you have the right to withdraw your
              consent or ask us to stop or restrict processing the personally
              identifiable information we have about you, at any time by
              emailing us at{' '}
              <a
                href="mailto:privacy@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                privacy@AutoAffiliate.ai.
              </a>
            </p>
            <p>
              You may also ask us to transfer your personally identifiable
              information to a third party in certain circumstances. If you
              would like any further information about your rights or how to
              exercise them, please contact us.
            </p>

            <p>
              If you are in the European Union, you have the right to make a
              complaint at any time to the relevant data protection authority in
              your country. We will retain your information for as long as your
              account is active or as needed to fulfill your requests, provide
              you services, comply with our legal obligations, resolve disputes,
              and enforce our agreements.
            </p>
            <p>
              If you receive emails from us about new or existing AutoAffiliate
              Products, Services, and special offers specific to you, you can
              unsubscribe to our emails by clicking "unsubscribe" within each
              email. You may not opt-out of service-related communications,
              which are not promotional in nature, unless you request us to
              delete your account from our system.
            </p>
            <p>
              You have the right to access, correct, or delete your personal
              data stored by us. Additionally, you can object to processing,
              request the restriction of processing, and enjoy the right to data
              portability. To exercise these rights, please access your account
              settings or contact our support team directly.
            </p>
            <p>
              We retain your personal data only for as long as necessary to
              fulfill the purposes we collected it for, including for the
              purposes of satisfying any legal, accounting, or reporting
              requirements. After this period, your data will be either deleted
              or anonymized.
            </p>
            <p>
              Under privacy regulations such as GDPR and CCPA, you have specific
              rights including the right to access and receive a copy of your
              personal data, request deletion, and limit the use of your data.
              To exercise these rights, please contact us at{' '}
              <a
                href="mailto:privacy@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                privacy@AutoAffiliate.ai.
              </a>
            </p>
            <h2>Cookies and Other Tracking Technologies</h2>
            <p>
              As is true of most sites, we gather certain information
              automatically and store it in log files. This information may
              include Internet protocol (IP) addresses, browser type, internet
              service provider (ISP), referring/exit pages, the files viewed on
              our site (e.g., HTML pages, graphics, etc.), operating system,
              date/time stamp, and/or clickstream data to analyze trends in the
              aggregate and administer the site.
            </p>
            <p>
              AutoAffiliate uses cookies or similar technologies to store
              session information, analyze trends, administer the Sites, track
              users' movements around the Sites, and gather demographic
              information about our user base as a whole. A cookie is a small
              amount of data, which often includes an anonymous unique
              identifier, which is sent to your browser from a website's
              computers and stored on your computer's hard drive.
            </p>
            <p>
              We use both session ID cookies and persistent cookies. A session
              ID cookie expires when you close your browser. We use session
              cookies to track your login status. This cookie is only ever
              transmitted over HTTPS. A persistent cookie remains on your hard
              drive for an extended period of time. We use persistent cookies to
              determine from where you were referred to our Sites so that we may
              provide commissions or referral fees, and recognize the last user
              ID that you used to log in. We may set and access AutoAffiliate
              cookies on your computer; cookies are required to use the Sites.
            </p>
            <p>
              Our Site uses different types of cookies: Essential cookies for
              website functionality, Analytics cookies for understanding how our
              site is used, and Advertising cookies for personalizing ads. You
              can manage your cookie preferences through your browser settings
              or by visiting our Cookie Settings page.
            </p>
            <p>
              You can remove persistent cookies by following directions provided
              in your Internet browser's "help" directory. Please go to{' '}
              <a href="www.aboutcookies.org" target="_blank" rel="noreferrer">
                www.aboutcookies.org
              </a>{' '}
              for more information on cookies, including how to disable them. If
              you choose to disable cookies, it may limit your use of certain
              features or functions on our Sites or service. To manage Flash
              cookies, please click here:{' '}
              <a
                href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                target="_blank"
                rel="noreferrer"
              >
                http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
              </a>
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Managing Your Cookie Preferences:
            </h3>
            <p>
              You have the right to decide whether to accept or reject cookies.
              You can exercise your cookie preferences by setting or amending
              your web browser controls to accept or refuse cookies. If you
              choose to reject cookies, you may still use our website though
              your access to some functionality and areas of our website may be
              restricted. As the means by which you can refuse cookies through
              your web browser controls vary from browser-to-browser, you should
              visit your browser's help menu for more information.
            </p>
            <p>
              Additionally, most advertising networks offer you a way to opt out
              of targeted advertising. If you would like to find out more
              information, please visit{' '}
              <a
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noreferrer"
              >
                http://www.aboutads.info/choices/
              </a>{' '}
              or{' '}
              <a
                href="http://www.youronlinechoices.com"
                target="_blank"
                rel="noreferrer"
              >
                http://www.youronlinechoices.com.
              </a>
            </p>
            <h2>Third-Party Tracking</h2>
            <p>
              Third parties also use cookies and other tracking technologies on
              our site, of which data is only collected in the aggregate. The
              use of cookies by third parties is not covered by our Privacy
              Policy. We do not have access or control over these cookies. Third
              parties use session ID and persistent cookies to make it easier
              for you to navigate the Sites, in order for you to use the
              shopping cart, and to otherwise use our services.
            </p>
            <h2>Behavioral Targeting / Re-Targeting</h2>
            <p>
              We partner with a third-party ad network to either display
              advertising on our Site or to manage our advertising on other
              sites. Our ad network partner uses cookies and web beacons to
              collect non-personally identifiable information about your
              activities on this and other websites. This information is used to
              provide you targeted advertising across the internet, based upon
              your interests. If you wish to not have this information used for
              the purpose of serving you targeted ads, you may opt-out by
              clicking here (or if located in the European Union you may change
              your preferences by clicking here). Please note this does not opt
              you out of being served advertising. You will continue to receive
              generic ads.
            </p>
            <h2>Disclosure of Personally Identifiable Information</h2>
            <p>
              Our practices with respect to the disclosure of personally
              identifiable information include the following:
            </p>
            <p>
              <u>Consent.</u> We may disclose your personally identifiable
              information to third parties with your express consent. Data
              collected through the SMS shortcode program will not be shared
              under any circumstances.
            </p>

            <p>
              <u>Vendors and Affiliated Businesses.</u> We share your personally
              identifiable information with our parent company, affiliated
              businesses, and with the vendor(s) whose products you purchase,
              for their use in a manner described in this Privacy Policy.
            </p>
            <p>
              Agents/Service Providers. We use third-party service providers to
              assist us in providing our Services and Sites, including database
              backup providers to protect our data, and fraud prevention
              services. We may disclose your personally identifiable information
              to third parties who complete transactions or perform services on
              our behalf or for your benefit. We may utilize the services of
              third parties to verify customer data, or to supplement other
              information we have collected. We may also contract with third
              parties to host our Sites and to conduct market research. All
              service providers are authorized to use your personally
              identifiable information only as necessary to provide their
              respective services to us and are obligated to protect your
              personally identifiable information under contract terms which are
              no less protective than those set forth in this Policy.
            </p>

            <p>
              <u>Legal Process.</u> We may disclose your personally identifiable
              information as required by law, such as to comply with a subpoena
              or other legal process, or when we believe in good faith that
              disclosure is necessary to protect our rights, protect your safety
              or the safety of others, investigate fraud, or respond to a
              government request.
            </p>

            <p>
              <u>Certain Business Transfers.</u> Your personally identifiable
              information may be disclosed as part of a corporate business
              transaction, such as a merger, acquisition, joint venture, or
              financing or sale of AutoAffiliate assets, and could be
              transferred to a third party as one of the business assets in such
              a transaction. It may also be disclosed in the event of
              insolvency, bankruptcy, or receivership. We will use reasonable
              efforts to direct any such transferee to use your personally
              identifiable information in a manner that is consistent with our
              Privacy Policy.
            </p>

            <p>
              We may share your information with third-party partners who assist
              us in operating our website, conducting our business, or serving
              our users, as long as those parties agree to keep this information
              confidential. Our contracts with third parties detail the
              expectations around the security and handling of your data,
              ensuring they adhere to our privacy standards.
            </p>

            <p>
              You will be notified via email and/or a prominent notice on our
              Sites of any change in ownership or uses of your personally
              identifiable information, as well as any resulting choices you may
              have regarding your personally identifiable information.
            </p>

            <h3 className="h4 fw-bold pt-3 mb-4">
              Third-Party Sharing Details:
            </h3>

            <p>
              While we are committed to safeguarding your personal information,
              there are circumstances under which we might share your data with
              third parties. These include:
            </p>

            <ul>
              <li>
                <b>Service Providers: </b>We engage with trusted service
                providers who perform services on our behalf, such as payment
                processing, data analysis, email delivery, hosting services,
                customer service, and marketing assistance. These service
                providers are bound by contractual obligations to keep personal
                information confidential and use it only for the purposes for
                which we disclose it to them.
              </li>
              <li>
                <b>Legal Requirements: </b>We may disclose your personal
                information if required by law or in the good faith belief that
                such action is necessary to comply with a legal obligation,
                protect and defend our rights or property, act in urgent
                circumstances to protect the personal safety of users of the
                Site or the public, or protect against legal liability.
              </li>
              <li>
                <b>Business Transfers: </b>In the event of a merger,
                acquisition, bankruptcy, dissolution, reorganization, or similar
                transaction or proceeding that involves the transfer of the
                information described in this Privacy Policy.
              </li>
            </ul>

            <h2>Security</h2>
            <p>
              The security of your personally identifiable information is
              important to us. When you enter sensitive information such as
              credit card number on our registration or order forms, we encrypt
              that information using SSL.
            </p>
            <p>
              We follow generally accepted industry standards to protect the
              personally identifiable information submitted to us, both during
              transmission and once we receive it. No method of transmission
              over the Internet, or method of electronic storage, is 100%
              secure, however. Therefore, while we strive to use commercially
              acceptable means to protect your personally identifiable
              information, we cannot guarantee its absolute security.
            </p>
            <p>
              The information we collect about you is stored in limited-access
              servers. We maintain safeguards to protect the security of these
              servers and your personally identifiable information. We retain
              personally identifiable information only as long as necessary to
              complete a purchase, thwart fraud, or provide customer service.
            </p>
            <p>
              In addition to using SSL encryption, we employ advanced security
              measures such as two-factor authentication and regular security
              audits to protect your data. In the unlikely event of a data
              breach, we will notify affected individuals and regulatory
              authorities in accordance with applicable laws.
            </p>
            <p>
              <b>Enhanced Security Measures:</b> In addition to SSL encryption,
              we employ a variety of security measures to enhance the protection
              of your personal information both online and offline. These
              include but are not limited to:
            </p>
            <ul>
              <li>
                <b>Firewalls </b>to prevent unauthorized access to our network.
              </li>
              <li>
                <b>Regular Malware Scanning </b>to detect and prevent malicious
                activities.
              </li>
              <li>
                <b>Secure Socket Layer (SSL) Technology </b>to ensure that your
                information is fully encrypted and sent across the Internet
                securely.
              </li>
              <li>
                <b>Two-Factor Authentication (2FA) </b>for an added layer of
                security during account access and transactions. We also ensure
                that our employees are trained on the importance of maintaining
                confidentiality and security of your information. Access to your
                personal data is strictly limited to personnel who need access
                to perform their job functions.
              </li>
            </ul>
            <h2>Links to Other Sites</h2>
            <p>
              If you click on a link to a third-party site you will leave our
              site and go to the site you selected. Because we cannot control
              the activities of third parties, we cannot accept responsibility
              for any use of your personally identifiable information by such
              third parties, and we cannot guarantee that they will adhere to
              the same privacy practices as us. We encourage you to review the
              privacy policies of any other site you visit or the service
              provider from whom you request services.
            </p>
            <h2>Changes to This Privacy Policy</h2>
            <p>
              We reserve the right to modify this Privacy Policy at any time. If
              we make material changes to this policy, we will notify you here,
              or by means of a notice on the Site. If we make any substantial
              change to the way we use your personally identifiable information,
              we will notify you by email. You will have an opportunity to
              opt-out of such new or different uses of your personally
              identifiable information prior to the change becoming effective.
              We encourage you to periodically review this page for the latest
              information on our privacy practices.
            </p>
            <p>
              <b>Stay Informed on Updates:</b> To keep you informed of any
              changes to our Privacy Policy, we will provide notifications of
              updates through our website and, where appropriate, through direct
              communication via email or text message. We encourage you to
              review our Privacy Policy periodically for the latest information
              on our privacy practices. For substantial changes, we will provide
              more prominent notice, including, for certain services, email
              notification of Privacy Policy changes.
            </p>
            <p>
              We will communicate any changes to our Privacy Policy via our
              website and, if the changes are significant, through a direct
              communication such as email. You are encouraged to periodically
              review this policy for the latest information on our privacy
              practices.
            </p>
            <h2>AutoAffiliate Contact Information</h2>
            <p>
              If you have any questions, comments, or concerns about our Privacy
              Policy, you may contact us by submitting your inquiry to us at:
            </p>
            <p>
              <a
                href="mailto:privacy@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                privacy@AutoAffiliate.ai
              </a>
            </p>
            <p>To reach us by mail, please use the following address:</p>
            <p>SmartTech Labs, Inc.</p>
            <p>30 N Gould St Ste 21152,</p>
            <p>Sheridan, WY, 92801</p>

            <p>
              Please contact us if you have any further questions, or if you
              want to exercise any of your rights or to make a complaint. You
              also have the right to make a complaint to the supervisory
              authority in the country in which you are resident.
            </p>
            <h2>International Transfers</h2>
            <p>
              Wherever you use our Site or access our Services, you understand
              and acknowledge that we may transfer, process, and store
              information about you in the United States and other countries,
              both within and outside of the European Economic Area. The
              countries to which we transfer your personal information may have
              different data protection laws than those in the country in which
              you reside.
            </p>
            <p>
              When we transfer your data internationally, we ensure that we
              comply with applicable laws by relying on approved transfer
              mechanisms such as Standard Contractual Clauses or by verifying
              that the receiving entities are Privacy Shield certified.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
