import styled from 'styled-components';
import { lighten } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Button = styled.button``;

export const Modal = styled(ModalComponent)`
  h2 {
    font-family: 'Inter';
    color: #021027;
    font-size: 50px;
    font-weight: 600;
  }

  h3 {
    font-family: 'Inter';
  }

  h4 {
    font-size: 16px;
    color: #000;
  }
  u {
    font-weight: 600;
    font-size: 12px;
  }

  .btn-blue {
    font-size: 16px;
    color: #fff;
    background-color: #116bff;
    border-radius: 26px;
    padding: 15.5px 38px;
    :hover {
      background-color: ${lighten(0.1, '#116BFF')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .btn-continue {
    font-size: 15px;
    color: #292929;
    :hover {
      background-color: ${lighten(0.1, '#292929')};
      text-decoration: underline;
    }
  }

  .bg-gray {
    background: #f4f4f4;
    border-radius: 26px;
  }
  .padding {
    padding: 27px 20px;
  }

  .pad-button {
    padding: 15.5px 15px;
    font-size: 15px;
    border-radius: 26px;
    :hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }
  .bd-lg-black {
    background: #ffffff;
    border: 1.5px solid #242526;
    border-radius: 26px;

    p {
      color: #000;
      font-size: 15px;
    }
  }
  .text-black {
    color: #000;
  }
  .bg-plan {
    background: #242526;
    color: #fff;
  }
  .bg-blue {
    background-color: #116bff;
    box-shadow: 0px 22px 24px rgba(78, 78, 78, 0.25);
    border-radius: 30px;

    p {
      font-size: 15px;
    }
  }
  .h2-description {
    font-family: 'Inter';
    color: #021027;
    font-size: 18px;
    font-weight: 300;
  }
  .modal-content {
    background: #ffffff;
    box-shadow: 19px 49px 112px rgba(192, 192, 192, 0.4),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(138, 138, 138, 0.5);
    border-radius: 30px;
    border: 0 !important;
  }

  .close-button {
    background-color: transparent;
  }

  @media screen and (min-width: 992px) {
    .text-gradient-lg-table {
      font-size: 18px;
      color: #116bff;
    }
  }

  @media screen and (max-width: 1399px) {
    h2 {
      font-size: 40px;
    }

    .h2-description {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 34px;
    }

    .h2-description {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 991px) {
    .text-mob-gray {
      color: #818181 !important;
    }
    .padding {
      padding: 15px 32px;
    }
    .bg-gray {
      background-color: transparent;
    }
    .pad-button {
      border-radius: 6px;
    }

    .bd-lg-black,
    .bd-mobile {
      border: 1.5px solid #cbccce;
      box-shadow: 0px 22px 24px rgba(213, 207, 177, 0.331184);
    }

    .bd-mobile {
      border-radius: 26px;
    }

    .btn-blue {
      border-radius: 12px;
      width: 100%;
      padding: 19.5px 22px;
    }

    /* .btn-continue {
      background-color: rgba(96, 96, 96, 0.1) !important;
      color: rgba(96, 96, 96, 0.36);
      border-radius: 12px;
      width: 100%;
      padding: 19.5px 22px;
    } */
  }

  @media screen and (max-width: 574px) {
    .h2-description {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 28px;
    }
  }
`;

export const ModalWait = styled(ModalComponent)`
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 4px;
  }
  h2 {
    color: #021027;
    font-family: 'Libre Baskerville';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.75%;
    letter-spacing: -1.12px;
  }

  h3 {
    color: #021027;
    font-family: 'Archivo';
    font-size: 26px;
    font-weight: 400;
    line-height: 37px;
  }

  p,
  li {
    color: #021027;
    font-family: 'Archivo';
    font-size: 21.5px;
    font-weight: 400;
    line-height: 37px;
  }

  .font-libre {
    font-family: 'Libre Baskerville';
  }

  video {
    border: 1px solid #303030;
    -webkit-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
  }

  .bg-danger-span {
    background-color: #ff1a50;
  }

  .text-color-red {
    color: #ff1a50;
  }

  .text-color-blue {
    color: #116bff;
  }

  .font-daughter {
    font-family: 'Architects Daughter';
  }
  .modal-content {
    border-radius: 40px;
    border: 4px dashed #116bff;

    background: #fffdfd;
  }

  .bg-300 {
    background-color: #fff8c2;
  }
  .btn-yes-modal {
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 16px;
    font-weight: 700;
    border-radius: 20px;
    background: #2f80ed;
    border: none;
    height: auto;
    padding: 10px;
    text-transform: capitalize;
    line-height: normal;
  }
  .btn-no-modal {
    color: #ff1a50;
    text-align: center;
    font-family: 'Archivo';
    font-size: 20px;
    font-weight: 700;
    border-radius: 20px;
    background: transparent;
    border: 1px solid #ff1a50;
    height: 74px;
    line-height: normal;
  }

  @media screen and (min-width: 992px) {
    .btn-yes-modal {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 1200px) {
    .btn-yes-modal {
      height: 74px;
    }
  }
`;
