import styled from 'styled-components';

interface IItem {
  active?: boolean;
}

export const Container = styled.div`
  position: relative;
  button[disabled] {
    border: 1px solid #676767;
    background: #fff;

    svg {
      fill: #676767;
    }

    :hover {
      background: #fff;
    }
  }

  .quote {
    width: 18px;
  }

  .slider-count {
    position: absolute;
    right: 21%;
    top: -32px;
    font-family: 'Source Code Pro', sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: #676767;
    span {
      font-weight: 500;
      color: #000;
    }
  }

  @media screen and (min-width: 576px) {
    .quote {
      width: auto;
    }
    .slider-count {
      right: 16%;
      top: -35px;
    }
  }

  @media screen and (min-width: 768px) {
    .quote {
      width: 18px;
    }
  }

  @media screen and (min-width: 992px) {
    .slider-count {
      right: 16%;
      top: -41px;
    }
  }

  @media screen and (min-width: 1200px) {
    .slider-count {
      right: 14%;
      top: -43px;
    }
  }
`;

export const Item = styled.div<IItem>`
  width: 100%;
  border: none;
  background: transparent;
  padding-top: 3rem;
  padding-bottom: 4rem;

  // quando tiver video dos outros testemunhos tirar abaixo
  #videoMembers2,
  #videoMembers3,
  #videoMembers4 {
    pointer-events: none;
  }

  .hide-btn button {
    display: none !important;
  }
  //ate aqui
`;

export const ButtonLeft = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #116bff;
  border: 1px solid #116bff;
  transition-duration: 0.3s;
  bottom: -70px;

  left: unset;
  transform: translateY(-50%);
  top: -2%;
  right: 37%;

  svg {
    fill: #fff;
  }

  :hover,
  :focus {
    background: #116bff;

    svg {
      fill: #ffffff;
    }
  }

  :before,
  :after {
    display: none;
  }

  @media screen and (min-width: 576px) {
    right: 27%;
  }

  @media screen and (min-width: 768px) {
    top: -4%;
    right: 25%;
  }

  @media screen and (min-width: 1200px) {
    right: 21%;
  }
`;

export const ButtonRight = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #116bff;
  border: 1px solid #116bff;

  transition-duration: 0.3s;
  bottom: -70px;
  right: 35%;
  transform: translateY(-50%);
  top: -2%;
  left: unset;
  right: 6%;

  svg {
    fill: #fff;
  }

  :hover,
  :focus {
    background: #116bff;

    svg {
      fill: #ffffff;
    }
  }

  :before,
  :after {
    display: none;
  }

  @media screen and (min-width: 768px) {
    top: -4%;
    right: 8%;
  }
`;
