import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import bgValue from '~/assets/banners/banner-value.png';
import bgPaper from '~/assets/defaults/bg-paper-blue.png';
import bgPaperWhite from '~/assets/defaults/bg-paper-white.png';
import bgPaperWhiteMob from '~/assets/defaults/bg-paper-white-mob.png';
import bgPaperWhiteLg from '~/assets/defaults/bg-paper-white-large.png';
import bgPaperTransparent from '~/assets/banners/paper-bonus.png';
import bgOval from '~/assets/defaults/rounded-guide.png';

import bdTestemonials from '~/assets/defaults/bd-testemonials.png';

export const Container = styled.div`
  .fit-content {
    max-width: fit-content;
  }
  .btn-download {
    padding: 5px 65px 5px 41px;
    display: flex;
    max-width: fit-content;
    align-items: center;
    border: none;
    border-radius: 20px;
    background: #116bff;
    color: #fff;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -1.36px;
    span span {
      display: block;
      text-align: end;
      font-family: 'Quicksand';
      font-size: 20px;
      font-weight: 500;
      line-height: 138.75%;
      letter-spacing: -0.8px;
    }
  }

  .btn-download-hero {
    padding: 0px 65px 0px 41px;
    display: flex;
    justify-content: space-between;
    max-width: auto;
    align-items: center;
    border: none;
    border-radius: 20px;
    background: #116bff;
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -1.36px;

    div {
      margin: 0 !important;
      rotate: -90deg;
    }
  }

  .btn-no-thanks {
    margin: 0 auto;
    color: #ff1a50;
    background: transparent;
    padding: 0;
    border: none;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 30px;
    font-weight: 500;
    line-height: 138.75%;
    letter-spacing: -0.96px;
  }

  .cl-blue {
    color: #116bff;
  }

  .cl-red {
    color: #ff1a50;
  }

  @media screen and (min-width: 992px) {
    .container {
      max-width: 1320px !important;
    }
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.85;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }

  @media screen and (max-width: 991px) {
    .btn-no-thanks {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 575px) {
    .btn-no-thanks {
      font-size: 18px;
    }
  }
`;

export const Attention = styled.div`
  background: #ff1a50;
  box-shadow: 0px 4px 4px rgba(255, 26, 80, 0.15);

  h2 {
    font-weight: 400 !important;
  }

  svg {
    height: 30px;
    width: 30px;
  }

  @media screen and (max-width: 991px) {
    svg {
      height: 25px;
      width: 25px;
    }
  }
`;

export const Gpt = styled.div`
  h1 {
    color: #000;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 116%;
  }

  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Archivo';
    font-size: 70px;
    font-weight: 800;
    line-height: 125%;
  }

  h2 :nth-child(1) {
    text-decoration-line: line-through;
  }

  h2 :nth-child(2) {
    background: linear-gradient(270deg, #fb5a43 0.03%, #c341ae 175.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0;
  }

  h3 {
    color: #000;
    text-align: center;
    font-family: 'Archivo';
    font-size: 26px;
    font-weight: 300;
    line-height: 120%;
  }

  h4 {
    color: #ff1a50;
    font-family: 'Archivo';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 116%;
  }

  h5 {
    color: #116bff;

    font-family: 'Architects Daughter';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%;
  }

  p {
    color: #116bff;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: 600;
    line-height: 138.75%;
    letter-spacing: -0.64px;
  }

  img {
    width: 431px;
    height: 88px;
  }

  .img-hero {
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 535px;
  }

  .font-small {
    font-size: 60px;
  }

  .circle-title {
    position: relative;
    span {
      position: relative;
      z-index: 2;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .bg-oval {
    background-image: url(${bgOval});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    width: 95%;
    max-width: 1350px;
    margin: 0 auto;
  }

  .bg-top {
    background-color: #43435c;
    border-top-left-radius: 0.875rem;
    border-top-right-radius: 0.875rem;
    height: 1.375rem;
  }

  .bg-conic-gradient {
    background-image: conic-gradient(
      from 225deg,
      #ffc876,
      #79fff7,
      #9f53ff,
      #ff98e2,
      #ffc876
    );
    padding: 0.125rem;
  }

  .rounded-16 {
    border-radius: 16px;
  }

  .rounded-0875 {
    border-radius: 0.875rem;
  }

  .rounded-b-125 {
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
  }

  .rounded-b-0875 {
    border-bottom-left-radius: 0.875rem;
    border-bottom-right-radius: 0.875rem;
  }

  .border-n {
    position: absolute;

    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .right-float {
    width: 18.375rem;
    bottom: 11.25rem;
    right: -5.5rem;

    .youtube {
      width: 70px;
      height: 59px;
    }
    .avatar {
      width: 20px;
      height: 20px;
      border: 2px solid #2e2a41;
    }
  }

  .left-float {
    width: auto;
    bottom: 7.625rem;
    left: -5.5rem;
    list-style: none;

    .youtube {
      width: 45px;
      height: 45px;
    }
  }

  .bg-float {
    background-color: #47406060;

    backdrop-filter: blur(8px);
  }

  .h-6 {
    height: 1.5rem;
  }

  .bg-bottom-1 {
    background-color: #1b1b2e;
    z-index: 1;
  }

  .bg-bottom-2 {
    background-color: #1b1b2e;
    opacity: 0.7;
    z-index: 1;
  }

  .h-shadow {
    background: rgba(0, 0, 0, 0.8);
    height: 3rem;
    filter: blur(29px);
  }

  .lootie > div {
    margin: 0 10px !important;
    min-width: 39px;
    min-height: 39px;
  }

  .enter-email {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .thumb,
  video {
    border: 1px solid #303030;
    -webkit-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
  }

  .thumb {
    width: 100%;
    border-radius: 25px;
  }

  .btn-mute {
    z-index: 100;
    background: transparent;
    border-radius: 20px;
    left: 0px;
    img {
      width: 32px;
      height: 32px;
    }
  }

  .mute {
    position: absolute;
    z-index: 100;
    height: 400px;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 8px;
    background: transparent;
    border-radius: 20px;
    border: 1px solid #fff;
    background: rgba(17, 107, 255, 0.8);
    backdrop-filter: blur(5px);
    filter: drop-shadow(0px 4px 40px rgba(17, 107, 255, 0.36));
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 43.5px;
  }

  .aspect {
    aspect-ratio: 1024 / 490;
  }

  @media screen and (max-width: 1399px) {
    .bg-oval {
      background-image: url(${bgOval});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
      width: 95%;
      max-width: 1145px;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 1199px) {
    .bg-oval {
      background-image: url(${bgOval});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
      width: 95%;
      max-width: 938px;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 991px) {
    h1 {
      font-size: 33px;
    }

    h2 {
      font-size: 44px;
    }

    h4 {
      font-size: 18px;
    }

    .font-small {
      font-size: 36px;
    }

    .aspect {
      aspect-ratio: 688 / 490;
    }

    .mute {
      height: 370px;
    }

    .bg-oval {
      background-image: url(${bgOval});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      width: 100%;
      max-width: auto;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 768px) {
    .mute {
      height: 280px;
      width: 290px;
      font-size: 24px;
    }
  }

  @media screen and (max-width: 575px) {
    h3 {
      font-size: 20px;
    }

    h5 {
      font-size: 34px;
    }

    /* img {
      width: 100%;
      max-width: 431px;
      height: auto;
    } */

    .aspect {
      aspect-ratio: 412 / 490;
    }

    .btn-download,
    .btn-download-hero {
      padding: 5px 40px 5px 15px;
    }

    .img-hero {
      width: 100%;
      height: 100%;
      max-width: 250px;
      max-height: 380px;
    }

    .mute {
      height: 240px;
      width: 240px;
      font-size: 20px;
      line-height: 25px;
    }
  }

  @media screen and (max-width: 480px) {
    .mute {
      height: 200px;

      font-size: 20px;
      line-height: 25px;
    }
  }
`;

export const Converting = styled.div`
  background-color: #0c0c0d;
  padding-top: 65px;
  padding-bottom: 120px;
  h2 {
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
  }

  h3 {
    color: #021027;
    font-size: 22px;
    font-weight: 500;
  }

  p {
    color: #fff;
    font-family: 'Archivo';
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
  }

  .lootie > div {
    margin: 0 7px !important;
  }

  .line {
    opacity: 1;
    height: 2px;
    background-color: #e9ede8;
    margin: 5rem 0;
  }

  .bd-left {
    border-radius: 24px;
    border: 1px solid #9c9c9c;
    background: #131313;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bd-right {
    border-radius: 24px;
    border: 1px solid #2577ff;
    background: #131313;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 575px) {
    h3 {
      font-size: 17px;
    }

    .lootie > div {
      margin: 0 0px !important;
    }
  }
`;

export const Breakthrough = styled.div`
  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 400;
    line-height: 119.5%;
    span {
      font-weight: 700;
    }
  }

  hr {
    height: 2px;
    margin-top: 100px;
    margin-bottom: 80px;

    background-color: #e9ede8;
    opacity: 1;
  }

  .title h3 {
    font-family: 'Libre Baskerville';
    background: linear-gradient(95deg, #2d907c -11.22%, #0f406d 129.68%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 75px;
    font-weight: 700;
    line-height: 119.5%;
  }

  .traffic {
    width: 236px;
    height: 236px;
  }

  .mastering {
    width: 277px;
    height: 85px;
    margin-bottom: -14px;
  }

  .get-all-side p {
    color: #021027;
    font-family: 'Archivo';
    font-size: 18px;
    font-weight: 300;
    line-height: 133.5%;
    margin-bottom: 23px;
  }

  .right-side h3 {
    width: 87%;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 22px;
    font-weight: 700;
    line-height: 119.5%;
    border-radius: 25px 25px 0 0;
    background: #175771;
    padding-top: 17px;
    padding-bottom: 10px;
  }

  .bg {
    border-radius: 30px;
    background: #eee;
    padding: 35px;
  }

  .bg h2 {
    color: #021027;
    text-align: center;
    font-family: 'Archivo';
    font-size: 44px;
    font-weight: 700;
    line-height: 133.5%;

    span:nth-child(1) {
      color: #ff1a50;
      text-decoration-line: line-through;
    }

    span:nth-child(2) {
      color: #fff;
      font-weight: 800;
      background: url(${bgValue});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 100px;
      padding: 3px 18px;
    }
  }

  .fw-800 {
    font-weight: 800 !important;
  }

  .bg h4 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 22px;

    font-weight: 400;
    line-height: 119.5%;

    span {
      font-family: 'Quicksand';
      font-size: 22px;

      line-height: 138.75%;
      letter-spacing: -0.88px;
    }
  }

  .bg p {
    color: #021027;
    text-align: center;
    font-family: 'Archivo';
    font-size: 19px;
    font-weight: 300;
    line-height: 133.5%;
  }

  .bg h5 {
    color: #021027;
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: 500;
    line-height: 138.75%;
    letter-spacing: -0.64px;
  }

  .btn-download {
    padding: 0px 29px 0px 14px;
    font-size: 34px;
    line-height: 80%;
  }

  .stamp img {
    width: 285px;
    height: 266px;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 32px;
    }

    hr {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .traffic {
      width: 150px;
      height: 150px;
    }

    .mastering {
      width: 183px;
      height: 55px;
    }

    .title h3 {
      font-size: 45px;
    }

    .get-all-side p {
      font-size: 22px;
    }

    .cards {
      max-width: 431px;
    }
  }

  @media screen and (max-width: 768px) {
    .traffic {
      width: 110px;
      height: 110px;
    }

    .mastering {
      width: 145px;
      height: 48px;
    }

    .title h3 {
      font-size: 33px;
    }
  }

  @media screen and (max-width: 575px) {
    .traffic {
      width: 81px;
      height: 81px;
    }

    .mastering {
      width: 107px;
      height: 30px;
    }

    .title h3 {
      font-size: 26px;
    }

    .bg {
      padding: 35px 32px;
    }
  }
`;

export const Perfect = styled.div`
  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;
  }

  h3 {
    color: #000;
    text-align: center;
    font-family: 'IBM Plex Sans JP';
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
  }

  h4 {
    color: #fff;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 119.5%;
  }

  p {
    color: #000;
    text-align: center;
    font-family: 'Archivo';
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    line-height: 119.5%;
  }

  .bg-imagine {
    background-color: #0e0c15;
    padding: 181px 0px;
    margin-top: 250px;
    margin-bottom: 214px;
  }

  .lootie > div {
    margin: 0 7px !important;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 32px;
    }
  }
`;

export const Easy = styled.div`
  h2 {
    color: #000;
    font-family: 'Archivo';
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 119.5%;
  }

  h3 {
    color: #000;
    font-family: 'Archivo';
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 119.5%;
  }
`;

export const Unlimited = styled.div`
  h2 {
    color: #000;
    text-align: center;
    font-family: 'Archivo Narrow';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 119.5%;
  }

  h3 {
    color: #000;
    text-align: center;
    font-family: 'Archivo';
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    line-height: 119.5%;
  }

  p {
    color: #000;
    font-family: 'Source Code Pro';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-right: 88px;
  }

  .bd-testemonials {
    padding: 118px 80px;
    background: radial-gradient(
          circle at 100% 100%,
          #ffffff 0,
          #ffffff 20px,
          transparent 20px
        )
        0% 0%/22px 22px no-repeat,
      radial-gradient(
          circle at 0 100%,
          #ffffff 0,
          #ffffff 20px,
          transparent 20px
        )
        100% 0%/22px 22px no-repeat,
      radial-gradient(
          circle at 100% 0,
          #ffffff 0,
          #ffffff 20px,
          transparent 20px
        )
        0% 100%/22px 22px no-repeat,
      radial-gradient(circle at 0 0, #ffffff 0, #ffffff 20px, transparent 20px)
        100% 100%/22px 22px no-repeat,
      linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 4px)
        calc(100% - 44px) no-repeat,
      linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 44px)
        calc(100% - 4px) no-repeat,
      linear-gradient(transparent 0%, #116bff 56%),
      linear-gradient(0deg, transparent 0%, #ffc876 100%);
    border-radius: 22px;

    box-sizing: content-box;
    position: relative;
    //min-height: 476px;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #fff;
    }

    .avatar {
      width: 160px;
      height: 160px;
    }
    span {
      color: #000;
      font-family: 'Sora';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      span {
        color: #a8a9aa;
        font-family: 'Sora';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .quote {
    position: absolute;
    top: -40px;
    left: 90px;
    width: 90px;
    height: 90px;
  }
`;

export const All = styled.div`
  background: #e5eff8;

  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;
    span {
      color: #116bff;
    }
  }

  h3 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;
  }

  h4 {
    color: #116bff;
    text-align: center;
    font-family: 'Loved by the King';
    font-size: 60px;
    font-weight: 600;
    line-height: 119.5%;
  }

  h5 {
    color: #116bff;
    font-family: 'Archivo';
    font-size: 28px;
    font-weight: 700;
    line-height: 138%;
  }

  b {
    font-weight: 700;
  }

  p {
    color: #021027;
    font-family: 'Archivo';
    font-size: 24px;
    font-weight: 300;
    line-height: 138%;
  }

  .bg-paper {
    //height: 1082px;
    background: url(${bgPaperWhite});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
  }

  .p-top-title {
    padding-top: 22%;
  }

  .p-top {
    padding-top: 4%;
    padding-bottom: 21%;
  }
  @media screen and (min-width: 1700px) {
    .container-fluid {
      max-width: 1650px;
    }

    .p-top {
      padding-bottom: 21%;
    }

    .bg-paper {
      background: url(${bgPaperWhiteLg});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top left;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 34px;
    }
    h3 {
      font-size: 34px;
    }
    h4 {
      font-size: 40px;
    }
    .bg-paper {
      background-position: center;
      padding-top: unset;
      padding-bottom: unset;
    }
  }

  @media screen and (max-width: 575px) {
    .bg-paper {
      background: url(${bgPaperWhiteMob});
      padding-top: 22%;
      padding-bottom: 37vw;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
`;

export const Bonus = styled.div`
  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 119.5%;
    span {
      color: #116bff;
    }
  }

  h3 {
    color: #fff;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;
  }

  h5 {
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;
  }

  h6 {
    color: #ff0;
    font-family: 'Archivo';
    font-size: 26px;
    font-weight: 400;
    line-height: 138%;
  }

  p {
    color: #fff;
    font-family: 'Archivo';
    font-size: 26px;
    font-weight: 400;
    line-height: 138%;
  }

  ul li {
    color: #fff;
    font-family: 'Archivo';
    font-size: 20px;
    font-weight: 400;
    line-height: 138%;
  }

  button {
    padding-left: 36px;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -1.36px;
    border-radius: 40px;
    background: #116bff;
    border: none;
    span {
      margin: 13px 13px 13px 36px;
      padding: 13px 24px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.32);
    }
  }

  .col-max-mobile {
    max-width: unset;
  }

  .bg-pp1-transparent {
    background: url(${bgPaperTransparent}), #014038;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .bg-pp2-transparent {
    background: url(${bgPaperTransparent}), #1a0953;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .bg-pp3-transparent {
    background: url(${bgPaperTransparent}), #420b48;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .vertical-bar {
    width: 10px;
    height: 342px;
    background: #ff0;
  }

  .w-90 {
    max-width: 90%;
  }

  .value {
    color: #fff;

    font-family: 'Libre Baskerville';
    font-size: 40px;
    font-weight: 400;
    line-height: 119.5%;

    span {
      color: #ff0;
      font-weight: 700;
    }
  }
  .papper-top-1 {
    top: -76px;
    right: -12px;

    img {
      width: 491px;
      height: 133px;
    }

    h5 {
      color: #108777;
      margin-top: -104px;
    }
  }

  .papper-top-2 {
    top: -76px;
    right: -12px;

    img {
      width: 491px;
      height: 133px;
    }

    h5 {
      color: #35149f;
      margin-top: -104px;
    }
  }

  .papper-top-3 {
    top: -76px;
    right: -12px;

    img {
      width: 491px;
      height: 133px;
    }

    h5 {
      color: #83168f;
      margin-top: -104px;
    }
  }

  .tablet {
    bottom: -70px;
    right: -107px;
    width: 334px;
    height: 460px;
    display: block;
    margin-bottom: auto;
    position: absolute;
  }

  .mb-bonus {
    margin-bottom: 16%;
  }

  .bg-1 {
    background: #128979;
  }

  .bg-2 {
    background: #3717a3;
  }

  .bg-3 {
    background: #841791;
  }

  .bg-4 {
    background: #1141a1;
  }

  .number span {
    width: 84px;
    height: 84px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #fff;
    color: #fff;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 400;
    line-height: 108%;
    align-items: center;
  }

  .line {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 50px;
    height: 60px;
    width: 1px;
    background: #841791;
  }

  .total-value {
    color: #021027;
    font-family: 'Libre Baskerville';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 119.5%;
  }

  .total {
    color: #021027;
    font-family: 'Libre Baskerville';
    font-size: 50px;
    font-weight: 700;
    line-height: 119.5%;
    .color-value {
      color: #116bff;
      font-weight: 700;
    }
  }

  .only {
    color: #021027;
    text-align: center;
    font-family: 'Archivo';
    font-size: 60px;
    font-weight: 700;
    line-height: 125%;
    span {
      font-weight: 800;
      background: linear-gradient(270.04deg, #c341ae 43.44%, #9445c2 96.35%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .saving-text {
    color: #116bff;
    text-align: center;
    font-family: 'Loved by the King';
    font-size: 60px;
    font-weight: 600;
    line-height: 119.5%;
  }

  .dont {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;
  }

  .text-btn-send {
    color: #ff2e00;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 28px;
    font-weight: 600;
    line-height: 138.75%;
    letter-spacing: -1.12px;
  }

  @media screen and (max-width: 991px) {
    overflow: hidden;
    h2 {
      font-size: 34px;
    }

    h3 {
      font-size: 40px;
    }

    h5 {
      font-size: 55px;
    }

    p {
      font-size: 24px;
    }

    ul {
      margin-bottom: 0;
    }

    button {
      font-size: 26px;
      padding-left: 20px;
    }

    .tablet {
      margin-bottom: -240px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      bottom: unset;
      right: unset;
    }

    .mt-lootie {
      margin-top: 30%;
    }

    .mb-bonus {
      margin-bottom: 40%;
    }

    .number span {
      width: 54px;
      height: 54px;
      font-size: 35px;
    }

    .line {
      margin-left: 35px;
    }

    .total-value {
      font-size: 18px;
    }

    .total {
      font-size: 34px;
    }

    .only {
      font-size: 34px;
    }

    .saving-text {
      font-size: 38px;
    }

    .dont {
      font-size: 34px;
    }

    .text-btn-send {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .mb-bonus {
      margin-bottom: 75%;
    }

    .font-size {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 575px) {
    button {
      font-size: 26px;
      padding-left: 20px;
    }

    button span {
      margin: 13px 13px 13px 18px;
      padding: 13px 13px;
    }
    .papper-top-1,
    .papper-top-2,
    .papper-top-3 {
      right: -65px;
    }

    .col-max-mobile {
      max-width: 410px;
    }

    .mt-lootie {
      margin-top: 50%;
    }
  }

  @media screen and (max-width: 425px) {
    button {
      font-size: 23px;
    }
  }
`;

export const Questions = styled.div`
  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: normal;
  }

  span {
    font-family: 'Quicksand', sans-serif;
    color: #021027;
    font-weight: 700;
  }

  b {
    font-weight: 600;
  }

  .btn-plus {
    top: 0px;
    left: 0px;
  }

  .padding-y {
    padding-top: 5.7rem;
    padding-bottom: 5.7rem;
  }

  .btn-see-more {
    height: 46px;
    width: 46px;
  }

  .hover-top:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 20px 20px 0 0;
  }

  .hover-bottom:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 0 0 20px 20px;
  }

  .hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .answer {
    overflow: hidden;
    height: 0px;
    transition-duration: 0.3s;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #021027;
  }

  .answer.active {
    height: 190px;
  }

  .answer button {
    position: relative;
    z-index: 100;
    color: #116bff;
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    :hover {
      text-decoration: underline;
    }
  }

  .bg-answer {
    background-color: rgba(249, 249, 249, 0.6);
  }

  .text-grey {
    color: #021027;
    font-family: 'Libre Baskerville';
    font-size: 23.5px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
  }

  .border-questions {
    border: solid 1px #e6e7e8;
    border-radius: 20px;
  }

  /* @media screen and (max-width: 1199px) {
    .answer.active {
      height: 225px;
    }
  } */

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 34px;
    }

    .answer.active {
      height: auto;
    }
  }

  @media screen and (max-width: 767px) {
    .text-grey {
      font-size: 20px;
    }

    .answer.active {
      height: auto;
    }
  }

  @media screen and (max-width: 575px) {
    span {
      font-size: 36px;
    }

    .text-grey {
      font-size: 24px;
    }

    .padding-y {
      padding-top: 1rem;
      padding-bottom: 1.25rem;
    }

    .answer.active {
      height: auto;
    }

    .answer {
      font-size: 18px;
      line-height: 26px;
    }
  }

  @media screen and (max-width: 390px) {
    .answer.active {
      height: auto;
    }
  }
`;

export const Current = styled.div`
  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;
    span {
      color: #116bff;
    }
  }

  h3 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 40px;
    font-weight: 400;
    line-height: 119.5%;
  }

  .only {
    color: #021027;
    text-align: center;
    font-family: 'Archivo';
    font-size: 60px;
    font-weight: 700;
    line-height: 125%;
    span {
      font-weight: 800;
      background: linear-gradient(
        270.04deg,
        #fb5a43 0.03%,
        #c341ae 75.44%,
        #9445c2 99.35%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .saving-text {
    color: #116bff;
    text-align: center;
    font-family: 'Loved by the King';
    font-size: 60px;
    font-weight: 600;
    line-height: 119.5%;
  }

  .text-btn-send {
    color: #ff2e00;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 28px;
    font-weight: 600;
    line-height: 138.75%;
    letter-spacing: -1.12px;
  }

  .disclaimer {
    border: 1px solid #bcb2b2;
    border-radius: 40px;
  }

  .disclaimer h2 {
    font-family: 'Archivo', sans-serif;
    font-weight: 400;
    font-size: 26px;
    color: #116bff;
  }

  .disclaimer p {
    font-family: 'Archivo', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 133.34%;
    color: #202020;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 34px;
    }

    h3 {
      font-size: 28px;
    }

    button {
      font-size: 26px;
      padding-left: 20px;
    }

    .only {
      font-size: 34px;
      span {
        background: linear-gradient(
          270.04deg,
          #fb5a43 0.03%,
          #c341ae 30.44%,
          #9445c2 57.35%,
          #079cfe 95.73%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .saving-text {
      font-size: 38px;
    }

    .text-btn-send {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    button {
      font-size: 26px;
      padding-left: 20px;
    }

    button span {
      margin: 13px 13px 13px 18px;
      padding: 13px 13px;
    }

    .disclaimer p {
      font-size: 16px;
    }

    .disclaimer h2 {
      font-size: 26px;
    }
  }

  @media screen and (max-width: 425px) {
    button {
      font-size: 23px;
    }
  }

  @media screen and (min-width: 768px) {
    .disclaimer {
      border: none;
    }
    .disclaimer p {
      font-family: 'Inter', sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 133.34%;
      color: #797979;
      span {
        font-weight: 500;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 4px;
  }
  h2 {
    color: #021027;
    font-family: 'Libre Baskerville';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.75%;
    letter-spacing: -1.12px;
  }

  h3 {
    color: #021027;
    font-family: 'Archivo';
    font-size: 26px;
    font-weight: 600;
    line-height: 37px;
  }

  p,
  li {
    color: #021027;
    font-family: 'Archivo';
    font-size: 23px;
    font-weight: 400;
    line-height: 37px;
  }

  video {
    border: 1px solid #303030;
    -webkit-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
  }

  .text-color-red {
    color: #ff1a50;
  }

  .text-color-blue {
    color: #116bff;
  }

  .font-daughter {
    font-family: 'Architects Daughter';
  }
  .modal-content {
    border-radius: 40px;
    border: 4px dashed #116bff;

    background: #fffdfd;
  }

  .bg-300 {
    background-color: #fff8c2;
  }
  .btn-yes-modal {
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 20px;
    font-weight: 700;
    border-radius: 20px;
    background: #2f80ed;
    border: none;
    height: 74px;
    text-transform: capitalize;
    line-height: normal;
  }
  .btn-no-modal {
    color: #ff1a50;
    text-align: center;
    font-family: 'Archivo';
    font-size: 20px;
    font-weight: 700;
    border-radius: 20px;
    background: transparent;
    border: 1px solid #ff1a50;
    height: 74px;
    line-height: normal;
  }
`;
