import React, { useState, useCallback, useRef } from 'react';
import { Container } from './styles';

import iconPlay from '~/assets/icons/play-button.png';

interface IPlayer {
  videoSelected: string;
  src: string;
  thumbnail: string;
  onPlay?(): void;
  onEnded?(): void;
}

const PlayerMembers: React.FC<IPlayer> = ({
  videoSelected,
  src,
  thumbnail,
  onPlay,
  onEnded,
}) => {
  const videoMembersRef = useRef<HTMLVideoElement>(null);
  const [play, setPlay] = useState(false);

  const handleVideoEnded = () => {
    const video = videoMembersRef.current;
    if (video) {
      video.currentTime = 0;
      video.setAttribute('poster', thumbnail);
    }

    if (onEnded) {
      onEnded();
    }
  };

  const handleVideoSelected = useCallback((itemVid) => {
    const vid = document.getElementById('videoMembers1') as HTMLVideoElement;
    const vid2 = document.getElementById('videoMembers2') as HTMLVideoElement;
    const vid3 = document.getElementById('videoMembers3') as HTMLVideoElement;
    const vid4 = document.getElementById('videoMembers4') as HTMLVideoElement;

    if (itemVid === 'videoMembers1') {
      vid2.pause();
      vid3.pause();
      vid4.pause();
    }

    if (itemVid === 'videoMembers2') {
      vid.pause();
      vid3.pause();
      vid4.pause();
    }

    if (itemVid === 'videoMembers3') {
      vid.pause();
      vid2.pause();
      vid4.pause();
    }

    if (itemVid === 'videoMembers4') {
      vid.pause();
      vid2.pause();
      vid3.pause();
    }
  }, []);

  const handleClick = useCallback(() => {
    const vid = document.getElementById(videoSelected) as HTMLVideoElement;
    if (vid) {
      if (!play) {
        vid.play();
      } else {
        vid.pause();
      }
    }

    setPlay((state) => !state);
    handleVideoSelected(videoSelected);
  }, [videoSelected, handleVideoSelected, play]);

  return (
    <Container className="video w-100 position-relative">
      <video
        playsInline
        poster={thumbnail}
        onClick={handleClick}
        id={videoSelected}
        onPlay={() => {
          if (onPlay) {
            onPlay();
          }
        }}
        onEnded={() => {
          onEnded;
          handleVideoEnded();
        }}
        onPause={() => setPlay(false)}
        ref={videoMembersRef}
      >
        <source src={src} type="video/mp4" />
        <track default kind="captions" srcLang="en" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <button
        type="button"
        className={`${
          play ? 'd-none' : 'd-block'
        } position-absolute border-0 bg-transparent zoom w-100 h-100`}
        onClick={handleClick}
      >
        <img src={iconPlay} alt="Play" className="mx-auto" />
      </button>
    </Container>
  );
};

export default PlayerMembers;
