import styled from 'styled-components';
import free from '~/assets/banners/banner-free-new.png';
import freeMobile from '~/assets/banners/banner-value.png';

export const Container = styled.div`
  font-family: 'Quicksand', sans-serif;

  button.modal-works {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #116bff;
    font-size: 28px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    line-height: 45px;
    color: #fff;
    border: none;
    padding: 5px;
    z-index: 10;
    transition: all 1s ease;

    span {
      margin: -13px 2rem;
    }
  }

  @media screen and (max-width: 991px) {
    button.modal-works {
      font-size: 16px;
      line-height: 29px;
    }
  }

  @media screen and (max-width: 575px) {
    .container {
      max-width: 405px;
    }

    button.modal-works {
      span {
        margin: -13px 4rem -13px 0rem;
      }
    }
  }

  @media screen and (max-width: 991px) {
    overflow-x: hidden;
  }

  @media screen and (min-width: 992px) {
    .zoom {
      zoom: 0.8;
    }

    .active {
      top: -100px;
    }

    .not-active {
      top: 0px;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }
`;

export const Join = styled.div`
  h1 {
    font-weight: 400;
    font-size: 52px;

    line-height: 119.5%;
    color: #000;
    margin-top: 80px;
    margin-bottom: 40px;

    img.circle {
      top: -57px;
      left: 30px;
    }
  }

  p {
    color: #021027;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 28px;
    font-weight: 400;
    line-height: 119.5%;
  }

  form {
    background: #fafafa;
    border: 1px solid #116bff;
    border-radius: 24px;
    img {
      min-width: auto;
    }
    > div {
      border: none;
      width: 49%;
    }

    input {
      font-family: 'Archivo', sans-serif;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: -0.025em;
      color: #151517;
    }

    input::placeholder {
      font-weight: 300 !important;
      font-size: 16px;
      line-height: 24px;
      color: #b0b0b0;
    }

    button {
      font-family: 'Archivo', sans-serif;
      font-size: 24px;
      line-height: 110%;
      letter-spacing: -0.04em;
      color: #fff;
      background: #116bff;
      border-radius: 20px;
      padding: 17px 28px;
      border: none;
      transition-duration: 0.3s;
      font-weight: 500;

      :hover {
        opacity: 0.8;
      }
    }
  }

  [role='button'] {
    cursor: pointer;
    pointer-events: none;
    transform: rotate(180deg);
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .master {
    font-weight: 700;
    color: #116bff;
  }

  .archivo {
    font-family: 'Archivo';
  }

  .text-danger {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
  }

  .discover {
    bottom: 4%;
    left: 50%;
    margin-top: 3rem;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
  }

  .banner-free {
    background-image: url(${freeMobile});
    background-position: center;
    background-size: cover;

    padding: 16px 20px;
    line-height: 79px;
    color: #fff;

    text-align: center;
    font-family: 'Quicksand';
    font-size: 26px;
    font-weight: 700;
    line-height: 119.5%;
    display: inline-block;
    border: solid 4px #116bff !important;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 72px 70px -10px rgba(0, 0, 0, 0.12) !important;
    -moz-box-shadow: 0px 72px 70px -10px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0px 72px 70px -10px rgba(0, 0, 0, 0.12) !important;

    :hover {
      opacity: 0.9;
    }
  }

  .person1 {
    left: 0px;
    top: 10px;
  }

  .person2 {
    right: -12px;
    top: 290px;
  }

  .person3 {
    left: 0px;
    bottom: 35px;
    width: 100px;
  }

  .cube {
    right: 15px;
    bottom: 130px;
  }

  @media screen and (min-width: 576px) {
    h1 {
      font-size: 47px;
    }
    h1 img.circle {
      top: -57px;
      left: -35px;
    }

    p {
      font-size: 24px;
    }

    .banner-free {
      font-size: 33px;
      padding: 5px 28px;
    }
  }

  @media screen and (min-width: 769px) {
    h1 img.circle {
      top: -57px;
      left: 47px;
    }

    .person2 {
      right: -12px;
      top: 180px;
    }

    .banner-free {
      background-image: url(${free});
      font-size: 33px;
      padding: 13px 17px;
    }
  }

  @media screen and (min-width: 992px) {
    h1 {
      font-size: 50px;
    }
    h1 img.circle {
      top: -57px;
      left: 47px;
    }

    form button {
      padding: 25px 28px;
    }

    .person1 {
      left: -30px;
      top: 50px;
    }

    .person2 {
      right: 0px;
      top: 222px;
    }

    .person3 {
      left: -20px;
      bottom: 95px;
    }

    .cube {
      right: -20px;
      bottom: 130px;
      width: 80px;
    }

    .sphere {
      bottom: -89px;
      left: -15px;
      z-index: -1;
    }

    .discover {
      zoom: 0.8;
      bottom: 0%;
    }

    .banner-free {
      font-size: 35px;
      padding: 13px 30px;
    }
  }

  @media screen and (min-width: 1200px) {
    .person1 {
      left: 5px;
    }

    .person2 {
      right: -10px;
      bottom: 176px;
    }

    h1 {
      font-size: 56px;
    }

    p {
      font-size: 30px;
    }

    .banner-free {
      font-size: 40px;
    }
  }

  @media screen and (min-width: 1400px) {
    h1 {
      font-size: 84px;
    }
    h1 img.circle {
      top: -57px;
      left: 111px;
    }

    p {
      font-size: 40px;
    }

    form button {
      padding: 30px 28px;
    }

    .person1 {
      left: -20px;
      top: 30px;
    }
    .person2 {
      right: -37px;
      //bottom: 460px;
      top: 316px;
    }

    .banner-free {
      padding: 20px 30px;
    }

    .discover {
      zoom: 1;
      bottom: 4%;
    }
  }

  @media (min-width: 992px) and (min-height: 830px) {
    /* .container {
      height: 99vh;
    } */
    .discover {
      position: absolute;
      transform: translateX(-50%);
    }
  }
`;

export const DontWait = styled.div`
  .fw-500 {
    font-weight: 500;
  }
  .terms {
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    line-height: 164.5%;
    letter-spacing: 0.64px;
  }
`;
