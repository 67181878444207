import styled from 'styled-components';

export const Container = styled.div`
  h5 {
    font-family: 'Space Grotesk';
    font-weight: 400;
    font-size: 0.75rem;
    letter-spacing: 0.15em;
  }
  li {
    min-width: max-content;
    height: 8.5rem;
    font-family: 'Sora';
  }

  img {
    width: 70px !important;
    height: 70px;
  }

  .bg-socials {
    background-color: #0e0c15;
  }

  .mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .text-size {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .track {
    animation: 15s linear 0s infinite normal none running scroll-left;
  }

  .left-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 3rem;
    height: 8.5rem;
    background: linear-gradient(90deg, #0e0d14 0%, rgba(14, 13, 20, 0) 100%);
  }

  .right-side {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    width: 3rem;
    height: 8.5rem;
    background: linear-gradient(270deg, #0e0d14 0%, rgba(14, 13, 20, 0) 100%);
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(35px);
    }
    100% {
      transform: translateX(-1553px);
    }
  }
`;
