import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Inter';
  h1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    color: #000000;
    margin-top: 4rem;
  }
  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #151515;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  p,
  li,
  a {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #151515;
  }
  p {
    margin-bottom: 1.5rem;
  }

  a {
    text-decoration: underline !important;
    word-break: break-all;
  }

  u {
    font-weight: 700;
  }

  .style-none {
    list-style-type: none;
    padding-left: 1rem;
    li {
      display: flex;
      span:nth-child(1) {
        min-width: 30px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    h1 {
      font-size: 40px;
      line-height: 50px;
    }

    h2 {
      font-size: 30px;
    }
  }
`;
