import React from 'react';
import Fade from 'react-reveal/Fade';

import { Container } from './styles';

const Disclaimer: React.FC = () => {
  return (
    <Container className="d-flex flex-column py-5 px-4 p-md-0">
      <Fade>
        <h2 className="mb-4 mt-2 title">Important Disclaimers</h2>
      </Fade>
      <Fade>
        <p className="fw-light">
          <span className="h6">
            Non-Affiliation with Facebook/Meta Platforms, Inc.:
          </span>{' '}
          This site is not part of the Facebook website or Meta Platforms, Inc.
          Additionally, this site is not endorsed by Facebook or Instagram in
          any way. Facebook and Instagram are trademarks of Meta Platforms, Inc.
        </p>
        <p className="fw-light">
          <span className="h6">Earnings and Income Disclaimer:</span> While
          AutoAffiliate provides a platform for affiliate marketing, we make no
          guarantees of specific income or profit. Success depends on individual
          effort, dedication, and market conditions.
        </p>
        <p className="fw-light">
          <span className="h6">Free and Paid Services:</span> Our services on
          this page are free, not requiring a credit card. We may offer
          additional services or upgrades involving payment.
        </p>
        <p className="fw-light">
          <span className="h6">Affiliate Participation:</span> To earn from the
          income streams we help build, membership in our affiliate program may
          be necessary, which might include signing up for offers that require
          payment. Review and understand the terms of these offers.
          AutoAffiliate is not responsible for outcomes related to these offers.
        </p>
        <p className="fw-light">
          <span className="h6">Compliance and Responsibility:</span> Members are
          responsible for complying with all laws and regulations in their use
          of our platform and affiliate marketing activities.
        </p>
        <p className="fw-light">
          <span className="h6">Risks Acknowledgment:</span> By signing up,
          members acknowledge and accept all risks associated with using our
          platform and engaging in affiliate marketing.
        </p>
        <p className="fw-light">
          <span className="h6">No "Get Rich Quick" Promises:</span> Our platform
          does not endorse "get rich quick" schemes. Building sustainable income
          streams requires time, effort, and strategic planning.
        </p>
        <p className="fw-light">
          <span className="h6">Testimonials and Examples:</span> Testimonials
          and examples on our site are exceptional results, not indicative of
          average experiences, and are not guarantees of future success for
          anyone.
        </p>
        <p className="fw-light">
          <span className="h6">Updates and Changes:</span> AutoAffiliate
          reserves the right to modify services and platform features, aiming to
          keep members informed of significant changes.
        </p>
        <p className="fw-light">
          <span className="h6">Acceptance of Terms:</span> Use of the
          AutoAffiliate platform and participation in our program constitutes
          acceptance of these terms and conditions.s an affiliate marketing
          business that provides a simple to use platform for its members.
        </p>
      </Fade>
    </Container>
  );
};

export default Disclaimer;
