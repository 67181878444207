/* eslint-disable no-console */
import React, {
  ChangeEvent,
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import swalError from '~/utils/swalError';
import { Loading, Modal } from './styles';
import Input from '../Input';
import mailModal from '~/assets/defaults/mail.png';
import protectedIcon from '~/assets/icons/protected.svg';
import arrow from '~/assets/icons/arrow.svg';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

interface IModal {
  id?: string;
  btnText: string;
  btnContinueText?: string;
  className: string;
  origin?: string;
}

interface IParams {
  reference?: string;
  origin?: string;
}

interface IFormData {
  name: string;
  email: string;
}

const ModalEnterEmail: React.FC<IModal> = ({
  id,
  btnText,
  btnContinueText,
  className,
  origin,
}) => {
  const history = useHistory();
  const params = useParams<IParams>();
  const [reference, setReference] = useState('');
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const tag = useMemo(() => {
    const data = new URLSearchParams(location.search);
    const tagAux = data.get('tag');
    if (tagAux?.toUpperCase() === 'AIC' || tagAux?.toUpperCase() === 'BOT') {
      return 'AIC Bot, ';
    }
    return `${data.get('tag')}, `;
  }, [location.search]);

  useEffect(() => {
    if (params.reference) {
      localStorage.setItem('@AutoAffiliate:reference', params.reference);
      setReference(params.reference);
    } else {
      const referenceData = localStorage.getItem('@AutoAffiliate:reference');
      if (referenceData) {
        setReference(referenceData);
      }
    }
  }, [params.reference]);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);
        const schema = yup.object().shape({
          name: yup.string().required('Name is required'),
          email: yup
            .string()
            .email('Enter a valid email address')
            .required('Email is required'),
        });
        await schema.validate(data, { abortEarly: false });

        const responseIp = await axios.get('https://api.ipify.org?format=json');
        const response = await api.post(
          'users/webinar',
          {
            name: data.name,
            email: data.email,
            reference,
            ip: responseIp.data.ip,
          },
          {
            params: {
              origin: `${tag}${origin}`,
            },
          }
        );

        localStorage.setItem(
          '@AutoAffiliate:userData',
          JSON.stringify(response.data)
        );

        setLoading(false);
        if (location.pathname.includes('lp')) {
          history.push(`${process.env.PUBLIC_URL}/email`, {
            lp: 'pageLp',
          });
        }
        if (location.pathname.includes('ultimate-guide')) {
          history.push(`${process.env.PUBLIC_URL}/thanks`, {
            lp: 'pageUltimateGuide',
          });
        }
        if (location.pathname === '/' || location.pathname.includes('/i/')) {
          history.push(`${process.env.PUBLIC_URL}/aic`);
        }
      } catch (error) {
        setLoading(false);
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          const err = error as any;
          console.log(err);
          if (err.response.data.message === 'Email address already used') {
            swalError({
              message: 'Email already in use, please log in',
              textButton: 'Login',
            }).then(() => {
              window.location.href = process.env.REACT_APP_APP_URL as string;
            });
          } else {
            swalError({
              message:
                'Looks like what you were trying to do didn’t work, please try again.',
              textButton: 'Try Again',
            });
          }
        }
        console.error(error);
      }
    },
    [history, location.pathname, origin, reference, tag]
  );

  return (
    <>
      <button id={id} type="submit" className={className} onClick={handleShow}>
        {btnText}
      </button>
      <Modal
        className="modal-verify-email"
        centered
        size="lg"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header className="border-0 justify-content-center pt-0">
          {' '}
          <img
            src={mailModal}
            alt="Email"
            className="pe-none mg-negative"
          />{' '}
        </Modal.Header>
        <Modal.Body>
          <h2 className="text-center">Enter Your Email Below</h2>
          <h3 className="text-center my-3 w-lg-75 mx-auto">
            Please ensure that you enter your best email address
          </h3>

          <div className="w-lg-75 pt-4 mx-auto">
            <Form
              ref={formRef}
              className=""
              onSubmit={handleSubmit}
              id="join-waitlist-modal"
            >
              <Input
                name="name"
                type="text"
                placeholder="Full Name"
                className="bd-input"
              />
              <Input
                name="email"
                type="email"
                placeholder="Email"
                className="bd-input"
              />
              <button
                type="submit"
                className={`${
                  loading && 'pe-none'
                } py-3 d-flex align-items-center justify-content-center mt-4 btn btn-submit`}
              >
                {loading ? (
                  <Loading />
                ) : (
                  <span className="d-block py-1">
                    {`${btnContinueText ?? 'CONTINUE TO FREE VIDEO'}`}{' '}
                    <img src={arrow} alt="Arrow" className="ms-3" />
                  </span>
                )}
              </button>
              <p className="text-center mt-4">
                <img src={protectedIcon} alt="Protected" className="me-2" />
                <span>PRIVACY</span> PROTECTED - YOUR INFORMATION IS 100% SECURE
              </p>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 my-2" />
      </Modal>
    </>
  );
};

export default ModalEnterEmail;
