import React, { useState, useMemo } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import SliderSlick, { Settings } from 'react-slick';

import { Container, Item, ButtonLeft, ButtonRight } from './styles';

import quote from '~/assets/icons/quote.svg';
import bishop from '~/assets/defaults/bishop.png';
import jeremy from '~/assets/defaults/jeremy.png';
import tee from '~/assets/defaults/tee.png';
import jason from '~/assets/defaults/jason.png';
import bishopThumb from '~/assets/defaults/bishop-thumb.png';
import jeremyThumb from '~/assets/defaults/jeremy-thumb.png';
import teeThumb from '~/assets/defaults/tee-thumb.png';
import jasonThumb from '~/assets/defaults/jason-thumb.png';
import PlayerMembers from '../PlayerMembers';

interface ICarousel {
  className?: string;
}

interface IButtonProps {
  className?: string;
  onClick?(): void;
}

const PrevArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonLeft
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <IoIosArrowBack size={24} />
      </div>
    </ButtonLeft>
  );
};

const NextArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonRight
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <IoIosArrowForward size={24} />
      </div>
    </ButtonRight>
  );
};

const Carousel: React.FC<ICarousel> = ({ className }) => {
  const [selectedTestimony, setSelectedTestimony] = useState(1);

  const settings: Settings = useMemo(
    () => ({
      infinite: false,
      slidesToShow: 1,
      centerPadding: '0px',
      autoplay: false,
      autoplaySpeed: 3000,
      speed: 500,
      arrows: true,
      touchMove: true,
      afterChange: (current) => {
        setSelectedTestimony(current + 1);
      },

      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }),
    []
  );

  return (
    <Container className={className}>
      <div className="slider-count text-end">
        <span>{selectedTestimony}</span>/4
      </div>
      <SliderSlick {...settings}>
        <div className="">
          <Item active>
            <div className="px-2 d-md-flex justify-content-center justify-content-xxl-end align-items-center">
              <div className="box-member p-3 p-sm-5 p-md-3 p-lg-4 p-xl-5  mb-n5 mb-md-n1 mx-auto ms-md-0 me-md-n5">
                <div className="d-flex align-items-start pt-3">
                  <img
                    src={quote}
                    alt="quote"
                    className="quote mt-n2 me-sm-2"
                  />
                  <h4>AutoAffiliate is a total game-changer”</h4>
                </div>
                <p className="text-md-start my-2 my-sm-4 my-md-2 my-lg-3 my-xl-4">
                  “Its automation and AI features fit seamlessly into my
                  lifestyle. It has helped me on prospecting and building
                  relationships, which has started to open up new income
                  opportunities. It feels like I'm building my own freedom
                  business!"
                </p>
                <div className="d-flex align-items-center member-info px-4 px-sm-0 pt-2 pb-3">
                  <img src={bishop} alt="bishop" className="me-3" />
                  <div className="text-start mt-2">
                    <h3 className="mb-0">Bishop Stewart</h3>
                    <p className="mb-0">Member since 2023</p>
                  </div>
                </div>
              </div>
              <div className="member-banner">
                <PlayerMembers
                  videoSelected="videoMembers1"
                  src="https://cdn.autoaffiliate.ai/videos/Zoom.mp4"
                  thumbnail={bishopThumb}
                />
              </div>
            </div>
          </Item>
        </div>
        <div className="">
          <Item>
            <div className="px-2 d-md-flex justify-content-center justify-content-xxl-end align-items-center">
              <div className="box-member p-3 p-sm-5 p-md-3 p-lg-4 p-xl-5  mb-n5 mb-md-n1 mx-auto ms-md-0 me-md-n5">
                <div className="d-flex align-items-start pt-3">
                  <img
                    src={quote}
                    alt="quote"
                    className="quote mt-n2 me-sm-2"
                  />
                  <h4>More Than a Team, I’m Growing a Family ”</h4>
                </div>
                <p className="text-md-start my-2 my-sm-4 my-md-2 my-lg-3 my-xl-4">
                  I've met some amazing people along the way! And all while
                  making money! This journey has taught me that every step
                  counts, no matter how small. I'm grateful for being part of
                  something so incredible from the start!
                </p>
                <div className="d-flex align-items-center member-info px-4 px-sm-0 pt-2 pb-3">
                  <img src={tee} alt="Tee" className="me-3" />
                  <div className="text-start mt-2">
                    <h3 className="mb-0">Tee Hansen</h3>
                    <p className="mb-0">Member since 2023</p>
                  </div>
                </div>
              </div>
              <div className="member-banner hide-btn">
                <PlayerMembers
                  videoSelected="videoMembers2"
                  src="https://cdn.autoaffiliate.ai/videos/Zoom.mp4"
                  thumbnail={teeThumb}
                />
              </div>
            </div>
          </Item>
        </div>
        <div className="">
          <Item>
            <div className="px-2 d-md-flex justify-content-center justify-content-xxl-end align-items-center">
              <div className="box-member p-3 p-sm-5 p-md-3 p-lg-4 p-xl-5  mb-n5 mb-md-n1 mx-auto ms-md-0 me-md-n5">
                <div className="d-flex align-items-start pt-3">
                  <img
                    src={quote}
                    alt="quote"
                    className="quote mt-n2 me-sm-2"
                  />
                  <h4>AutoAffiliate is more than just a platform.”</h4>
                </div>
                <p className="text-md-start my-2 my-sm-4 my-md-2 my-lg-3 my-xl-4">
                  “It's a community of like-minded individuals striving for
                  financial freedom. Their robust tools and unwavering support
                  have helped me a lot with my affiliate marketing journey.
                </p>
                <div className="d-flex align-items-center member-info px-4 px-sm-0 pt-2 pb-3">
                  <img src={jeremy} alt="Avatar" className="me-3" />
                  <div className="text-start mt-2">
                    <h3 className="mb-0">Jeremy Engel</h3>
                    <p className="mb-0">Member since 2023</p>
                  </div>
                </div>
              </div>
              <div className="member-banner hide-btn">
                <PlayerMembers
                  videoSelected="videoMembers3"
                  src="https://cdn.autoaffiliate.ai/videos/Zoom.mp4"
                  thumbnail={jeremyThumb}
                />
              </div>
            </div>
          </Item>
        </div>
        <div className="">
          <Item>
            <div className="px-2 d-md-flex justify-content-center justify-content-xxl-end align-items-center">
              <div className="box-member p-3 p-sm-5 p-md-3 p-lg-4 p-xl-5  mb-n5 mb-md-n1 mx-auto ms-md-0 me-md-n5">
                <div className="d-flex align-items-start pt-3">
                  <img
                    src={quote}
                    alt="quote"
                    className="quote mt-n2 me-sm-2"
                  />
                  <h4>The AutoAffiliate system has been amazing”</h4>
                </div>
                <p className="text-md-start my-2 my-sm-4 my-md-2 my-lg-3 my-xl-4">
                  “It's user-friendly, and I'm impressed with their innovative
                  bot. I've started seeing some earnings, even passively. For
                  those passionate about affiliate marketing, it's definitely
                  worth exploring.”
                </p>
                <div className="d-flex align-items-center member-info px-4 px-sm-0 pt-2 pb-3">
                  <img src={jason} alt="jason" className="me-3" />
                  <div className="text-start mt-2">
                    <h3 className="mb-0">Jason Davis</h3>
                    <p className="mb-0">Member since 2023</p>
                  </div>
                </div>
              </div>
              <div className="member-banner hide-btn">
                <PlayerMembers
                  videoSelected="videoMembers4"
                  src="https://cdn.autoaffiliate.ai/videos/Zoom.mp4"
                  thumbnail={jasonThumb}
                />
              </div>
            </div>
          </Item>
        </div>
      </SliderSlick>
    </Container>
  );
};

export default Carousel;
