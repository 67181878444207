import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface ICopyAlertProps {
  show: boolean;
}

export const Modal = styled(ModalComponent)`
  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 65px;
    text-align: center;
    background: linear-gradient(
      270.04deg,
      #fb5a43 0.03%,
      #c341ae 30.44%,
      #9445c2 57.35%,
      #079cfe 95.73%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  h3 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #151517;
  }
  .social-media {
    a {
      width: 120px;
      height: 49px;
      border-radius: 22px;
      text-decoration: none !important;

      .icon {
        width: 30px;
        height: 30px;
        background: #ffffff;
        border-radius: 50%;
      }

      span {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 32px;
        color: #ffffff;
      }

      + a {
        margin-left: 20px;
      }
    }
  }

  .social-media :first-child {
    a {
      :nth-child(1) {
        background-color: #204fa9;

        svg {
          fill: #204fa9;
        }
      }

      :nth-child(2) {
        background-color: #0dc143;

        svg {
          fill: #0dc143;
        }
      }

      :nth-child(3) {
        background-color: #16a7c7;

        svg {
          fill: #16a7c7;
        }
      }
    }
  }

  .social-media :last-child {
    a {
      :nth-child(1) {
        background-color: #447ae4;

        svg {
          fill: #447ae4;
        }
      }

      :nth-child(2) {
        background-color: #00acee;

        svg {
          fill: #00acee;
        }
      }

      :nth-child(3) {
        background-color: #0098ee;

        svg {
          fill: #0098ee;
        }
      }
    }
  }

  .modal-content {
    background: #fffdfd;
    border-radius: 40px;
    border: none;
  }

  @media screen and (max-width: 575px) {
  }

  @media screen and (min-width: 450px) {
  }

  @media screen and (min-width: 992px) {
    zoom: 0.8;
  }

  @media screen and (min-width: 1400px) {
    .modal-xl {
      max-width: 930px;
    }
    zoom: 1;
  }
`;

export const CopyButton = styled.button`
  background: #fafafa;
  border: 1px solid #ababab;
  border-radius: 24px;
  height: 70px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  color: #3d3d3e;
  width: 100%;
  max-width: 568px;
  padding: 0 33px 0 53px;
  margin-bottom: 10px;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const CopyAlert = styled.div<ICopyAlertProps>`
  background: #116bff;
  position: absolute;
  transition: all 0.3s ease;
  bottom: 70px;
  right: 20px;
  //right: ${(props) => (props.show ? '10px' : '-1000px')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  z-index: 100;

  p {
    color: #fff !important;
  }
`;
