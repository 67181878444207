import Swal, { SweetAlertResult } from 'sweetalert2';
import frown from '~/assets/animations/no-data-found.gif';

interface ISwalError {
  message: string;
  textButton: string;
}

export default async function swalError({
  message,
  textButton,
}: ISwalError): Promise<SweetAlertResult<any>> {
  return Swal.fire({
    title: '',
    html: `
  <div>
    <img src="${frown}" alt="frown" />
    <p class="h1">Something went wrong...</p>
    <p>${message}</p>
  </div>
  `,
    customClass: 'error-swal-box',
    confirmButtonText: textButton,
  });
}
