import styled from 'styled-components';

export const Container = styled.div`
  background-color: #0f0c15;
  padding-bottom: 3rem !important;
  overflow-x: hidden;
  footer {
    background-color: #15131d;
    margin-bottom: 0 !important;

    h2,
    a,
    span {
      color: #cac6dd;
    }

    hr {
      background-color: #615e70;
    }
  }
  .btn-download {
    padding: 5px 65px 5px 41px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 20px;
    background: #116bff;
    color: #fff;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -1.36px;
    span span {
      display: block;
      text-align: end;
      font-family: 'Quicksand';
      font-size: 20px;
      font-weight: 500;
      line-height: 138.75%;
      letter-spacing: -0.8px;
    }
  }

  a.btn-yes {
    color: #fff;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -1.36px;
    border-radius: 22px;
    background: #858dff;
    border: none;
    min-height: 86px;
  }

  p.satisfaction {
    color: #116bff;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: 600;
    line-height: 138.75%;
    letter-spacing: -0.64px;
  }

  button.btn-no-thanks {
    margin: 0 auto;
    color: #ff1a50;
    background: transparent;
    padding: 0;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 24px;
    font-weight: 500;
    line-height: 138.75%;
    letter-spacing: -0.96px;
  }

  .cards {
    width: 100%;
    max-width: 430px;
  }

  .red {
    color: #ff1a50;
  }

  .fw-500 {
    font-weight: 500;
  }

  .circle-large-1 {
    background-color: #181634;
    width: 600px;
    height: 600px;
    border-radius: 600px;
    position: absolute;
    left: -100px;
    bottom: -300px;
    filter: blur(100px);
  }

  .circle-large-2 {
    background-color: #210d1b;
    width: 600px;
    height: 600px;
    border-radius: 600px;
    position: absolute;
    right: 18%;
    bottom: -300px;
    filter: blur(100px);
  }

  .circle-large-3 {
    background-color: #210d1b;
    width: 600px;
    height: 600px;
    border-radius: 600px;
    position: absolute;
    right: -250px;
    bottom: -104px;
    filter: blur(100px);
  }

  @media screen and (max-width: 991px) {
    a.btn-yes {
      font-size: 26px;
      padding: 10px 16px;
    }

    button.btn-no-thanks {
      font-size: 19px;
    }
  }

  @media screen and (min-width: 992px) {
    .container {
      max-width: 1320px !important;
    }
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.85;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }
`;

export const Attention = styled.div`
  position: relative;
  z-index: 2;
  background: #ff1a50;
  box-shadow: 0px 4px 4px rgba(255, 26, 80, 0.15);

  h2 {
    font-weight: 400;
    font-size: 18px;
  }

  svg {
    height: 30px;
    width: 30px;
  }

  @media screen and (max-width: 991px) {
    svg {
      height: 25px;
      width: 25px;
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 14px;
    }
  }
`;

export const Banner = styled.div`
  top: 0px;
  left: 0px;
  border: 1px solid #26242c;
  margin: 5rem 0rem;

  .union-1 {
    position: absolute;
    left: 8%;
    top: -66px;
  }

  .union-2 {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .cross-top-left {
    position: absolute;
    left: -5px;
    top: -5px;
  }

  .cross-top-right {
    position: absolute;
    right: -5px;
    top: -5px;
  }

  .cross-bottom-left {
    position: absolute;
    left: -5px;
    bottom: -5px;
  }

  .cross-bottom-right {
    position: absolute;
    right: -5px;
    bottom: -5px;
  }

  .circle-1 {
    opacity: 0.2;
    border-radius: 1016px;
    border: 1px solid #cac6dd;
    width: 70%;
    height: 65vw;
  }

  .circle-2 {
    border-radius: 858.064px;
    border: 1px solid #cac6dd;

    width: 85%;
    height: 85%;
  }

  .circle-3 {
    border-radius: 858.064px;
    border: 1px solid #cac6dd;
    width: 80%;
    height: 80%;
  }

  .circle-4 {
    border-radius: 858.064px;
    border: 1px solid #cac6dd;
    width: 70%;
    height: 70%;
  }

  .circle-5 {
    border-radius: 858.064px;
    border: 1px solid #cac6dd;
    width: 60%;
    height: 60%;
  }

  .square-dark-blue {
    position: absolute;
    right: 26%;
    bottom: 46%;
    width: 30%;
    height: 180px;
    background-color: #111744;
    filter: blur(93px);
  }

  .square-blue {
    position: absolute;
    right: 59%;
    bottom: 21%;
    width: 172px;
    height: 670px;
    background-color: #111239;
    filter: blur(93px);
  }

  .square-pink {
    position: absolute;
    right: 40%;
    top: 10%;
    width: 236px;
    height: 151px;
    background-color: #4f0f20;
    filter: blur(93px);
  }

  .square-black-1 {
    position: absolute;
    right: 14%;
    top: 7%;
    width: 364px;
    height: 364px;
    background-color: #141424;
    filter: blur(100px);
  }

  .square-black-2 {
    position: absolute;
    right: 18%;
    bottom: 14%;
    width: 364px;
    height: 364px;
    background-color: #141424;
    filter: blur(100px);
  }

  @media screen and (max-width: 575px) {
    margin: 2rem 0rem;
  }
`;

export const Gpt = styled.div`
  position: relative;
  z-index: 2;
  h1 {
    color: #858dff;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;

    span {
      color: #cac6dd;
      font-weight: 400;
      font-size: 36px;
    }
  }

  h3 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }

  h4 {
    color: #021027;
    font-family: 'Archivo';
    font-size: 24px;
    font-weight: 300;
    line-height: 125%;

    span:nth-child(1) {
      font-weight: 700;
    }

    span:nth-child(2) {
      color: #116bff;
      font-family: 'Architects Daughter';
      font-size: 28px;
      font-weight: 400;
      line-height: 125%;
    }
  }

  img {
    width: 431px;
    height: 88px;
  }

  .lootie > div {
    margin: 0 10px !important;
  }

  .thumb,
  video {
    border: 1px solid #303030;
    -webkit-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
  }

  .thumb {
    width: 100%;
    border-radius: 25px;
  }

  @media screen and (max-width: 991px) {
    h1 {
      font-size: 30px;
      line-height: 100%;
    }
    h2 {
      font-size: 30px;
    }
    h4 {
      font-size: 21px;
    }
  }

  @media screen and (max-width: 575px) {
    h1 {
      font-size: 22px;
      span {
        font-size: 22px;
      }
    }
    h3 {
      font-size: 19px;
    }

    img {
      width: 100%;
      max-width: 431px;
      height: auto;
    }

    .btn-download {
      padding: 5px 40px 5px 15px;
    }
  }
`;

export const Steps = styled.div`
  position: relative;
  z-index: 2;
  h2 {
    color: #858dff;

    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;

    u {
      color: #fff;
      font-weight: 400;
    }
  }

  h3 {
    color: #cac6dd;

    font-family: 'Inter';
    font-size: 48px;
    font-weight: 600;
    line-height: 109.5%;

    span {
      background: linear-gradient(
        270.04deg,
        #fb5a43 0.03%,
        #c341ae 70.44%,
        #9445c2 80.35%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .step1 {
    background-color: #15131d;
    h4 {
      color: #fff;
      font-family: 'Inter';
      font-size: 48px;
      font-weight: 600;
      line-height: 109.5%;
    }

    h5 {
      color: #ffc876;

      font-family: 'Archivo';
      font-size: 34px;
      font-weight: 800;
      line-height: 138.75%;
    }

    h6 {
      color: #ff1a50;
      font-family: 'Architects Daughter';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 138.75%;
    }

    p,
    li {
      color: #cac6dd;

      font-family: 'Archivo';
      font-size: 26px;
      font-weight: 300;
      line-height: 138.75%;
    }

    hr {
      background: #606060;
      opacity: 1;
    }
  }

  .step2 {
    border-radius: 0px 20px 20px 0px;
    background: #ac6aff;
    box-shadow: 10px 9px 37px 0px rgba(41, 41, 41, 0.3);

    h4 {
      color: #fff;
      font-family: 'Inter';
      font-size: 48px;
      font-weight: 600;
      line-height: 109.5%;
    }

    h5 {
      color: #fff;

      font-family: 'Archivo';
      font-size: 34px;
      font-weight: 800;
      line-height: 138.75%;
    }

    p,
    a {
      color: #fff;

      font-family: 'Archivo';
      font-size: 26px;
      font-style: normal;
      font-weight: 300;
      line-height: 138.75%;
      span {
        color: #ffda55;

        font-weight: 400;
      }
    }

    a:hover {
      text-decoration: underline !important;
    }

    hr {
      background: #fff;
      opacity: 1;
    }
  }

  .border-gray-left {
    border-radius: 20px 0 0 20px;
    border-top: 2px solid #9490a7;
    border-left: 2px solid #9490a7;
    border-bottom: 2px solid #9490a7;
  }

  .border-gray-right {
    border-radius: 0 20px 20px 0;
    border-top: 2px solid #9490a7;
    border-right: 2px solid #9490a7;
    border-bottom: 2px solid #9490a7;
  }

  .quote {
    width: 57px;
    height: 57px;
    margin-left: 15px;
  }

  @media screen and (max-width: 991px) {
    .border-gray-left {
      border-radius: 20px;
      border: 2px solid #9490a7;
    }

    .border-gray-right {
      border-radius: 20px;
      border: 2px solid #9490a7;
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 40px;
    }
    h3 {
      font-size: 29px;
    }

    .step1 {
      h4 {
        font-size: 35px;
      }
      h5 {
        font-size: 28px;
      }
      h6 {
        font-size: 24px;
      }
      p,
      li {
        font-size: 22px;
      }
    }
    .step2 {
      h4 {
        font-size: 35px;
      }
      h5 {
        font-size: 28px;
      }
      h6 {
        font-size: 24px;
      }
      p,
      a {
        font-size: 22px;
      }
    }

    .quote {
      width: 35px;
      height: 35px;
    }
  }
`;

export const Access = styled.div`
  margin-bottom: 7rem;
  position: relative;
  z-index: 2;
  h2 {
    color: #cac6dd;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 400;
    line-height: 119.5%;
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 3rem;
    h2 {
      font-size: 35px;
    }
  }
`;

export const Dear = styled.div`
  position: relative;
  z-index: 2;
  border-radius: 30px;
  border: 4px dashed #ff776f;
  background: #15131d;
  margin: 0 10px;
  position: relative;
  h2,
  p {
    color: #cac6dd;
    font-family: 'Architects Daughter';
    font-size: 26px;
    font-weight: 400;
    line-height: 150%;
  }

  h3 {
    color: #ff776f;
    font-family: 'Architects Daughter';
    font-size: 32px;
    font-weight: 400;
    line-height: 150%;
  }

  h4 {
    color: #cac6dd;
    font-family: 'Archivo';
    font-size: 26px;
    font-style: italic;
    font-weight: 600;
    line-height: 150%;
  }

  .logo {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 52px;
    height: 48px;
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 25px;
    }

    h4 {
      font-size: 20px;
    }

    p {
      font-size: 22px;
    }
  }
`;

export const Current = styled.div`
  position: relative;
  z-index: 2;
  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;
    span {
      color: #116bff;
    }
  }

  h3 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 40px;
    font-weight: 400;
    line-height: 119.5%;
  }

  button {
    padding-left: 36px;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -1.36px;
    border-radius: 40px;
    background: #116bff;
    border: none;
    span {
      margin: 13px 13px 13px 36px;
      padding: 13px 24px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.32);
    }
  }

  .btn-no-thanks {
    margin: 0 auto;
    color: #116bff;
    background: transparent;
    padding: 0;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 24px;
    font-weight: 500;
    line-height: 138.75%;
    letter-spacing: -0.96px;
  }

  .only {
    color: #021027;
    text-align: center;
    font-family: 'Archivo';
    font-size: 60px;
    font-weight: 700;
    line-height: 125%;
    span {
      font-weight: 800;
      background: linear-gradient(
        270.04deg,
        #fb5a43 0.03%,
        #c341ae 75.44%,
        #9445c2 99.35%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .saving-text {
    color: #116bff;
    text-align: center;
    font-family: 'Loved by the King';
    font-size: 60px;
    font-weight: 600;
    line-height: 119.5%;
  }

  .text-btn-send {
    color: #ff2e00;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 28px;
    font-weight: 600;
    line-height: 138.75%;
    letter-spacing: -1.12px;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 34px;
    }

    h3 {
      font-size: 28px;
    }

    button {
      font-size: 26px;
      padding-left: 20px;
    }

    .only {
      font-size: 34px;
      span {
        background: linear-gradient(
          270.04deg,
          #fb5a43 0.03%,
          #c341ae 30.44%,
          #9445c2 57.35%,
          #079cfe 95.73%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .saving-text {
      font-size: 38px;
    }

    .text-btn-send {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    button {
      font-size: 26px;
      padding-left: 20px;
    }

    button span {
      margin: 13px 13px 13px 18px;
      padding: 13px 13px;
    }

    .btn-no-thanks {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 425px) {
    button {
      font-size: 23px;
    }
  }

  @media screen and (min-width: 768px) {
  }
`;
