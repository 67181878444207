import React from 'react';

import { Helmet } from 'react-helmet';
import { Container } from './styles';

const RefundPolicy: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col pt-5 my-5">
            <h1>
              AutoAffiliate.ai Cancellation and{' '}
              <br className="d-none d-xl-block" /> No Refund Policy
            </h1>
            <h2>Cancellation and No Refund Policy</h2>
            <p>
              Welcome to AutoAffiliate, where we prioritize both your
              satisfaction and the clarity of our service agreements. This
              enhanced policy provides detailed guidelines on our cancellation,
              refund processes, and outlines the scope of user responsibilities
              to ensure a transparent and fair experience for all our users.
            </p>
            <h2>Subscription Cancellation Policy</h2>
            <p>
              <b>Active Subscriptions:</b> Users may cancel their subscriptions
              at any time. To cancel, please visit your account settings or
              contact our support team for guidance. Upon cancellation, your
              access to the paid services will end immediately, and future
              billing will cease.
            </p>
            <p>
              <b>No Refund for Unused Time:</b> Please note, we do not issue
              refunds for any remaining portion of your subscription term. This
              policy reflects the immediate access provided to our comprehensive
              resources upon subscription.
            </p>
            <h2>30-Day No-Questions-Asked Refund Guarantee</h2>
            <p>
              <b>Scope:</b> Our 30-day refund policy applies exclusively to
              AutoAffiliate products purchased directly through our platform.
              If, for any reason, you are dissatisfied within the first 30 days
              after purchase, you are entitled to a full refund.
            </p>
            <p>
              <b>Process:</b> To initiate a refund, contact
              support@AutoAffiliate.ai with your order details. Our team will
              process your request promptly, with refunds issued to the original
              payment method within 7-10 business days.
            </p>
            <h2>Events and Mastermind Sessions</h2>
            <p>
              <b>Final Sale:</b> Registrations for our events and mastermind
              sessions are non-refundable, except in circumstances where the
              event is canceled by AutoAffiliate.
            </p>
            <p>
              <b>Cancellation by AutoAffiliate:</b> In the rare event we cancel
              an event, registered participants will receive a full refund or
              the option to transfer registration to a future event.
            </p>
            <h2>Policy on Free Services</h2>
            <p>
              Our free services do not require any form of cancellation and are
              available for continuous use, providing an opportunity to
              experience AutoAffiliate without financial commitment.
            </p>
            <h2>Third-Party Offers and Refunds</h2>
            <p>
              <b>Limitation of Responsibility:</b> Refunds for third-party
              products or services advertised on AutoAffiliate are governed by
              the third party’s refund policy. AutoAffiliate cannot process
              refunds for these external offers.
            </p>
            <p>
              <b>Guidance:</b> We advise reviewing the third-party provider's
              refund policy before purchase. For issues, please contact the
              third-party provider directly.
            </p>
            <h2>Subscription Auto-Renewal and Cancellation</h2>
            <p>
              <b>Auto-Renewal:</b> Subscriptions automatically renew to ensure
              uninterrupted service. Users can opt out of auto-renewal through
              account settings or by contacting our support team.
            </p>
            <p>
              <b>Preventive Measures:</b> To avoid unwanted charges, please
              cancel your subscription before the renewal date. Assistance is
              available through our support channels.
            </p>
            <h2>Legal Compliance</h2>
            <p>
              This policy adheres to international and local laws regarding
              refunds and cancellations, ensuring our practices are legally
              compliant and transparent.
            </p>

            <h2>Resolution of Complaints</h2>
            <p>
              For any concerns or complaints regarding cancellations and
              refunds, please contact us directly:
            </p>
            <ul>
              <li>
                <b>Email:</b>{' '}
                <a
                  href="mailto:support@AutoAffiliate.ai"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@AutoAffiliate.ai
                </a>
              </li>
              <li>
                <b>Physical Address:</b> SmartTech Labs, Inc., 30 N Gould St Ste
                R, Sheridan, WY, 92801, USA
              </li>
            </ul>
            <p>
              Escalated concerns can be addressed to our Data Protection Officer
              at the same contact points.
            </p>
            <h2>International Data Transfers</h2>
            <p>
              Data related to cancellations and refunds may be processed and
              stored internationally, in compliance with global data protection
              regulations. Our transfer mechanisms ensure your data’s security
              and privacy.
            </p>

            <h2>Policy Updates</h2>
            <p>
              Our cancellation and refund policy is subject to updates.
              Significant changes will be communicated via our website and
              through direct communication to our users.
            </p>
            <h3 className="h4 fw-semibold mb-4">
              By engaging with AutoAffiliate’s services and products, you
              acknowledge and accept the terms outlined in this enhanced
              cancellation and refund policy.{' '}
            </h3>
            <h3 className="h4 fw-semibold">
              Our goal is to ensure transparency and fairness in all
              transactions, safeguarding both your interests and our integrity
              as your chosen service provider.
            </h3>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RefundPolicy;
