/* eslint-disable no-console */
import React, { useCallback, useState } from 'react';

import { BsDash, BsPlus } from 'react-icons/bs';

import Fade from 'react-reveal/Fade';

import { useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';

import {
  Container,
  DontWait,
  Feeling,
  Join,
  Members,
  PowerAi,
  Questions,
} from './styles';

import discover1 from '~/assets/defaults/discover1.png';
import discover2 from '~/assets/defaults/discover2.png';
import discover3 from '~/assets/defaults/discover3.png';
import discover4 from '~/assets/defaults/discover4.png';
import discover5 from '~/assets/defaults/discover5.png';
import discover6 from '~/assets/defaults/discover6.png';

import socialHome from '~/assets/defaults/social-home.png';

import logo from '~/assets/logos/logo.svg';
import quote from '~/assets/icons/quote.svg';
import bishop from '~/assets/defaults/bishop.png';
import jeremy from '~/assets/defaults/jeremy.png';
import tee from '~/assets/defaults/tee.png';
import jason from '~/assets/defaults/jason.png';
import bishopThumb from '~/assets/defaults/bishop-thumb.png';
import jeremyThumb from '~/assets/defaults/jeremy-thumb.png';
import teeThumb from '~/assets/defaults/tee-thumb.png';
import jasonThumb from '~/assets/defaults/jason-thumb.png';
import mobHero from '~/assets/banners/banner-hero-mob.png';

import Carousel from '~/components/Carousel';
import Disclaimer from '~/components/Disclaimer';
import PlayerMembers from '~/components/PlayerMembers';
import ModalEnterEmail from '~/components/ModalEnterEmail';

interface IParams {
  reference?: string;
  origin?: string;
}

const Home: React.FC = () => {
  const params = useParams<IParams>();
  const [answer, setAnswer] = useState('');

  const handleClickQuestion = useCallback(
    (data) => {
      if (answer === data) {
        setAnswer('');
      } else {
        setAnswer(data);
      }
    },
    [answer]
  );

  return (
    <>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image:secure_url"
          itemProp="image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="autoaffiliate.ai" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <Container>
        <Join>
          <div id="home" className="container position-relative mt-4 mt-md-5">
            <div className="row zoom">
              <div className="col-lg-7 position-relative px-4 px-md-3 ps-lg-5">
                <Fade>
                  <img src={logo} alt="logo" className="logo" />
                  <span className="d-block mt-4 mb-3">
                    Social Media Users Looking for Additional Income
                  </span>
                  <h1 className="position-relative">
                    Your Social Accounts{' '}
                    <span>Should Be Earning You Money</span> - Let Our AI Bot Do
                    the Work.
                  </h1>
                  <p className="archivo pb-2 mb-2">
                    <b>
                      Install <span className="master">our Free AI Bot</span>
                    </b>{' '}
                    and have it bring in{' '}
                    <b>
                      leads and <br className="d-none d-lg-block" />
                      sales, 24/7 on autopilot!
                    </b>
                  </p>{' '}
                  <ModalEnterEmail
                    btnText="Install the Free AI Bot & Let It Start Making Money for You!"
                    className="mt-3 mb-4 btn-yes w-100"
                    origin={`${
                      params.origin ? `${params.origin}, ` : ''
                    }Main Site`}
                  />
                </Fade>
              </div>
            </div>
          </div>
          <img src={mobHero} alt="hero" className="w-100 d-lg-none mt-3" />
        </Join>

        <PowerAi>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11 text-center mt-5 pt-5">
                <Fade>
                  <h2 className="mb-5 mb-md-0">
                    Most People Waste Hours Scrolling on Social Media -
                  </h2>
                  <h3 className="mb-5 pb-4">
                    You Could Be Turning That Time into Income
                  </h3>
                </Fade>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="row justify-content-center">
                  <div className="col-md-4 px-4 px-md-3 ps-lg-5 mb-4 mb-md-5">
                    <div className="position-relative">
                      <Fade>
                        <p>
                          <span className="color-principal fw-semibold">
                            Most people spend hours scrolling through social
                            media, consuming content and missing a huge
                            opportunity to earn money.
                          </span>{' '}
                          Social media isn’t just for entertainment anymore –{' '}
                          <span className="color-principal fw-semibold">
                            it’s one of the biggest platforms for generating
                            income,
                          </span>{' '}
                          yet millions aren’t taking advantage of it.
                        </p>
                      </Fade>
                    </div>
                  </div>
                  <div className="col-md-4 px-4 px-md-3 ps-lg-5 mb-4 mb-md-5">
                    <div className="position-relative">
                      <Fade>
                        <p>
                          While you’re watching viral videos or engaging with
                          posts,{' '}
                          <span className="color-principal fw-bold">
                            you could be making sales.
                          </span>{' '}
                          You’re already investing time in your accounts and
                          growing your followers, but are you earning from it?{' '}
                          <span className="color-secondary fw-bold fst-italic">
                            What if that time turned into a real income stream?
                          </span>
                        </p>
                      </Fade>
                    </div>
                  </div>
                  <div className="col-md-4 px-4 px-md-3 px-lg-5 mb-5">
                    <div className="position-relative">
                      <Fade>
                        <p>
                          Now, imagine your social accounts working for you,
                          <span className="color-principal fw-bold">
                            making money even while you sleep.
                          </span>{' '}
                          It’s frustrating to realize that this opportunity is
                          right in front of you, yet untapped.
                        </p>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 px-4 px-md-3 px-lg-5 mb-md-5 pb-5">
                <h4 className="text-center text-md-start">
                  Social media can be more than just a time sink –{' '}
                  <span className="color-principal fw-bold">
                    it can be a real source of income.
                  </span>{' '}
                  And the best part?{' '}
                  <span className="color-principal fw-bold">
                    You don’t need to lift a finger.
                  </span>{' '}
                  <span className="color-secondary fw-bold fst-italic">
                    That’s where the AI bot comes in.
                  </span>
                </h4>
              </div>
              <div className="col-12 d-md-none mb-5">
                <ModalEnterEmail
                  btnText="Install the Free AI Bot & Let It Start Making Money for You!"
                  className="mt-3 mb-4 btn-yes w-100"
                  origin={`${
                    params.origin ? `${params.origin}, ` : ''
                  }Main Site`}
                />
              </div>
            </div>
          </div>
        </PowerAi>

        <Feeling>
          <div className="container mb-lg-5 pt-5 pb-5">
            <div className="row justify-content-center align-items-center ">
              <div className="col-md-6 mx-auto px-4 px-md-3">
                <Fade>
                  <h2 className="pe-lg-5 mt-5 mt-md-0mb-5">
                    <span>
                      Turn Your Social Accounts into a 24/7 Sales Machine –
                    </span>
                    Without&nbsp;Spamming Your Friends&nbsp;or Family.
                  </h2>
                  <div className="d-md-none pe-5 pt-5 pe-md-3 pt-md-3 pe-lg-5 pt-lg-5 position-relative mb-5 pb-5">
                    <div className="box zoom" />
                    <img src={socialHome} alt="Social" className="w-100 zoom" />
                  </div>
                  <p className="mb-4 pb-3">
                    Our AI bot turns your social media into a 24/7 sales
                    machine.{' '}
                    <span className="color-principal fw-bold">
                      It works for you – finding leads, chatting naturally, and
                      closing deals
                    </span>{' '}
                    – while you focus on other things. No awkward pitches or
                    wasted time.
                  </p>
                  <p className="mb-4 pb-3">
                    <span className="color-principal fw-bold">
                      The bot seamlessly blends into your social platforms,
                      engaging real prospects and turning them into paying
                      customers
                    </span>{' '}
                    – all without your involvement. It’s like having a tireless
                    sales team.
                  </p>
                  <p>
                    Best of all, your personal network stays untouched. Unlike
                    spammy tools, this{' '}
                    <span className="color-principal fw-bold">
                      AI bot connects with real leads, never bothering your
                      friends or family.
                    </span>
                  </p>
                </Fade>
              </div>
              <div className="d-none d-md-block col-md-6 col-xl-5 pe-5 pt-5 pe-md-3 pt-md-3 pe-lg-5 pt-lg-5 position-relative">
                <Fade>
                  <div className="box zoom" />
                  <img src={socialHome} alt="Social" className="w-100 zoom" />
                </Fade>
              </div>
              <div className="col-lg-11 px-4 px-md-3 mt-5">
                <h3 className="pb-5">
                  You don’t need to manage it either.{' '}
                  <span className="fw-medium">
                    It’s fully automated, risk-free, and works while you sleep,
                  </span>{' '}
                  work, or take a break.
                  <span className="fw-semibold color-secondary fst-italic">
                    It makes your social media work for you,
                  </span>{' '}
                  <span className="fw-bold color-secondary">
                    not the other way around.
                  </span>
                </h3>
                <ModalEnterEmail
                  btnText="Install the Free AI Bot & Let It Start Making Money for You!"
                  className="mt-3 mb-4 btn-yes w-100"
                  origin={`${
                    params.origin ? `${params.origin}, ` : ''
                  }Main Site`}
                />
              </div>
            </div>
          </div>
        </Feeling>

        <Members>
          <div id="Stories" className="container mt-lg-5 py-5 ">
            <div className="row">
              <div className="col-12 px-4 px-md-3 ps-sm-5">
                <Fade>
                  <h2 className="text-md-center">
                    Don't just take our word for it{' '}
                  </h2>
                </Fade>
                <Fade>
                  <h3 className="mb-4 mb-sm-2 text-md-center">
                    See what our members have to&nbsp;say:
                  </h3>
                </Fade>
              </div>
            </div>
          </div>
          <div className="bg-shadow">
            <div className="container">
              <div className="row">
                <div className="col-12 px-0 px-sm-3 mt-lg-5 pt-3">
                  <Fade>
                    <div className="d-none d-md-block">
                      <div className="pb-lg-5">
                        <div className="px-2 d-md-flex justify-content-center justify-content-xxl-end align-items-center">
                          <div className="box-member p-3 p-sm-5 p-md-3 p-lg-4 p-xl-5  mb-n5 mb-md-n1 mx-auto ms-md-0 me-md-n5">
                            <div className="d-flex align-items-start pt-3">
                              <img
                                src={quote}
                                alt="quote"
                                className="quote mt-n2 me-sm-2"
                              />
                              <h4>AutoAffiliate is a total game-changer”</h4>
                            </div>
                            <p className="text-md-start my-2 my-sm-4 my-md-2 my-lg-3 my-xl-4">
                              “Its automation and AI features fit seamlessly
                              into my lifestyle. It has helped me on prospecting
                              and building relationships, which has started to
                              open up new income opportunities. It feels like
                              I'm building my own freedom business!"
                            </p>
                            <div className="d-flex align-items-center member-info px-4 px-sm-0 pt-2 pb-3">
                              <img src={bishop} alt="bishop" className="me-3" />
                              <div className="text-start mt-2">
                                <h3 className="mb-0">Bishop Stewart</h3>
                                <p className="mb-0">Member since 2023</p>
                              </div>
                            </div>
                          </div>
                          <div className="member-banner">
                            <PlayerMembers
                              videoSelected="videoMembers1"
                              src="https://cdn.autoaffiliate.ai/videos/Zoom.mp4"
                              thumbnail={bishopThumb}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="py-5">
                        <div className="px-2 d-md-flex justify-content-center justify-content-xxl-end align-items-center">
                          <div className="member-banner hide-btn">
                            <PlayerMembers
                              videoSelected="videoMembers2"
                              src="https://cdn.autoaffiliate.ai/videos/Zoom.mp4"
                              thumbnail={teeThumb}
                            />
                          </div>
                          <div className="box-member p-3 p-sm-5 p-md-3 p-lg-4 p-xl-5  mb-n5 mb-md-n1 mx-auto ms-md-0 ms-md-n5">
                            <div className="d-flex align-items-start pt-3">
                              <img
                                src={quote}
                                alt="quote"
                                className="quote mt-n2 me-sm-2"
                              />
                              <h4>More Than a Team, I’m Growing a Family ”</h4>
                            </div>
                            <p className="text-md-start my-2 my-sm-4 my-md-2 my-lg-3 my-xl-4">
                              I've met some amazing people along the way! And
                              all while making money! This journey has taught me
                              that every step counts, no matter how small. I'm
                              grateful for being part of something so incredible
                              from the start!
                            </p>
                            <div className="d-flex align-items-center member-info px-4 px-sm-0 pt-2 pb-3">
                              <img src={tee} alt="Tee" className="me-3" />
                              <div className="text-start mt-2">
                                <h3 className="mb-0">Tee Hansen</h3>
                                <p className="mb-0">Member since 2023</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="py-lg-5">
                        <div className="px-2 d-md-flex justify-content-center justify-content-xxl-end align-items-center">
                          <div className="box-member p-3 p-sm-5 p-md-3 p-lg-4 p-xl-5  mb-n5 mb-md-n1 mx-auto ms-md-0 me-md-n5">
                            <div className="d-flex align-items-start pt-3">
                              <img
                                src={quote}
                                alt="quote"
                                className="quote mt-n2 me-sm-2"
                              />
                              <h4>
                                AutoAffiliate is more than just a platform.”
                              </h4>
                            </div>
                            <p className="text-md-start my-2 my-sm-4 my-md-2 my-lg-3 my-xl-4">
                              “It's a community of like-minded individuals
                              striving for financial freedom. Their robust tools
                              and unwavering support have helped me a lot with
                              my affiliate marketing journey.
                            </p>
                            <div className="d-flex align-items-center member-info px-4 px-sm-0 pt-2 pb-3">
                              <img src={jeremy} alt="Avatar" className="me-3" />
                              <div className="text-start mt-2">
                                <h3 className="mb-0">Jeremy Engel</h3>
                                <p className="mb-0">Member since 2023</p>
                              </div>
                            </div>
                          </div>
                          <div className="member-banner hide-btn">
                            <PlayerMembers
                              videoSelected="videoMembers3"
                              src="https://cdn.autoaffiliate.ai/videos/Zoom.mp4"
                              thumbnail={jeremyThumb}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="py-5">
                        <div className="px-2 d-md-flex justify-content-center justify-content-xxl-end align-items-center">
                          <div className="member-banner hide-btn">
                            <PlayerMembers
                              videoSelected="videoMembers4"
                              src="https://cdn.autoaffiliate.ai/videos/Zoom.mp4"
                              thumbnail={jasonThumb}
                            />
                          </div>
                          <div className="box-member p-3 p-sm-5 p-md-3 p-lg-4 p-xl-5  mb-n5 mb-md-n1 mx-auto ms-md-0 ms-md-n5">
                            <div className="d-flex align-items-start pt-3">
                              <img
                                src={quote}
                                alt="quote"
                                className="quote mt-n2 me-sm-2"
                              />
                              <h4>
                                The AutoAffiliate system has been amazing”
                              </h4>
                            </div>
                            <p className="text-md-start my-2 my-sm-4 my-md-2 my-lg-3 my-xl-4">
                              “It's user-friendly, and I'm impressed with their
                              innovative bot. I've started seeing some earnings,
                              even passively. For those passionate about
                              affiliate marketing, it's definitely worth
                              exploring.”
                            </p>
                            <div className="d-flex align-items-center member-info px-4 px-sm-0 pt-2 pb-3">
                              <img src={jason} alt="jason" className="me-3" />
                              <div className="text-start mt-2">
                                <h3 className="mb-0">Jason Davis</h3>
                                <p className="mb-0">Member since 2023</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Carousel className="caroussel d-md-none" />
                    <p className="result-text text-center px-4 px-md-0">
                      Results may vary. This testimonials reflects the personal
                      experience of a few individuals and is not indicative of
                      typical results.
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </Members>

        <Questions id="faq" className="py-5">
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-12 mb-5 pb-5 mb-sm-0 pb-sm-0">
                <Fade>
                  <h2 className="text-center">Common questions</h2>
                </Fade>
              </div>

              <div className="col-11 my-sm-5 border-questions">
                <Fade>
                  <div
                    className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover-top ${
                      answer === 'question_1' ? 'bg-answer' : ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => handleClickQuestion('question_1')}
                      className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                    />
                    <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                      <span className="h2-sm h1-xl mb-0 fw-bold">01</span>
                    </div>
                    <div className="col-sm-8 col-lg-9 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                      <p className="h5-sm h4-xl mb-0 text-grey">
                        How much does it cost to install the bot?
                      </p>
                    </div>
                    <div className="col-sm-2 text-center px-0">
                      <button type="button" className="border-0 bg-transparent">
                        <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                          {answer !== 'question_1' ? (
                            <BsPlus size={25} />
                          ) : (
                            <BsDash size={25} />
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="col-2 col-lg-1 d-none d-lg-block" />
                    <div className="col-12 col-lg-10">
                      <p
                        className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                          answer === 'question_1' ? 'active mt-4' : ''
                        }`}
                      >
                        No, the bot only engages with real leads, so your
                        friends and family won’t receive any messages.
                      </p>
                    </div>
                    <div className="col-1 d-none d-lg-block" />
                  </div>
                </Fade>
                <Fade>
                  <div
                    className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                      answer === 'question_2' ? 'bg-answer' : ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => handleClickQuestion('question_2')}
                      className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                    />
                    <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                      <span className="h2-sm h1-xl mb-0 fw-bold">02</span>
                    </div>
                    <div className="col-sm-8 col-lg-9 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                      <p className="h6 h5-sm h4-xl mb-0 text-grey ">
                        Does the bot message my friends and family?
                      </p>
                    </div>
                    <div className="col-sm-2 text-center px-0">
                      <button type="button" className="border-0 bg-transparent">
                        <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                          {answer !== 'question_2' ? (
                            <BsPlus size={25} />
                          ) : (
                            <BsDash size={25} />
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="col-2 col-lg-1 d-none d-lg-block" />
                    <div className="col-12 col-lg-10">
                      <p
                        className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                          answer === 'question_2' ? 'active mt-4' : ''
                        }`}
                      >
                        The bot is completely free to install and use.
                      </p>
                    </div>
                    <div className="col-1 d-none d-lg-block" />
                  </div>
                </Fade>
                <Fade>
                  <div
                    className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                      answer === 'question_3' ? 'bg-answer' : ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => handleClickQuestion('question_3')}
                      className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                    />
                    <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                      <span className="h2-sm h1-xl mb-0 fw-bold">03</span>
                    </div>
                    <div className="col-sm-8 col-lg-9 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                      <p className="h5-sm h4-xl mb-0 text-grey">
                        How does the bot find leads?
                      </p>
                    </div>
                    <div className="col-sm-2 text-center px-0">
                      <button type="button" className="border-0 bg-transparent">
                        <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                          {answer !== 'question_3' ? (
                            <BsPlus size={25} />
                          ) : (
                            <BsDash size={25} />
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="col-2 col-lg-1 d-none d-lg-block" />
                    <div className="col-12 col-lg-10">
                      <p
                        className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                          answer === 'question_3' ? 'active mt-4' : ''
                        }`}
                      >
                        Our AI bot is designed to identify and engage with
                        potential buyers based on their social behavior,
                        ensuring high-quality leads.
                      </p>
                    </div>
                    <div className="col-1 d-none d-lg-block" />
                  </div>
                </Fade>
                <Fade>
                  <div
                    className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 hover-bottom ${
                      answer === 'question_4' ? 'bg-answer' : ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => handleClickQuestion('question_4')}
                      className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                    />
                    <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                      <span className="h2-sm h1-xl mb-0 fw-bold">04</span>
                    </div>
                    <div className="col-sm-8 col-lg-9 text-center text-sm-start my-4 my-sm-0">
                      <p className="h5-sm h4-xl mb-0 text-grey">
                        How quickly can I start earning money?
                      </p>
                    </div>
                    <div className="col-sm-2 text-center px-0 px-xl-3">
                      <button type="button" className="border-0 bg-transparent">
                        <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                          {answer !== 'question_4' ? (
                            <BsPlus size={25} />
                          ) : (
                            <BsDash size={25} />
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="col-2 col-lg-1 d-none d-lg-block" />
                    <div className="col-12 col-lg-10">
                      <p
                        className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                          answer === 'question_4' ? 'active mt-4' : ''
                        }`}
                      >
                        Many users start seeing results within days of
                        installing the bot, but letting the bot work
                        consistently is key.
                      </p>
                    </div>
                    <div className="col-1 d-none d-lg-block" />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </Questions>

        <DontWait>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-10 col-lg-9 text-center">
                <Fade>
                  <h2 className="mb-5 mb-sm-4 mt-5 mt-sm-0">
                    <span>Don't wait</span> <span>any longer!</span>{' '}
                    <br className="d-sm-none" />
                    Start building your financial future today.
                  </h2>
                  <h3 className="mb-5 pb-4">
                    Install the free AI bot now and unlock a new income stream
                    without the hassle. Our platform makes earning from social
                    media effortless, setting you on the path to success.
                  </h3>
                </Fade>
              </div>
              <div className="col-sm-11">
                <div className="bg-discover py-5 position-relative">
                  <Fade cascade>
                    <img
                      src={discover1}
                      alt="Person"
                      className="pe-none discover1 position-absolute zoom"
                    />
                    <img
                      src={discover2}
                      alt="Person"
                      className="d-none d-sm-block pe-none discover2 position-absolute zoom"
                    />
                    <img
                      src={discover3}
                      alt="Person"
                      className="d-none d-sm-block pe-none discover3 position-absolute zoom"
                    />
                    <img
                      src={discover4}
                      alt="Person"
                      className="pe-none discover4 position-absolute zoom"
                    />
                    <img
                      src={discover5}
                      alt="Person"
                      className="pe-none discover5 position-absolute zoom"
                    />
                    <img
                      src={discover6}
                      alt="Person"
                      className="pe-none discover6 position-absolute zoom"
                    />
                  </Fade>
                  <div className="row">
                    <div className="col-10 col-sm-8 col-xl-9 mx-auto text-center px-0 px-sm-3 pt-5 py-sm-5">
                      <Fade>
                        <h3 className="mb-4 mb-sm-2">
                          JOIN NOW AND DISCOVER
                          <br className="d-md-none" /> HOW YOU CAN
                        </h3>
                      </Fade>
                      <Fade>
                        <h2 className="mb-4 my-sm-3">
                          Build Additional Income <br />
                          Streams with Our AI Bot!{' '}
                        </h2>
                      </Fade>
                      <Fade>
                        <p className="px-4 px-sm-0 mb-0">
                          Do not miss this opportunity!
                        </p>
                      </Fade>
                      <Fade>
                        <ModalEnterEmail
                          btnText="Show Me How"
                          className="mt-3"
                          origin={`${
                            params.origin ? `${params.origin}, ` : ''
                          }Main Site`}
                        />
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DontWait>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-11 my-5 py-5">
              <Disclaimer />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
