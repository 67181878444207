/* eslint-disable jsx-a11y/anchor-is-valid */
import '@animxyz/core';
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css';

import Routes from '~/routes';
import GlobalStyles from './styles/global';

import AppProvider from './hooks';

const App: React.FC = () => {
  const [pathname, setPathname] = useState('');

  return (
    <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
      <BrowserRouter>
        <AppProvider>
          <Routes setPathname={setPathname} />
        </AppProvider>
        <GlobalStyles pathname={pathname} />
      </BrowserRouter>
      {/* <a href="#" id="open_preferences_center">
        <span>
          <FaCookieBite size={24} color="#fff" />
        </span>
        <span>
          <FaCookieBite size={24} color="#fff" />
        </span>
      </a> */}
    </HttpsRedirect>
  );
};

export default App;
