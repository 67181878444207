import React, { useCallback, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

// import { Container } from './styles';

const FormCard: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements() as any;

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      // Verifica se uma tecla foi pressionada
      if (event.key) {
        // Exibe a tecla pressionada no console (você pode fazer outra ação aqui)
        console.log(`Tecla pressionada: ${event.key}`);
      }
    });
  }, []);

  const handleFocus = useCallback((e) => {
    console.log(e);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const result = await stripe?.createToken(
        elements.getElement(CardElement)
      );

      // if (result.token) {
      //   // Enviar o token para o servidor Node.js
      //   fetch('http://localhost:3001/criar-pagamento', {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ token: result.token.id }),
      //   })
      //     .then((response) => response.json())
      //     .then((data) => {
      //       if (data.success) {
      //         alert('Pagamento bem-sucedido!');
      //       } else {
      //         alert(`Erro ao processar pagamento: ${data.message}`);
      //       }
      //     });
      // }
    },
    [elements, stripe]
  );
  return (
    <form onSubmit={handleSubmit} className="w-25 mx-auto py-5">
      <CardElement onFocus={handleFocus} />
      <button type="submit" className="btn btn-primary mt-3 w-100">
        Enviar
      </button>
    </form>
  );
};

export default FormCard;
