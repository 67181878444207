import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';

export const Container = styled.div``;

export const Modal = styled(ModalComponent)`
  background-color: rgba(0, 0, 0, 0.5);

  h2 {
    color: #303030;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
  }
  label {
    color: #8c8c8c;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
  }

  input {
    color: #021027;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
  }

  .check-position {
    position: absolute;
    right: 4%;
    top: 7%;
    width: 17px;
  }

  .bd-dashed {
    border-radius: 16px !important;
    border: 2px dashed #ffb74a !important;
  }

  .bd-blue {
    border-radius: 16px;
    border: 1px solid #116bff !important;
  }

  .grayscale {
    filter: grayscale(0.8);
  }

  .color-item {
    color: #116bff !important;
    font-style: italic !important;
  }

  .height {
    height: 45px;
    width: 45px;
  }

  .teste {
    background-color: #000;
  }

  .step {
    div {
      background-color: #d6d6d6;
      border: solid 2px #d6d6d6;

      span {
        font-size: 24px;
        color: #fff;
      }
    }

    p {
      font-family: 'Inter';
      color: #8c8c8c;
      font-size: 17px;
      font-weight: 400;
    }
  }

  hr {
    background-color: #b7b7b7;
  }

  .terms {
    h4,
    p {
      color: #8c8c8c;
      font-family: 'Inter';
      font-size: 10px;
      font-weight: 200;
      line-height: 14px;
      b {
        font-weight: 500;
      }
    }
  }

  .selected-step {
    div {
      border: 1px solid #37c694;
      background-color: #fff;
      span {
        font-size: 24px;
        color: #37c694;
        font-weight: 600;
      }
    }
    p {
      font-family: 'Inter';
      color: #021027;
      font-weight: 600;
      font-size: 17px;
    }
  }

  .active-step {
    div {
      background-color: #37c694;
      border: solid 2px #37c694;

      span {
        font-size: 24px;
        color: #fff;
        font-weight: 600;
      }
    }
    p {
      color: #37c694;
      font-size: 17px;
    }
  }

  .active-hr {
    background-color: #37c694;
  }

  .bg-input {
    background-color: #f7f7f7;
    border: 0;
    height: 58px;
    border-radius: 15px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .btn-black {
    background-color: #116bff;
    border-radius: 15px;
    font-size: 24px;
    padding: 13px 20px;
    transition-duration: 0.3s;
    font-family: 'Inter';
    font-weight: 600;
    span {
      font-size: 24px !important;
      font-weight: 600 !important;
    }

    :hover {
      background-color: ${lighten(0.1, '#116BFF')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 19px 49px 112px rgba(192, 192, 192, 0.4),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(138, 138, 138, 0.5);
    border-radius: 30px;
    border: 0 !important;
  }

  .close-button {
    background-color: transparent;
  }

  .btn-credit {
    color: #ababab !important;
    font-size: 14px;
  }

  .items {
    h2 {
      color: #081f32;
      font-size: 22px;
    }
    h3 {
      color: #081f32;
      font-family: 'Inter';
      font-size: 18px;
      font-weight: 600;
    }
    h4 {
      font-size: 14px;
      span {
        font-size: 14px;
      }
    }
    p {
      font-size: 12px;
      color: #6e798c;
    }

    .gradient-items {
      background: linear-gradient(
        270.03deg,
        #fb5a43 0.02%,
        #c341ae 92.68%,
        #c341ae 99.97%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    span {
      color: #303030;

      font-family: 'Inter';
      font-size: 18px;
      font-weight: 400;
    }

    .btn-cart {
      background-color: #ababab;
      border-radius: 10px;
      padding: 7.5px 20px;
      color: #fff;
      font-size: 14px;

      :hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
      }
    }

    .bd-box {
      background: #ffffff;
      box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
    }
  }

  .price {
    //color: #37c694 !important;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
  }

  .total {
    color: #116bff !important;
  }

  .months {
    color: #081f32 !important;
    text-align: right !important;
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-style: italic !important;
    font-weight: 400 !important;
  }

  @media screen and (min-width: 992px) {
    .check-position {
      position: absolute;
      right: 3%;
      top: 7%;
      width: 26px;
    }
  }

  @media screen and (min-width: 1200px) {
    .modal-xl {
      max-width: 1034px !important;
    }
  }
`;

export const Loading = styled.span`
  //margin-top: 0px !important;
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  border-radius: 20px;
  background-color: #116bff;
  width: 207px !important; */

  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #fff 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #fff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
