import styled from 'styled-components';

export const Container = styled.footer`
  font-family: 'Poppins', sans-serif;
  background: #116bff;
  border-radius: 40px;
  margin: 0 23px 46px 23px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
  }

  a,
  button {
    background: transparent;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #fafafa;
    margin-bottom: 6px;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }

  hr {
    background-color: #fafafa;
    margin: 50px 0;
    height: 2px;
  }

  span {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #fafafa;
  }

  @media screen and (max-width: 767px) {
    a img {
      width: 46px;
    }
  }

  @media screen and (max-width: 575px) {
    margin: 0 10px 20px 10px;

    a,
    button {
      line-height: 36px;
    }
  }

  @media screen and (min-width: 768px) {
    h2 {
      font-size: 16px;
    }
    a,
    button {
      font-size: 12px;
    }

    span {
      font-size: 12px;
    }
    .zoom-footer {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1400px) {
    h2 {
      font-size: 20px;
    }
    a,
    button {
      font-size: 16px;
    }

    span {
      font-size: 16px;
    }
    .zoom-footer {
      zoom: 1;
    }
  }
`;
