import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  background: #00000055;

  h2 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 45px;
    color: #116bff;
  }

  h3 {
    font-family: 'Archivo', sans-serif;
    font-size: 20px;
    line-height: 35px;
    color: #151517;
    font-weight: 300;
  }

  p {
    color: var(--Medium-Dark-Gray-Stroke, #242526);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    span {
      font-weight: 400;
    }
    img {
      width: 22px;
      height: 26px;
    }
  }

  input {
    height: 50px;
    color: #021027;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 134.023%;

    ::placeholder {
      color: #ababab;
    }
  }

  .bd-input {
    border-radius: 18px;
    border: 1px solid #116bff;
    background: #fff;
    height: 65px;
    padding: 5px 25px;
  }

  .body {
    padding: 0rem 40px 1rem 40px;
  }

  .input-button {
    background: transparent !important;
    border: none !important;
    height: 30px !important;
    top: 17px !important;
    left: 17px !important;
    transform: scale(1.5) !important;
  }

  .btn-submit {
    width: 100%;
    border-radius: 18px;
    background: #116bff;
    color: #fff;
    font-family: 'Archivo';
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    transition-duration: 0.2s;
    :hover {
      opacity: 0.8;
    }
  }

  .modal-content {
    border-radius: 25px;
    background: #fffdfd;
    border: none;
  }

  .mg-negative {
    margin-top: -43px;
    border: none;
  }

  .progress {
    height: 100%;
    padding: 0px;
    background: transparent;
    border-radius: 18px;
    border: 1px solid #dedede;
  }

  @keyframes progressAnimationStrike {
    from {
      width: 0;
    }
    to {
      width: 80%;
    }
  }

  @keyframes progressAnimationBar {
    from {
      right: -500px;
    }
    to {
      right: 0px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 34px;
      color: #ff1a50;
    }
    h3 {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 32px;
    }
    .body {
      padding: 0rem 25px 1rem 25px;
    }

    .modal-content {
      border: 4px solid #116bff !important;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 23px;
      line-height: 30px;
      margin: 26px 0px;
    }
    h3 {
      font-size: 22px;
    }
  }
`;

export const Loading = styled.div`
  //margin-top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  border-radius: 20px;
  background-color: #116bff;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #fff 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #fff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
