import React from 'react';
import { Container } from './styles';

const DisclaimerNew: React.FC = () => {
  return (
    <Container className="d-flex flex-column py-5 px-4 p-md-0">
      <p>
        <span>All prices are in USD</span>
      </p>

      <p>
        <span>IMPORTANT:</span> This offer DOES NOT include any Resell Rights,
        Private Label Rights, or Giveaway Rights. The templates are designed to
        help you sell your own products and services, and you cannot sell, share
        or giveaway our templates nor use our marketing materials under any
        circumstances. Doing so voids any refunds and we will pursue legal
        action to recover damages.
      </p>
      <p>
        <span>Non-Affiliation with Facebook/Meta Platforms, Inc.:</span> This
        site is not part of the Facebook website or Meta Platforms, Inc.
        Additionally, this site is not endorsed by Facebook or Instagram in any
        way. Facebook and Instagram are trademarks of Meta Platforms, Inc.
      </p>
      <p>
        <span>Earnings and Income Disclaimer:</span> While AutoAffiliate
        provides a platform for affiliate marketing, we make no guarantees of
        specific income or profit. Success depends on individual effort,
        dedication, and market conditions.
      </p>
      <p>
        <span>Free and Paid Services:</span> We may offer services what are
        free, not requiring a credit card but we also offer additional services
        or upgrades involving payment.
      </p>
      <p>
        <span>Affiliate Participation:</span> To earn from the income streams we
        help build, membership in our affiliate program may be necessary, which
        might include signing up for offers that require payment. Review and
        understand the terms of these offers. AutoAffiliate is not responsible
        for outcomes related to these offers.
      </p>
      <p>
        <span>Compliance and Responsibility:</span> Members are responsible for
        complying with all laws and regulations in their use of our platform and
        affiliate marketing activities.
      </p>
      <p>
        <span>Risks Acknowledgment:</span> By signing up, members acknowledge
        and accept all risks associated with using our platform and engaging in
        affiliate marketing.
      </p>
      <p>
        <span>No "Get Rich Quick" Promises:</span> Our platform does not endorse
        "get rich quick" schemes. Building sustainable income streams requires
        time, effort, and strategic planning.
      </p>
      <p>
        <span>Testimonials and Examples:</span> Testimonials and examples on our
        site are exceptional results, not indicative of average experiences, and
        are not guarantees of future success for anyone.
      </p>
      <p>
        <span>Updates and Changes:</span> AutoAffiliate reserves the right to
        modify services and platform features, aiming to keep members informed
        of significant changes.
      </p>
      <p>
        <span>Acceptance of Terms:</span> Use of the AutoAffiliate platform and
        participation in our program constitutes acceptance of these terms and
        conditions.s an affiliate marketing business that provides a simple to
        use platform for its members.{' '}
      </p>
    </Container>
  );
};

export default DisclaimerNew;
