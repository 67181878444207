import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FormCard from './FormCard';

const stripePromise = loadStripe(
  'pk_test_51NroHqCvSd6DjniUaxGbLrL4rN1rexQRs3zHA5ZPdTXrazZGfpGkKgH6uToHSjOMjxY7SHT8ZoDGHjhKoOS8eSiz00SlZhjogP'
);

const TestStripe: React.FC = () => {
  return (
    <>
      <h1 className="mt-5 pt-5">Teste</h1>
      <Elements stripe={stripePromise}>
        <FormCard />
      </Elements>
    </>
  );
};

export default TestStripe;
