import React from 'react';
import Lottie from 'react-lottie';
import bubble from '~/assets/animations/orb.json';

import logo from '~/assets/defaults/postease-header-logo.png';
import textLogo from '~/assets/defaults/postease-header-text.svg';
import { Container } from './styles';

interface LogoProps {
  className?: string;
  widthImg?: number;
  heightImg?: number;
  widthText?: number;
  heightText?: number;
  centerLogo?: string;
}

const Logo: React.FC<LogoProps> = ({
  className,
  widthImg,
  heightImg,
  widthText,
  heightText,
  centerLogo,
}) => {
  return (
    <Container className="d-flex align-items-center justify-content-between">
      <div className={`d-block ${className || ''}`}>
        <div className="d-flex align-items-center">
          <div className="d-flex position-relative pe-none">
            <Lottie
              options={{
                animationData: bubble,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width={70}
              height={70}
            />
            <img
              src={logo}
              width={widthImg ?? 43}
              height={heightImg ?? 43}
              alt="PostEase"
              className={`position-absolute ${
                widthImg && heightImg ? centerLogo : 'center'
              } `}
            />
          </div>
          <img
            src={textLogo}
            width={widthText ?? 163}
            height={heightText ?? 21}
            alt="PostEase"
          />
        </div>
      </div>
      <p className="mb-0">
        <span className="h6 mb-0 me-2">
          © {new Date().getFullYear()} SmartTech Labs. PostEASE. AutoAffiliate
          All right reserved
        </span>{' '}
        |{' '}
        <a href="/" className="mx-2">
          Privacy Policy
        </a>{' '}
        |{' '}
        <a href="/" className="mx-2">
          Terms of Service
        </a>{' '}
        |{' '}
        <a href="/" className="mx-2">
          Cookies
        </a>
      </p>
    </Container>
  );
};

export default Logo;
