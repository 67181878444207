import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from './styles';

const TermsService: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col pt-5 my-5">
            <h1>AutoAffiliate.ai Terms of Service</h1>
            <h2>Terms of Use</h2>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Welcome to AutoAffiliate.ai: Understanding Our Legal Terms
            </h3>
            <p>
              Hello and thank you for visiting AutoAffiliate.ai. Yes, this page
              contains many legal terms, but it's essential for us to share them
              with you. At AutoAffiliate, we believe in empowering you as an
              informed consumer and internet user. Navigating through our site,
              you'll not only discover valuable content but also learn about the
              importance of understanding the digital space you interact with.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Agreement and Acceptance
            </h3>
            <p>
              This document outlines the Terms of Use ("the Terms") that govern
              your access and interaction with AutoAffiliate.ai ("the Site").
              These Terms establish a legal agreement between you and SmartTech
              Labs, Inc. ("AutoAffiliate," "we," or "us"). By using our website,
              services, or products, you agree to adhere to each term presented
              here. If you find any of these terms unacceptable, please refrain
              from using our site and its offerings.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">Scope of Services</h3>
            <p>
              AutoAffiliate is proud to offer a wide array of digital content,
              services, and products through our website. Our offerings are
              designed for users who are 18 years of age or older. These
              services encompass a variety of live and recorded courses covering
              numerous topics, as well as other valuable resources. Every piece
              of information, document, product, and service, including courses,
              course materials, and digital content, along with trademarks,
              logos, graphics, and images (collectively referred to as
              "Materials"), are the property of AutoAffiliate or our licensors
              and contributors. Your access to and use of these Materials are
              subject to complete adherence to these Terms.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Digital Nature of Our Services
            </h3>
            <p>
              Our softwares and educational instructions are exclusively digital
              and become accessible immediately upon your registration and
              payment completion on AutoAffiliate.ai. Please note that we do not
              provide physical shipments of products.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Commitment to Resolution
            </h3>
            <p>
              <b>
                By accepting these Terms, you agree to resolve any disputes with
                AutoAffiliate through binding individual arbitration, as
                detailed in these Terms. This commitment to arbitration aims to
                provide a streamlined and effective process for addressing any
                concerns or disagreements that may arise during your use of our
                services.
              </b>
            </p>
            <h2>Privacy Policy</h2>
            <p>
              The information we collect on the site is governed by
              AutoAffiliate.ai's Privacy Policy, which is available on this site
              on our Privacy Policy page.{' '}
              <Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>
                Privacy Policy
              </Link>{' '}
              is part of these terms and is incorporated into these Terms by
              reference.
            </p>
            <h2>Communication Consent</h2>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Your Communication Preferences
            </h3>
            <p>
              When you agree to these Terms, you authorize AutoAffiliate to
              contact you via text messages, automated calls, emails, and other
              digital communication methods. These communications may include
              marketing, updates, and customer support messages.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Standard Rates and Opt-Out Rights
            </h3>
            <p>
              Standard data and messaging rates may apply to text messages. Your
              consent to receive communications is not a condition for any
              purchase. You have the right to withdraw your consent and opt-out
              of future communications at any time. To unsubscribe, follow the
              instructions in our communications or contact{' '}
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai.
              </a>
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Data Privacy Commitment
            </h3>
            <p>
              AutoAffiliate is dedicated to protecting your personal data. Your
              contact information will be handled in accordance with our Privacy
              Policy and will not be shared with third parties without your
              explicit consent.
            </p>
            <h2>Detailed Cookie and Tracking Technologies Policy</h2>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              What are Cookies and Tracking Technologies?
            </h3>
            <p>
              Cookies are small data files stored on your device. Tracking
              technologies like web beacons or pixels help us understand how you
              interact with our site.
            </p>
            <p className="mb-0 fw-bold">Types of Cookies Used</p>
            <ul>
              <li>
                Essential Cookies: Necessary for the site's functionality.
              </li>
              <li>
                Performance Cookies: Collect information about site usage.
              </li>
              <li>Functional Cookies: Remember your preferences.</li>
              <li>
                Advertising Cookies: Tailor advertising to your interests.
              </li>
            </ul>
            <p className="mb-0 fw-bold">Consent and Control</p>
            <ul>
              <li>
                EU Users: We obtain your consent before using non-essential
                cookies. You can change your preferences at any time.
              </li>
              <li>
                Managing Cookies: You can manage cookies through your browser
                settings.
              </li>
            </ul>
            <p className="mb-0 fw-bold">Third-Party Cookies</p>
            <p>
              We may use third-party services that set cookies for analytics or
              advertising purposes. For more details, please visit our{' '}
              <Link to={`${process.env.PUBLIC_URL}/cookie-policy`}>
                Full Cookie Policy.
              </Link>
            </p>
            <h2>Data Privacy and Security</h2>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Data Collection and Use
            </h3>
            <p>
              We collect personal data such as name, email address, and payment
              information. This data is used to provide and improve our
              services, process transactions, and communicate with you.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">Data Protection</h3>
            <p>
              We implement robust security measures to protect your data from
              unauthorized access, alteration, disclosure, or destruction.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">Data Sharing</h3>
            <p>
              We do not sell personal data. Data may be shared with third-party
              providers only to the extent necessary for providing services.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">User Rights</h3>
            <ul>
              <li>
                <b>Access and Control:</b> You can review, edit, or delete your
                personal data.
              </li>
              <li>
                <b>EU Users:</b> You have additional rights under the GDPR, such
                as data portability and objecting to data processing.
              </li>
            </ul>
            <h3 className="h4 fw-semibold pt-3 mb-4">Data Retention</h3>
            <p className="mb-0">
              We retain personal data only as long as necessary for the purposes
              outlined in this policy.
            </p>
            <p>
              For more information, please refer to our{' '}
              <Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>
                Privacy Policy.
              </Link>
            </p>
            <h2>Jurisdiction-specific Provisions</h2>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              GDPR Compliance (EU Users)
            </h3>
            <ul>
              <li>
                We adhere to the GDPR for EU residents. This includes rights to
                access, correct, delete, or transfer your data, and restrict its
                processing.
              </li>
              <li>
                <b>Data Protection Officer:</b> Contact details of our DPO are
                available for EU residents
              </li>
            </ul>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              CCPA Compliance (California Users)
            </h3>
            <ul>
              <li>
                Under the CCPA, California residents have the right to know
                about the personal data collected, used, and disclosed, and can
                request deletion of their personal data.
              </li>
              <li>
                <b>Opt-Out Right:</b> California residents can opt out of the
                sale of their personal data.
              </li>
            </ul>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              LGPD Compliance (Brazilian Users)
            </h3>
            <ul>
              <li>
                For users in Brazil, AutoAffiliate adheres to the Brazilian
                General Data Protection Law (LGPD). We are committed to
                protecting the personal data of our Brazilian users as per LGPD
                guidelines.
              </li>
            </ul>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Australian Privacy Act Compliance
            </h3>
            <ul>
              <li>
                Users in Australia are protected under the Australian Privacy
                Act. AutoAffiliate ensures compliance with this Act in the
                handling, use, and storage of personal data from Australian
                users.
              </li>
            </ul>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Changes to Jurisdiction-specific Provisions
            </h3>
            <p className="mb-0">
              These provisions may be updated to reflect changes in laws or
              regulations.
            </p>
            <p>
              For specific queries related to your jurisdiction, please contact
              us at{' '}
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai
              </a>
            </p>
            <h2>Updates to these Terms and AutoAffiliate's Services.</h2>
            <p>
              AutoAffiliate may update these terms to reflect changes to the
              site, or our services or products, and AutoAffiliate reserves the
              right, in its sole discretion, to modify and/or make changes to
              these terms at any time. If AutoAffiliate makes any material
              changes to these terms, we will notify you through prominent
              means, such as by email notice sent to the email address linked to
              your account or by posting a notice on the website. Any changes
              will become effective on the day they are posted unless otherwise
              stated.
            </p>
            <p>
              If you continue to use the site after such changes have been
              posted, it means you agree to the new terms, even if you have not
              reviewed the changes. Therefore, please periodically review these
              terms for such updates.
            </p>
            <p>
              AutoAffiliate may modify the website, Materials, services, and
              products, or discontinue part or all of their availability at any
              time.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Notification of Significant Updates
            </h3>
            <p>
              In case of significant changes to these Terms, AutoAffiliate will
              inform users via email notifications and dashboard announcements
              on our platform. We encourage regular review of these terms to
              stay informed.
            </p>

            <h2>Account Registration</h2>
            <p>
              To access and use certain Materials or services on the website,
              you will need to register for an account. When registering, you
              must provide certain information, such as your name, email
              address, and our system will automatically generate a password and
              email it to you which you can change it at your convenience. You
              are solely responsible for maintaining the confidentiality of your
              account and password (collectively, your "account") and for all
              activities associated with your account or occurring with it. You
              represent and warrant that the information in your account will be
              accurate at all times. You must immediately notify AutoAffiliate
              if you become aware of any unauthorized use of your account or any
              other security breach. You are responsible for any loss or damage
              arising from your failure to comply with the above requirements
              and for all actions that occur in connection with your account,
              whether with or without your knowledge, prior to notifying
              AutoAffiliate of any unauthorized access or use of your account.
              You may not transfer your account to any other person and may not
              use another person's account at any time.
            </p>

            <h2>License</h2>
            <p>
              AutoAffiliate grants you a limited, personal, non-exclusive,
              non-transferable license to access and use the website and the
              Materials, including the courses and content for which you have
              paid the fee, solely for your personal, non-commercial, or
              educational use. You agree that you will only use the site and the
              Materials in accordance with these terms.
            </p>
            <p>
              You agree that you have no right to modify, edit, copy, reproduce,
              distribute, transmit, sell, rent, lease, share, create derivative
              works, alter, enhance, or exploit in any manner any of the
              Materials provided by AutoAffiliate. Your limited license to
              access the website and use the Materials may be immediately
              terminated without prior notice if you violate any of these terms.
            </p>
            <p>
              Upon termination of your license, you agree to immediately destroy
              any downloaded or printed Materials. Except as expressly stated
              herein, you acknowledge that you have no rights, title, or
              interest in the website or any Materials.
            </p>
            <h2>Charges and Fee Payment</h2>
            <p>
              You are solely responsible for all charges for services, data,
              and/or other fees and costs associated with your access and use of
              the website and the Materials, as well as for obtaining and
              maintaining all equipment required for such access and use.
            </p>
            <p>
              If you choose to access or use courses or Materials or sign up for
              a subscription service, which involves payment of a fee, you agree
              to pay and will be responsible for the payment of the fees and any
              taxes associated with such access or use. If you provide credit
              card information to pay such fees, you represent and warrant that
              you are authorized to provide such payment information and
              authorize AutoAffiliate to charge your credit card on a one-time
              or recurring basis, as applicable, to pay the fees when they
              become due.
            </p>
            <p>
              For paid subscriptions, AutoAffiliate will automatically renew
              your subscription and charge your credit card. The renewal charge
              will be equal to the current subscription fee for the previous
              period, unless AutoAffiliate has given you at least 30 days'
              written notice of a fee increase, which will take effect upon
              renewal. Fees for other courses or services will be charged based
              on the list price or as quoted.
            </p>
            <p>
              If your payment method fails or your account is expired,
              AutoAffiliate may collect the committed fees using other
              collection mechanisms, such as charging other payment methods
              registered with AutoAffiliate. We may also block your access to
              the website or any materials pending the resolution of any amounts
              owed by you to AutoAffiliate.
            </p>
            <h2>Refund and Cancellation Policy</h2>
            <p>
              AutoAffiliate offers a refund policy for certain services and
              products. If you are not satisfied with your purchase, you may be
              eligible for a refund within a specified period following your
              purchase. The specific terms of the refund, including any
              conditions or limitations, will be outlined at the time of
              purchase. For subscription services, you may cancel your
              subscription at any time; however, please note that refunds may
              not be available for the unused portion of the subscription.
            </p>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Detailed Refund and Cancellation Process
            </h3>
            <p>
              <b>
                Refund requests for paid services must be submitted within 30
                days of purchase.
              </b>{' '}
              To request a refund, please contact our support team at{' '}
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai
              </a>{' '}
              Refunds are processed within 14 business days and may be subject
              to conditions such as the state of the consumed content or
              services.
            </p>
            <h2>Use of the Website and Materials</h2>
            <h3 className="h4 fw-semibold pt-3 mb-4">
              Prohibited Actions and Consequences
            </h3>
            <p>
              Engaging in activities such as spreading malware, attempting
              unauthorized access, or conducting fraudulent activities is
              strictly prohibited. Violations of these provisions may result in
              immediate termination of your account, legal action, and potential
              financial liability.
            </p>
            <p>
              Your continued use of the Website, Materials, and services is
              expressly conditioned on your compliance with these terms. Without
              limiting the foregoing, you acknowledge that AutoAffiliate
              prohibits and agrees not to:
            </p>
            <ol>
              <li>
                Take any action that imposes an unreasonable or
                disproportionately large burden on the infrastructure of the
                site;
              </li>
              <li>
                Intentionally expose the site and/or any product or service
                provided on the website to a computer virus or any other program
                or code that is intended to disrupt or disable the operations of
                the website and/or access to the materials;
              </li>
              <li>
                Frame or link to the site or any of the Materials available on
                the site unless expressly consented to by AutoAffiliate;
              </li>
              <li>
                Use any spider, robot, or other program, code, or device to
                copy, retrieve, or index any portion of the AutoAffiliate
                website;
              </li>
              <li>
                Collect or gather information about other users for any purpose
                other than as expressly permitted herein;
              </li>
              <li>
                Post any false or inaccurate Submission (defined below) or
                information on any part of the site;
              </li>
              <li>
                Use the Materials and/or the website to violate any applicable
                law or regulation, including but not limited to infringement of
                any copyright, trademark, trade secret, or other intellectual
                property rights, or violate the privacy or rights of any other
                person;
              </li>
              <li>
                Post any Submission or information that is discriminatory,
                hateful, obscene, vulgar, abusive, defamatory, sexually
                oriented, threatening, or otherwise objectionable;
              </li>
              <li>
                Include any hyperlink or any misleading, irrelevant, and/or
                hidden code or keywords in any Submission posted by you;
              </li>
              <li>
                Harass, stalk, or subject any other user of the site or the
                Materials to inappropriate or unwanted contact;
              </li>
              <li>
                Make additions, changes, alterations, and/or deletions to any
                Submission posted by any user without the express written
                authorization of such other user;
              </li>
              <li>
                AutoAffiliate reserves the right to terminate our relationship
                with you under these terms and prohibit your access to the
                website, the materials, or to edit, delete, or close any
                Submission or thread for any reason.
              </li>
            </ol>
            <p>
              The above list of prohibitions is illustrative and is not intended
              to be exhaustive or exclusive. AutoAffiliate may add to this list
              any other prohibition at any time, at its sole discretion.
            </p>
            <p>
              You acknowledge and agree that there is a possibility that you may
              be exposed to content on the website that may be offensive or
              objectionable to you. Your access to the website and use of the
              materials is at your own risk, and AutoAffiliate is not
              responsible for any offensive or objectionable material that may
              be posted on our website.
            </p>
            <p>
              Furthermore, the above prohibitions do not impose on AutoAffiliate
              any obligation to monitor or remove any Submission or other
              information or communication submitted by you or any other user.
            </p>
            <p>
              You are prohibited from taking any action to circumvent or attempt
              to circumvent the security and access controls implemented by
              AutoAffiliate to protect the website, the materials, and any
              information you provide through the website. Such prohibited
              conduct includes, but is not limited to, any attempt to: (a) log
              in to an account with a password not assigned to you; (b) access
              personally identifiable information that is not intended for you;
              (c) test security measures on the website and/or attempt to
              identify system vulnerabilities; (d) impersonate any other user of
              the website and/or the products and services, forge header
              information in any post, or alter the TCP/IP packet header; (e)
              modify, reverse engineer, disassemble, decompile, or attempt or
              allow others to discover the underlying software code for the
              website and/or the products and services; or (f) overwhelm or
              disable the website or the products and services or interfere with
              the access and use of the website and/or the services by any other
              user.
            </p>
            <p>
              If we become aware of any of the above activities or any other
              prohibited or illegal behavior on the website, we may investigate
              and take action. When appropriate, we may work with law
              enforcement to investigate and prosecute offenders.
            </p>
            <h2>User Submissions</h2>
            <p>
              If you provide data and/or post communications on the website
              ("Submissions"), you grant AutoAffiliate an irrevocable,
              perpetual, royalty-free (including moral rights) worldwide right
              and license to use, reproduce, display, distribute, modify, and
              create derivative works from them, in whole or in part, and to
              incorporate such information into other works in any media and
              using any technology now known or later developed. You represent
              that you have all rights and that no third party has retained such
              rights (including moral rights) to the Submissions you may post on
              the website.
            </p>
            <h2>Links to Third-Party Websites</h2>
            <p>
              AutoAffiliate will provide you with these links for your
              convenience only, and AutoAffiliate is not responsible for the
              content or links displayed on these third-party websites.
              AutoAffiliate may provide links to other websites on our website
              that are not owned, operated, or managed by us.
            </p>
            <h2>Indemnification</h2>
            <p>
              You shall indemnify and hold AutoAffiliate and its officers,
              directors, employees, affiliates, agents, licensors, and business
              partners ("AutoAffiliate Indemnified Parties") harmless from and
              against any and all damages, costs, expenses, and liabilities,
              including reasonable attorneys' fees, that the AutoAffiliate
              Indemnified Parties suffer in connection with, arise out of, or
              for the purpose of avoiding any claim or demand from a third party
              in the event that the use of the website, the materials, or the
              use of the site by any person using your username and/or password,
              violates any applicable law or regulation or the rights of any
              third party.
            </p>

            <h2>Copyright Notice</h2>
            <p>
              All materials, information, and data provided on the website and
              in the materials, including web pages, documents, website design,
              text, graphics, logos, images, and icons, and the selection and
              arrangement thereof, are the exclusive property of AutoAffiliate,
              unless otherwise specified, and are protected by copyright and
              applicable laws.
            </p>
            <p>
              All rights not expressly granted in these terms are reserved. Any
              reproduction, distribution, modification, retransmission, or
              publication of any copyrighted material on the website is strictly
              prohibited without the express written consent of AutoAffiliate,
              except as required by applicable law.
            </p>

            <h2>Disclaimer of Warranties</h2>
            <p>
              THE WEBSITE, MATERIALS, PRODUCTS, SERVICES, AND ANY OTHER
              INFORMATION OR MATERIAL AVAILABLE ON THE WEBSITE OR THROUGH IT ARE
              PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED. TO THE MAXIMUM EXTENT PERMITTED BY LAW,
              AUTOAFFILIATE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
              INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE,
              NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY, AND FITNESS FOR A
              PARTICULAR PURPOSE. AUTOAFFILIATE DOES NOT WARRANT THAT THE USE OF
              THE WEBSITE OR THE MATERIALS WILL BE UNINTERRUPTED, ERROR-FREE, OR
              SECURE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE WEBSITE,
              MATERIALS, OR THE SERVER(S) ON WHICH THE WEBSITE IS HOSTED ARE
              FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NO OPINION, ADVICE,
              OR STATEMENT OF AUTOAFFILIATE OR ITS AFFILIATES, LICENSORS,
              AGENTS, MEMBERS, OR VISITORS, WHETHER MADE THROUGH THE USE OF THE
              SITE OR OTHERWISE, SHALL CREATE ANY WARRANTY. THE USE OF THE
              WEBSITE, MATERIALS, PRODUCTS, SERVICES, AND THIRD-PARTY CONTENT IS
              SOLELY AT YOUR OWN RISK.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
              IN NO EVENT SHALL AUTOAFFILIATE BE LIABLE TO YOU OR ANY THIRD
              PARTY FOR ANY INDIRECT, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL,
              OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF DATA, REVENUE,
              PROFITS, USE, OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF OR IN
              CONNECTION WITH THE USE OF OUR WEBSITE, MATERIALS, PRODUCTS, AND
              SERVICES OR THE RELIANCE ON THE INFORMATION AND/OR MATERIALS
              PROVIDED ON THE WEBSITE, EVEN IF AUTOAFFILIATE HAS BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES. THE TOTAL LIABILITY OF
              AUTOAFFILIATE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES ARISING
              OUT OF THE USE OF OUR WEBSITE, MATERIALS, PRODUCTS, AND SERVICES
              OR THE RELIANCE ON THE INFORMATION AND/OR MATERIALS PROVIDED ON
              THE WEBSITE SHALL NOT EXCEED THE TOTAL AMOUNT PAID BY YOU TO
              AUTOAFFILIATE DURING THE TWELVE (12) MONTHS PRIOR, IF ANY, IN
              WHICH CASE AUTOAFFILIATE'S MAXIMUM LIABILITY SHALL BE FIFTY
              DOLLARS ($50.00). BECAUSE SOME STATES OR JURISDICTIONS DO NOT
              ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
              OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
            </p>
            <h2>Compliance with Laws</h2>
            <p>
              Your use, access, and other activities related to the products and
              services must comply with all applicable laws and regulations,
              including but not limited to laws relating to copyright and other
              intellectual property rights, privacy, online conduct, and
              acceptable content. Furthermore, access to the website and
              services from territories where their contents are illegal is
              prohibited. If you choose to access or use the website or services
              from locations outside the United States, you do so on your own
              initiative and are responsible for compliance with all applicable
              local laws. You also agree to comply with all applicable laws
              regarding the transmission of technical data exported from the
              United States or the country in which you reside, including import
              and export regulations.
            </p>

            <h2>Termination</h2>
            <p>
              If you violate these terms, AutoAffiliate may terminate your
              access to the website or certain parts of the website without
              prior notice. While AutoAffiliate prefers to advise you of
              inappropriate behavior and recommend any necessary corrective
              action, certain violations of these terms, as determined by
              AutoAffiliate in its sole discretion, may result in immediate
              termination of your access to our website or certain parts
              thereof. AutoAffiliate reserves the right to terminate any account
              for any reason.
            </p>

            <h2>Dispute Resolution</h2>
            <p>
              In the event that you are not satisfied with the website, the
              materials, or a purchase you have made through our website, please
              contact us at{' '}
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai
              </a>{' '}
              so that we can address your issue. If we are unable to resolve
              your complaint, the following process applies.
            </p>
            <p>
              Any dispute or claim relating in any way to your use of the
              website or the materials shall be resolved through binding
              arbitration, rather than in court, except that you may assert
              claims in small claims court if your claims qualify. The Federal
              Arbitration Act and federal arbitration law apply to this
              agreement.
            </p>
            <p>
              There is no judge or jury in arbitration, and court review of an
              arbitration award is limited. However, an arbitrator can award the
              same damages and relief as a court (including injunctive and
              declaratory relief or statutory damages), and must follow the
              terms of these terms of use as a court would.
            </p>
            <p>
              To initiate an arbitration proceeding, you must send a letter
              requesting arbitration and describing your claim to AutoAffiliate,
              Inc. Attn: Legal, 30 N Gould St Ste 21152, Sheridan, WY, 92801, or
              by email from the email address associated with your account to:
              The arbitration will be conducted by the American Arbitration
              Association (AAA) in accordance with its rules, including the
              AAA's Supplementary Procedures for Consumer-Related Disputes. The
              AAA's rules are available at{' '}
              <a href="https://www.adr.org/" target="_blank" rel="noreferrer">
                www.adr.org
              </a>{' '}
              or by calling 1-800-778-7879. Payment of all filing,
              administration, and arbitration fees will be governed by the AAA's
              rules. We will reimburse those fees for claims totaling less than
              $10,000 unless the arbitrator determines the claims are frivolous.
              Likewise, we will not seek attorneys' fees and costs in
              arbitration unless the arbitrator determines the claims are
              frivolous. You may choose to have the arbitration conducted by
              telephone, based on written submissions, or in person in the
              county where you live or at another mutually agreed location.
            </p>
            <p>
              Each of us agrees that any dispute resolution proceedings will be
              conducted only on an individual basis and not in a class,
              consolidated, or representative action. If for any reason a claim
              proceeds in court rather than in arbitration, each of us waives
              any right to a jury trial. We also agree that you or we may bring
              suit in court to enjoin infringement or other misuse of
              intellectual property rights.
            </p>

            <h2>Free Services and Paid Services:</h2>
            <p>
              AutoAffiliate provides both free services and paid services. The
              terms and conditions outlined in this section apply to both types
              of services.
            </p>

            <p>
              <b>a. Free Services:</b> AutoAffiliate may offer certain services
              free of charge. These free services are provided on an "as is"
              basis, without any warranties or guarantees. While we strive to
              provide accurate and reliable information, we do not warrant the
              completeness, accuracy, or usefulness of these free services. The
              use of these free services is entirely at your own risk.
            </p>
            <p>
              <b>b. Paid Services:</b> In addition to our free services,
              AutoAffiliate also offers paid services. These paid services may
              include access to premium content, advanced features, or exclusive
              benefits. The specific details, pricing, and terms of our paid
              services are provided separately and may be subject to additional
              agreements or terms.
            </p>
            <p>
              By accessing or using our free services, you acknowledge that
              these services may be limited in scope and functionality compared
              to our paid services. You understand that AutoAffiliate reserves
              the right to modify, suspend, or discontinue any free services at
              any time, without prior notice or liability.
            </p>
            <p>
              If you choose to subscribe or purchase our paid services, you
              agree to abide by the terms and conditions set forth in the
              applicable agreement or terms specific to those services. Payment
              for paid services will be subject to the agreed-upon pricing and
              payment terms. Please note that any cancellation, refund, or
              dispute related to our paid services will be governed by the
              specific terms and conditions provided in the corresponding
              agreement or terms.
            </p>
            <p>
              AutoAffiliate may offer promotions, discounts, or trial periods
              for our paid services. Such offers are subject to their own terms
              and conditions, and AutoAffiliate reserves the right to modify or
              revoke these offers at any time.
            </p>
            <p>
              It is important to carefully review the details and terms of our
              free and paid services before engaging with them. If you have any
              questions or concerns, please contact our customer support for
              assistance.
            </p>
            <p>
              This Free Services and Paid Services clause is incorporated into
              the overall Terms of Use and is effective as of June 1st, 2023.{' '}
            </p>
            <p>
              AutoAffiliate reserves the right to update or modify this clause
              and the associated terms at its discretion. Please check this
              section periodically for any changes.
            </p>
            <h2>General Terms</h2>
            <p>
              <b>Applicable Laws.</b> These Terms and the use of the Services
              shall be governed by the substantive laws of the State of Wyoming,
              without reference to its choice or conflicts of law principles
              that would require the application of the laws of another
              jurisdiction, and shall be deemed to have been made and accepted
              in the state of Wyoming.
            </p>
            <p>
              <b>Entire Agreement.</b> These terms and any applicable policies
              to you, posted on the site, constitute the entire agreement
              between you and AutoAffiliate regarding the subject matter hereof,
              and supersede all prior written or oral agreements between you and
              AutoAffiliate regarding such subject matter.
            </p>
            <p>
              <b>Severability.</b> If any provision of these terms is found to
              be unlawful, void, or unenforceable, such provision shall be
              deemed severable from these terms and shall not affect the
              validity and enforceability of the remaining provisions of these
              terms. Waiver. The failure of AutoAffiliate to exercise or enforce
              any right or provision of these terms shall not constitute a
              waiver of such right or provision.
            </p>
            <p>
              <b>Notice.</b> Any notice or other communication provided
              hereunder shall be in writing and sent by facsimile, registered or
              certified mail with return receipt requested, or email.
            </p>
            <p>
              <b>Contact Information.</b> If you have any questions or comments
              about these terms, please contact us at:
            </p>
            <p>SmartTech Labs Inc. DBA AutoAffiliate</p>
            <p>
              30 N Gould St Ste 21152,
              <br />
              Sheridan, WY, 92801
              <br />
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai
              </a>
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TermsService;
