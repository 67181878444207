/* eslint-disable no-console */
import React, {
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
  useRef,
} from 'react';
import Lottie from 'react-lottie';
import { Form } from '@unform/web';

import { BsDash, BsPlus } from 'react-icons/bs';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import Fade from 'react-reveal/Fade';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';

import {
  Container,
  DontWait,
  Feeling,
  Free,
  Honest,
  Join,
  Members,
  PowerAi,
  Questions,
  Modal,
  Loading,
} from './styles';

import Input from '~/components/Input';
import Player from '~/components/Player';

import swalError from '~/utils/swalError';
import discover1 from '~/assets/defaults/discover1.png';
import discover2 from '~/assets/defaults/discover2.png';
import discover3 from '~/assets/defaults/discover3.png';
import discover4 from '~/assets/defaults/discover4.png';
import discover5 from '~/assets/defaults/discover5.png';
import discover6 from '~/assets/defaults/discover6.png';
import thumbFree from '~/assets/defaults/thumb-free.webp';
import ai from '~/assets/defaults/ai.png';
import automated from '~/assets/defaults/automated.png';
import advanced from '~/assets/defaults/advanced.png';
import family from '~/assets/defaults/feeling.png';
import street from '~/assets/defaults/person-street.png';
import stamp from '~/assets/defaults/stamp.svg';

import arrow from '~/assets/icons/arrow.svg';
import muteImg from '~/assets/icons/mute.svg';
import waves from '~/assets/animations/waves-sound.json';

import Carousel from '~/components/Carousel';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import Disclaimer from '~/components/Disclaimer';
import { useResize } from '~/hooks/Resize';
import ModalSaveMoney from '~/components/ModalSaveMoney';

interface IParams {
  reference?: string;
  origin?: string;
}

interface IFormData {
  name: string;
  email: string;
}

interface IVideo {
  setShowAllPage: React.Dispatch<React.SetStateAction<boolean>>;
  showChoice?: boolean;
}

interface ILocation {
  lp: string;
}

const VideoHome: React.FC<IVideo> = ({ setShowAllPage, showChoice }) => {
  const params = useParams<IParams>();
  const location = useLocation<ILocation>();
  const [reference, setReference] = useState('');
  const { width } = useResize();
  const formRef = useRef<FormHandles>(null);
  const [mute, setMute] = useState(true);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    const btnVideo = document.getElementById(
      'buttonVideo'
    ) as HTMLButtonElement;
    const btnMute = document.getElementById('btnMute') as HTMLButtonElement;
    if (params.reference) {
      localStorage.setItem('@AutoAffiliate:reference', params.reference);
      setReference(params.reference);
    } else {
      const referenceData = localStorage.getItem('@AutoAffiliate:reference');
      if (referenceData) {
        setReference(referenceData);
      }
    }
    if (btnVideo && showChoice && play) {
      btnVideo.click();
      setPlay(false);
    }

    if (mute && btnMute && location.state?.lp === 'pageLp') {
      setPlay(true);
      btnMute.click();
    }
  }, [location.state, mute, params.reference, play, showChoice]);

  const handleMute = useCallback(() => {
    const vid = document.getElementById('video') as HTMLVideoElement;
    if (vid) {
      vid.currentTime = 0;
      setMute(false);
      vid.play();
      console.log('handleMute');
    }
  }, []);

  return (
    <div className="position-relative">
      <button
        id="btnMute"
        type="button"
        onClick={handleMute}
        className={`btn-mute w-100 h-100 border-none position-absolute ${
          !mute && 'd-none'
        }`}
      >
        <span className="mute pt-3 pt-md-5">
          <Lottie
            options={{
              animationData: waves,
              autoplay: true,
              loop: true,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={width > 575 ? 100 : 50}
          />
          <span className="d-block mt-3 mt-md-5 mb-3">
            Your Video Is Playing Click Here To Unmute
          </span>{' '}
          <img src={muteImg} alt="mute" />
        </span>
      </button>
      <Player
        buttonId="buttonVideo"
        id="video"
        src="https://cdn.autoaffiliate.ai/videos/AA-2.0-Landing-Page-v1.mp4"
        thumbnail={thumbFree}
        autoPlay
        muted={mute}
        onPlay={() => setPlay(true)}
      />
    </div>
  );
};

const HomeEmail: React.FC = () => {
  const [answer, setAnswer] = useState('');

  const [showAllPage, setShowAllPage] = useState(false);

  const [showChoice, setShowChoice] = useState(false);

  const handleClickQuestion = useCallback(
    (data) => {
      if (answer === data) {
        setAnswer('');
      } else {
        setAnswer(data);
      }
    },
    [answer]
  );

  return (
    <>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image:secure_url"
          itemProp="image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="autoaffiliate.ai" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <Container>
        <Join>
          <div id="home" className="container position-relative">
            <div className="row justify-content-center zoom-lg">
              <div className="col-12 text-center position-relative">
                <Fade>
                  <h1 className="position-relative">
                    {' '}
                    <b className="fw-semibold">Discover How</b> to{' '}
                    <span className="master">Make Money Online Faster</span>{' '}
                    <span className="archivo">
                      Than <b className="fw-semibold">EVER before!</b>
                    </span>
                  </h1>
                  <p className="archivo pb-2 mb-2 px-xxl-5">
                    <span className="master">Using</span>{' '}
                    <b>proven strategies,</b> cutting-edge <b>AI tools, </b>
                    and a <b>LEAD Generation Bot</b> that works for you 24/7!
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </Join>

        <Free>
          <div id="works" className="container">
            <div className="row justify-content-center">
              <div className="col-md-11 mb-5 text-center">
                <Fade>
                  <VideoHome
                    showChoice={showChoice}
                    setShowAllPage={setShowAllPage}
                  />
                </Fade>

                <button
                  type="button"
                  onClick={() => setShowChoice(true)}
                  className="mt-5 btn-yes"
                >
                  YES... Show Me How To Start Earning Online NOW!
                </button>
              </div>
            </div>
          </div>
        </Free>
        <div className="banner-group" />

        <PowerAi>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center mt-5 pt-5">
                <Fade>
                  <h2 className="mb-5 mb-md-0">
                    <span>Mastering</span> Growth with Simplicity
                  </h2>
                  <h3 className="mb-5 pb-4">
                    We harness the power of AI, automation, and advanced
                    marketing strategies to provide a system designed not just
                    to support, but to accelerate your business growth. We equip
                    you with cutting-edge tools; you bring your commitment to
                    success.
                  </h3>
                </Fade>
              </div>
            </div>
          </div>
          <div className="container-fluid container-sm">
            <div className="row justify-content-center">
              <div className="col-md-4 ps-4 pe-0 px-md-2 mr-negative mb-5">
                <div className="position-relative">
                  <Fade>
                    <div className="box box-left zoom" />
                    <img
                      src={ai}
                      alt="Artificial Intelligence"
                      className="w-100 zoom"
                    />
                  </Fade>
                  <Fade>
                    <h4 className="">Artificial Intelligence</h4>
                    <p>
                      Our platform leverages AI technology to streamline data
                      analysis, uncover valuable insights, and precisely target
                      your ideal audience—making complex tasks simple.
                    </p>
                  </Fade>
                </div>
              </div>
              <div className="col-md-4 mb-5 px-md-2">
                <div className="position-relative text-center text-md-start">
                  <Fade>
                    <div className="box box-center zoom" />
                    <img
                      src={automated}
                      alt="Automated Systems"
                      className="w-100 zoom"
                    />
                  </Fade>
                  <Fade>
                    <h4 className="">Automated Systems</h4>
                    <p>
                      Experience our automated systems that handle lifelike
                      interactions with potential prospects, requiring only
                      minimal oversight. This means you can focus more on
                      strategy and less on manual tasks.
                    </p>
                  </Fade>
                </div>
              </div>
              <div className="col-md-4 pe-4 ps-0 mb-5 px-md-2 ml-negative">
                <div className="position-relative text-end text-md-start">
                  <Fade>
                    <div className="box box-right zoom" />
                    <img
                      src={advanced}
                      alt="Advanced Marketing Strategies"
                      className="w-100 zoom"
                    />
                  </Fade>
                  <Fade>
                    <h4 className="">Advanced Marketing Strategies</h4>
                    <p>
                      Our masterclass will teach you step-by-step marketing
                      strategies to support your business growth. The more
                      effort you put in, the greater the chance of success.
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </PowerAi>

        <Feeling>
          <div className="container mb-lg-5 pt-5 pb-5">
            <div className="row justify-content-between align-items-center ">
              <div className="col-md-6">
                <Fade>
                  <h2>
                    <span>Are you tired of feeling stuck </span>
                    <br />
                    in the same financial situation month&nbsp;after month?
                  </h2>
                  <h3>
                    Are you ready to break free from living paycheck to paycheck
                    and start&nbsp;building a better future for yourself?
                  </h3>
                  <div className="d-sm-flex mt-5 mt-md-2 mt-lg-5">
                    <div className="w-sm-50 pe-5">
                      <h4>Our system is designed</h4>
                      <p className="mb-5 mb-sm-4">
                        to help people like you develop additional income
                        streams. It simplifies the process, but active
                        participation is&nbsp;crucial.
                      </p>
                    </div>
                    <div className="w-sm-50 pe-5">
                      <h4>Say goodbye to relying</h4>
                      <p className="mb-5 mb-sm-4">
                        on traditional methods like SEO or complex websites. Our
                        masterclass will show you a complete system desigend to
                        enhance success in the affiliate marketing business!
                      </p>
                    </div>
                  </div>
                  <h6>
                    Your effort, our tools & training—
                    <span>together, we strive for your success.</span>
                  </h6>
                </Fade>
                <Fade>
                  <button
                    type="button"
                    onClick={() => setShowChoice(true)}
                    className="d-none d-lg-block mt-5 btn-yes"
                  >
                    YES... Show Me How To Start Earning Online NOW!
                  </button>
                </Fade>
              </div>
              <div className="col-md-6 col-xl-5 pe-5 pt-5 pe-md-3 pt-md-3 pe-lg-5 pt-lg-5 position-relative">
                <Fade>
                  <div className="box zoom" />
                  <img src={family} alt="Family" className="w-100 zoom" />
                  <div className="d-md-none">
                    <button
                      type="button"
                      onClick={() => setShowChoice(true)}
                      className="d-lg-none mt-5 btn-yes"
                    >
                      YES... Show Me How To Start Earning Online NOW!
                    </button>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </Feeling>

        <Honest>
          <div className="container py-5">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-xl-5 ps-5 pt-5 ps-md-3 pt-md-3 ps-lg-5 pt-lg-5 position-relative order-1 order-md-0">
                <Fade>
                  <div className="box" />
                  <div className="position-relative">
                    <img
                      src={stamp}
                      alt="Stamp"
                      className="stamp position-absolute zoom"
                    />
                    <img
                      src={street}
                      alt="Person in street"
                      className="w-100 zoom"
                    />
                    <div className="d-md-none ">
                      <button
                        type="button"
                        onClick={() => setShowChoice(true)}
                        className="d-lg-none mt-5 btn-yes"
                      >
                        YES... Show Me How To Start Earning Online NOW!
                      </button>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6 text-end order-0 order-md-1">
                <Fade>
                  <h2>
                    <span>Let’s</span>
                    <span className="gradient"> be honest...</span>
                    <br />
                    Thinking this sounds too good to be true? We get it.
                  </h2>
                  <h3 className="ps-4 ps-sm-0 mt-4 mt-sm-0">
                    We understand that you may have concerns or objections about
                    joining&nbsp;AutoAffiliate.
                  </h3>
                  <h6 className="my-5 mb-sm-3 mt-sm-4">
                    Here's how we address them <br className="d-sm-none" />
                    head-on:
                  </h6>
                  <div className="d-sm-flex">
                    <div className="w-sm-50 ps-5">
                      <h4>
                        Worried about the
                        <br className="d-sm-none" /> time commitment:
                      </h4>
                      <p className="mb-5 mb-sm-4">
                        Our system is optimized to save you time without
                        compromising your active involvement, which is crucial
                        for success.
                      </p>
                    </div>
                    <div className="w-sm-50 ps-5">
                      <h4>
                        Worried about <br />
                        the risk?
                      </h4>
                      <p className="mb-4">
                        AutoAffiliate is low risk with minimal financial
                        commitment. Your biggest investment? Your effort. We
                        make sure it counts.
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <button
                    type="button"
                    onClick={() => setShowChoice(true)}
                    className="d-none d-lg-block mt-5 btn-yes"
                  >
                    YES... Show Me How To Start Earning Online NOW!
                  </button>
                </Fade>
              </div>
            </div>
          </div>
        </Honest>

        <Members>
          <div id="Stories" className="container mt-lg-5 py-5 ">
            <div className="row">
              <div className="col-12 ps-sm-5">
                <Fade>
                  <h2>Don't just take our word for it </h2>
                </Fade>
                <Fade>
                  <h3 className="mb-4 mb-sm-2">
                    See what our members have to&nbsp;say:
                  </h3>
                </Fade>
              </div>
            </div>
          </div>
          <div className="bg-shadow">
            <div className="container">
              <div className="row">
                <div className="col-12 px-0 px-sm-3 mt-5 pt-3">
                  <Fade>
                    <Carousel className="caroussel" />
                    <p className="result-text text-center">
                      Results may vary. This testimonials reflects the personal
                      experience of a few individuals and is not indicative of
                      typical results.
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </Members>

        <Questions id="faq" className="py-5">
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-12 mb-5 pb-5 mb-sm-0 pb-sm-0">
                <Fade>
                  <h2 className="text-center">Common questions</h2>
                </Fade>
              </div>

              <div className="col-11 my-sm-5 border-questions">
                <Fade>
                  <div
                    className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover-top ${
                      answer === 'question_1' ? 'bg-answer' : ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => handleClickQuestion('question_1')}
                      className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                    />
                    <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                      <span className="h2-sm h1-xl mb-0 fw-bold">01</span>
                    </div>
                    <div className="col-sm-8 col-lg-9 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                      <p className="h5-sm h4-xl mb-0 text-grey">
                        What if I am not satisfied with the Masterclass?
                      </p>
                    </div>
                    <div className="col-sm-2 text-center px-0">
                      <button type="button" className="border-0 bg-transparent">
                        <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                          {answer !== 'question_1' ? (
                            <BsPlus size={25} />
                          ) : (
                            <BsDash size={25} />
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="col-2 col-lg-1 d-none d-lg-block" />
                    <div className="col-12 col-lg-10">
                      <p
                        className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                          answer === 'question_1' ? 'active mt-4' : ''
                        }`}
                      >
                        We stand behind the quality and potential of our
                        program, which is why we offer a 30-day money-back
                        guarantee. If you’re not completely satisfied within the
                        first 30 days of joining, simply reach out to us for a
                        full refund, no questions asked.
                      </p>
                    </div>
                    <div className="col-1 d-none d-lg-block" />
                  </div>
                </Fade>
                <Fade>
                  <div
                    className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                      answer === 'question_2' ? 'bg-answer' : ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => handleClickQuestion('question_2')}
                      className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                    />
                    <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                      <span className="h2-sm h1-xl mb-0 fw-bold">02</span>
                    </div>
                    <div className="col-sm-8 col-lg-9 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                      <p className="h6 h5-sm h4-xl mb-0 text-grey ">
                        How much time do I need to dedicate to see results with
                        what I will learn?
                      </p>
                    </div>
                    <div className="col-sm-2 text-center px-0">
                      <button type="button" className="border-0 bg-transparent">
                        <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                          {answer !== 'question_2' ? (
                            <BsPlus size={25} />
                          ) : (
                            <BsDash size={25} />
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="col-2 col-lg-1 d-none d-lg-block" />
                    <div className="col-12 col-lg-10">
                      <p
                        className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                          answer === 'question_2' ? 'active mt-4' : ''
                        }`}
                      >
                        While the exact time can vary depending on your
                        commitment and the specifics of your market, most of our
                        members start to see positive changes with as little as
                        a few hours per week. Our system is designed to be
                        efficient, allowing you to make the most of your time.
                      </p>
                    </div>
                    <div className="col-1 d-none d-lg-block" />
                  </div>
                </Fade>
                <Fade>
                  <div
                    className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                      answer === 'question_3' ? 'bg-answer' : ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => handleClickQuestion('question_3')}
                      className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                    />
                    <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                      <span className="h2-sm h1-xl mb-0 fw-bold">03</span>
                    </div>
                    <div className="col-sm-8 col-lg-9 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                      <p className="h5-sm h4-xl mb-0 text-grey">
                        Do I need previous experience to succeed?
                      </p>
                    </div>
                    <div className="col-sm-2 text-center px-0">
                      <button type="button" className="border-0 bg-transparent">
                        <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                          {answer !== 'question_3' ? (
                            <BsPlus size={25} />
                          ) : (
                            <BsDash size={25} />
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="col-2 col-lg-1 d-none d-lg-block" />
                    <div className="col-12 col-lg-10">
                      <p
                        className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                          answer === 'question_3' ? 'active mt-4' : ''
                        }`}
                      >
                        Not at all! Our masterclass and tools are crafted to
                        guide complete beginners through the process of
                        establishing and growing an online business. We provide
                        step-by-step training that makes learning and applying
                        the concepts straightforward.
                      </p>
                    </div>
                    <div className="col-1 d-none d-lg-block" />
                  </div>
                </Fade>
                <Fade>
                  <div
                    className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 hover-bottom ${
                      answer === 'question_4' ? 'bg-answer' : ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => handleClickQuestion('question_4')}
                      className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                    />
                    <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                      <span className="h2-sm h1-xl mb-0 fw-bold">04</span>
                    </div>
                    <div className="col-sm-8 col-lg-9 text-center text-sm-start my-4 my-sm-0">
                      <p className="h5-sm h4-xl mb-0 text-grey">
                        How does AutoAffiliate help in reducing the complexity
                        of starting an online business?
                      </p>
                    </div>
                    <div className="col-sm-2 text-center px-0 px-xl-3">
                      <button type="button" className="border-0 bg-transparent">
                        <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                          {answer !== 'question_4' ? (
                            <BsPlus size={25} />
                          ) : (
                            <BsDash size={25} />
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="col-2 col-lg-1 d-none d-lg-block" />
                    <div className="col-12 col-lg-10">
                      <p
                        className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                          answer === 'question_4' ? 'active mt-4' : ''
                        }`}
                      >
                        AutoAffiliate simplifies the online business process by
                        providing a comprehensive training and toolkit that
                        includes automated systems, advanced AI technology, and
                        effective marketing strategies. This integrated approach
                        minimizes the usual challenges and speeds up the path to
                        profitability.
                      </p>
                    </div>
                    <div className="col-1 d-none d-lg-block" />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </Questions>

        <DontWait>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-10 text-center">
                <Fade>
                  <h2 className="mb-5 mb-sm-4 mt-5 mt-sm-0">
                    <span>Don't wait</span> <span>any longer!</span>{' '}
                    <br className="d-sm-none" />
                    Start building your financial future today.
                  </h2>
                  <h3 className="mb-5 pb-4">
                    Join the masterclass today and seize the opportunity for
                    change. Our platform simplifies the creation of additional
                    income streams, setting you on the path to success.
                  </h3>
                </Fade>
              </div>
              <div className="col-sm-11">
                <div className="bg-discover py-5 position-relative">
                  <Fade cascade>
                    <img
                      src={discover1}
                      alt="Person"
                      className="pe-none discover1 position-absolute zoom"
                    />
                    <img
                      src={discover2}
                      alt="Person"
                      className="d-none d-sm-block pe-none discover2 position-absolute zoom"
                    />
                    <img
                      src={discover3}
                      alt="Person"
                      className="d-none d-sm-block pe-none discover3 position-absolute zoom"
                    />
                    <img
                      src={discover4}
                      alt="Person"
                      className="pe-none discover4 position-absolute zoom"
                    />
                    <img
                      src={discover5}
                      alt="Person"
                      className="pe-none discover5 position-absolute zoom"
                    />
                    <img
                      src={discover6}
                      alt="Person"
                      className="pe-none discover6 position-absolute zoom"
                    />
                  </Fade>
                  <div className="row">
                    <div className="col-10 col-sm-8 col-xl-9 mx-auto text-center px-0 px-sm-3 pt-5 py-sm-5">
                      <Fade>
                        <h3 className="mb-4 mb-sm-2">
                          JOIN THE MASTERCLASS
                          <br className="d-md-none" /> NOW AND
                        </h3>
                      </Fade>
                      <Fade>
                        <h2 className="mb-4 my-sm-3">
                          Discover How To Build <br />
                          Additional Income Streams{' '}
                        </h2>
                      </Fade>
                      <Fade>
                        <p className="px-4 px-sm-0 mb-0">With AutoAffiliate</p>
                      </Fade>
                      <Fade>
                        <button
                          type="button"
                          onClick={() => setShowChoice(true)}
                          className="mt-3 btn-yes"
                        >
                          Show Me How
                        </button>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DontWait>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-11 my-5 py-5">
              <Disclaimer />
            </div>
          </div>
        </div>

        <ModalSaveMoney
          className="mt-5"
          showChoice={showChoice}
          onClickShow={() => setShowChoice(false)}
        />
      </Container>
    </>
  );
};

export default HomeEmail;
