import styled from 'styled-components';
import mark from '~/assets/defaults/mark-text.svg';
import markLg from '~/assets/defaults/mark-text-lg.svg';

export const Container = styled.div`
  .c-blue {
    color: #116bff;
  }
  .mark-text {
    background-image: url(${mark});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 7.5px;
  }

  .mark-text-lg {
    background-image: url(${markLg});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 7.5px 25px;
  }
  .btn-download {
    padding: 5px 65px 5px 41px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 20px;
    background: #116bff;
    color: #fff;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -1.36px;
    span span {
      display: block;
      text-align: end;
      font-family: 'Quicksand';
      font-size: 20px;
      font-weight: 500;
      line-height: 138.75%;
      letter-spacing: -0.8px;
    }
  }

  button.btn-yes {
    color: #fff;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -1.36px;
    border-radius: 22px;
    background: #116bff;
    border: none;
    min-height: 86px;
  }

  p.satisfaction {
    color: #116bff;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: 600;
    line-height: 138.75%;
    letter-spacing: -0.64px;
  }

  button.btn-no-thanks {
    margin: 0 auto;
    color: #ff1a50;
    background: transparent;
    padding: 0;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 24px;
    font-weight: 500;
    line-height: 138.75%;
    letter-spacing: -0.96px;
  }

  .cards {
    width: 100%;
    max-width: 430px;
  }

  .red {
    color: #ff1a50;
  }

  .fw-500 {
    font-weight: 500;
  }

  @media screen and (max-width: 991px) {
    button.btn-yes {
      font-size: 26px;
      padding: 10px 16px;
    }

    button.btn-no-thanks {
      font-size: 19px;
    }
  }

  @media screen and (min-width: 992px) {
    .container {
      max-width: 1320px !important;
    }
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.85;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }
`;

export const Attention = styled.div`
  background: #ff1a50;
  box-shadow: 0px 4px 4px rgba(255, 26, 80, 0.15);

  h2 {
    font-weight: 400;
    font-size: 18px;
  }

  svg {
    height: 30px;
    width: 30px;
  }

  @media screen and (max-width: 991px) {
    svg {
      height: 25px;
      width: 25px;
    }
  }
`;

export const Gpt = styled.div`
  h1 {
    color: #116bff;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;

    span {
      color: #021027;
      font-weight: 400;
      font-size: 32px;
    }
  }

  h2 {
    color: #000;
    font-family: 'Libre Baskerville';
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    span span {
      color: #ff1a50;
    }
  }

  h3 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }

  h4 {
    color: #021027;
    font-family: 'Archivo';
    font-size: 24px;
    font-weight: 300;
    line-height: 125%;

    span:nth-child(1) {
      font-weight: 700;
    }

    span:nth-child(2) {
      color: #116bff;
      font-family: 'Architects Daughter';
      font-size: 28px;
      font-weight: 400;
      line-height: 125%;
    }
  }

  .whats-next {
    position: absolute;
    right: -21%;
    bottom: 0;
    width: 217px;
    height: 187px;
  }

  .lootie > div {
    margin: 0 10px !important;
  }

  .thumb,
  video {
    border: 1px solid #303030;
    -webkit-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
  }

  .thumb {
    width: 100%;
    border-radius: 25px;
  }

  @media screen and (max-width: 991px) {
    h1 {
      font-size: 30px;
      line-height: 100%;
    }
    h2 {
      font-size: 30px;
    }
    h4 {
      font-size: 21px;
    }

    .whats-next {
      position: absolute;
      right: -25%;
      bottom: 0;
      width: 150px;
      height: 120px;
    }
  }

  @media screen and (max-width: 575px) {
    h1 span {
      font-size: 23px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 19px;
    }

    img {
      width: 100%;
      max-width: 431px;
      height: auto;
    }

    .btn-download {
      padding: 5px 40px 5px 15px;
    }
  }
`;

export const Steps = styled.div`
  h2 {
    color: #021027;

    text-align: center;
    font-family: 'Archivo';
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%;
  }

  h3 {
    color: #021027;
    font-family: 'Libre Baskerville';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  h4 {
    color: #116bff;
    text-align: center;
    font-family: 'Love Ya Like A Sister';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  p {
    position: relative;
    color: #000;
    font-family: 'Archivo';
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 204%;
    b {
      font-weight: 700;
    }

    img {
      position: absolute;
      top: 10px;
      left: -50px;
    }
  }

  .check-1 {
    width: 42px;
    height: 38px;
  }

  .check-2 {
    width: 30px;
    height: 34px;
  }

  .check-3 {
    width: 29px;
    height: 33px;
  }

  .check-4 {
    width: 30px;
    height: 28px;
  }

  .check-5 {
    width: 26px;
    height: 39px;
  }

  .lamp {
    position: absolute;
    top: -30px;
    left: -8%;
    width: 87px;
    height: 93px;
  }

  .effortless {
    position: absolute;
    bottom: -8px;
    left: 10px;
    width: 90%;
  }

  .gradient {
    background: linear-gradient(
      270.04deg,
      #fb5a43 0.03%,
      #c341ae 70.44%,
      #9445c2 80.35%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 40px;
    }

    h3 {
      font-size: 25px;
    }

    h4 {
      font-size: 30px;
    }

    p {
      font-size: 22px;
    }

    .lamp {
      position: absolute;
      top: -82px;
      left: -2%;
      width: 67px;
      height: 73px;
    }
  }
`;

export const Access = styled.div`
  margin-bottom: 7rem;
  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 119.5%; /* 71.7px */
  }

  h3 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  h4 {
    color: #ff1a50;

    text-align: center;
    -webkit-text-stroke-width: 0.25;
    -webkit-text-stroke-color: #ff1a50;
    font-family: 'Loved by the King';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  h5 {
    color: #116bff;

    text-align: center;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #116bff;
    font-family: 'Loved by the King';
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 119.5%; /* 119.5px */
    letter-spacing: -3px;
  }

  p {
    color: #000;

    font-family: 'Archivo';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 119.5%;
  }

  h6 {
    color: #021027;
    font-family: 'Libre Baskerville';
    font-size: 40px;
    font-weight: 400;
    line-height: 130%;
  }

  .try-it {
    position: absolute;
    top: -22px;
    left: -8px;
    width: 118px;
    height: 96px;
  }

  .h2-gradient {
    font-family: 'Archivo';
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%;
    background: linear-gradient(
      270deg,
      #fb5a43 0.03%,
      #c341ae 30.44%,
      #9445c2 57.35%,
      #079cfe 95.73%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .eye {
    position: absolute;
    bottom: -10px;
    right: -100px;
    width: 50px;
    height: 50px;
  }

  .star-1 {
    position: absolute;
    bottom: 10px;
    left: -30px;
    width: 60px;
    height: 60px;
  }
  .star-2 {
    position: absolute;
    bottom: 10px;
    right: -30px;
    width: 60px;
    height: 60px;
  }
  @media screen and (max-width: 991px) {
    h2 {
      font-size: 40px;
    }

    h3 {
      font-size: 25px;
    }

    h4 {
      font-size: 40px;
    }

    h5 {
      font-size: 60px;
    }

    h6 {
      font-size: 30px;
      line-height: 178%;
    }

    p {
      font-size: 25px;
    }

    .h2-gradient {
      font-size: 45px;
    }

    .try-it {
      position: absolute;
      top: -17px;
      left: -8px;
      width: 94px;
      height: 76px;
    }
  }
`;

export const Dear = styled.div`
  border-radius: 30px;
  border: 4px dashed #116bff;
  background: rgba(245, 245, 245, 0.96);
  margin: 0 10px;
  position: relative;
  h2,
  p {
    color: #021027;
    font-family: 'Architects Daughter';
    font-size: 26px;
    font-weight: 400;
    line-height: 150%;
  }

  h3 {
    color: #116bff;
    font-family: 'Architects Daughter';
    font-size: 32px;
    font-weight: 400;
    line-height: 150%;
  }

  h4 {
    color: #021027;
    font-family: 'Archivo';
    font-size: 26px;
    font-style: italic;
    font-weight: 600;
    line-height: 150%;
  }

  .logo {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 52px;
    height: 48px;
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 25px;
    }
  }
`;

export const Current = styled.div`
  h2 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 60px;
    font-weight: 700;
    line-height: 119.5%;
    span {
      color: #116bff;
    }
  }

  h3 {
    color: #021027;
    text-align: center;
    font-family: 'Libre Baskerville';
    font-size: 40px;
    font-weight: 400;
    line-height: 119.5%;
  }

  button {
    padding-left: 36px;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -1.36px;
    border-radius: 40px;
    background: #116bff;
    border: none;
    span {
      margin: 13px 13px 13px 36px;
      padding: 13px 24px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.32);
    }
  }

  .btn-no-thanks {
    margin: 0 auto;
    color: #116bff;
    background: transparent;
    padding: 0;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 24px;
    font-weight: 500;
    line-height: 138.75%;
    letter-spacing: -0.96px;
  }

  .only {
    color: #021027;
    text-align: center;
    font-family: 'Archivo';
    font-size: 60px;
    font-weight: 700;
    line-height: 125%;
    span {
      font-weight: 800;
      background: linear-gradient(
        270.04deg,
        #fb5a43 0.03%,
        #c341ae 75.44%,
        #9445c2 99.35%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .saving-text {
    color: #116bff;
    text-align: center;
    font-family: 'Loved by the King';
    font-size: 60px;
    font-weight: 600;
    line-height: 119.5%;
  }

  .text-btn-send {
    color: #ff2e00;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 28px;
    font-weight: 600;
    line-height: 138.75%;
    letter-spacing: -1.12px;
  }

  .disclaimer {
    border: 1px solid #bcb2b2;
    border-radius: 40px;
  }

  .disclaimer h2 {
    font-family: 'Archivo', sans-serif;
    font-weight: 400;
    font-size: 26px;
    color: #116bff;
  }

  .disclaimer p {
    font-family: 'Archivo', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 133.34%;
    color: #202020;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 34px;
    }

    h3 {
      font-size: 28px;
    }

    button {
      font-size: 26px;
      padding-left: 20px;
    }

    .only {
      font-size: 34px;
      span {
        background: linear-gradient(
          270.04deg,
          #fb5a43 0.03%,
          #c341ae 30.44%,
          #9445c2 57.35%,
          #079cfe 95.73%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .saving-text {
      font-size: 38px;
    }

    .text-btn-send {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    button {
      font-size: 26px;
      padding-left: 20px;
    }

    button span {
      margin: 13px 13px 13px 18px;
      padding: 13px 13px;
    }

    .disclaimer p {
      font-size: 16px;
    }

    .disclaimer h2 {
      font-size: 26px;
    }

    .btn-no-thanks {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 425px) {
    button {
      font-size: 23px;
    }
  }

  @media screen and (min-width: 768px) {
    .disclaimer {
      border: none;
    }
    .disclaimer p {
      font-family: 'Inter', sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 133.34%;
      color: #797979;
      span {
        font-weight: 500;
      }
    }
  }
`;
