import React from 'react';

import { Helmet } from 'react-helmet';
import { Container } from './styles';

const Dmca: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col pt-5 my-5">
            <h1>
              AutoAffiliate.ai Copyright <br className="d-none d-xl-block" />
              Infringement and DMCA Notice
            </h1>
            <h2>Copyright Infringement Policy and DMCA Notice</h2>
            <p>Effective Date: June 15, 2023</p>
            <p>
              AutoAffiliate.ai, a division of SmartTech Labs, Inc., is committed
              to upholding the highest standards of intellectual property rights
              protection and adheres strictly to the Digital Millennium
              Copyright Act of 1998 (DMCA), 17 U.S.C. § 512. We respect the
              intellectual property rights of others and expect the same from
              our users. This document outlines our procedures for handling
              claims of copyright infringement concerning content posted on
              AutoAffiliate.ai ("the Site").
            </p>
            <p>
              This copyright notice applies to all who access this website, its
              products and/or services, including all visitors to this website.
            </p>
            <h3 className="h4 fw-semibold">
              Reporting Copyright Infringement:
            </h3>
            <p>
              If you believe that your copyright has been infringed upon on our
              Site, please provide us with a written notification containing the
              following:
            </p>
            <ul>
              <li>
                <b>Authorized Signature:</b> The digital or physical signature
                of the copyright owner or a person authorized to act on their
                behalf.
              </li>
              <li>
                <b>Identification of Copyrighted Work:</b> A detailed
                description of the copyrighted work that you claim has been
                infringed.
              </li>
              <li>
                <b>Location of Infringing Material:</b> Specific details about
                where the alleged infringing material is located on the Site,
                ideally with direct URLs.
              </li>
              <li>
                <b>Contact Information:</b> Your full address, telephone number,
                and email address.
              </li>
              <li>
                <b>Good Faith Statement:</b> A statement, made under penalty of
                perjury, that you believe in good faith that the disputed use is
                not authorized by the copyright owner, its agent, or the law.
              </li>
              <li>
                <b>Accuracy Statement:</b> A statement, made under penalty of
                perjury, that the information in your notice is accurate and
                that you are the copyright owner or authorized to act on their
                behalf.
              </li>
            </ul>
            <h3 className="h5 fw-bold mb-4">
              Contact Information for Copyright Agent:
            </h3>
            <p>
              To report a copyright infringement, contact AutoAffiliate's
              designated Copyright Agent at:
            </p>
            <p className="mb-0">
              <b>Address:</b> SmartTech Labs Inc. DBA AutoAffiliate, 30 N Gould
              St Ste R, Sheridan, WY, 92801
            </p>
            <p>
              <b>Email:</b>{' '}
              <a
                href="mailto:support@AutoAffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@AutoAffiliate.ai
              </a>
            </p>
            <h3 className="h5 fw-bold pt-4 mb-4">
              DMCA Provisions and Actions Upon Receipt of a Valid Infringement
              Notice:
            </h3>
            <p>
              Upon receiving a properly filed infringement notice, AutoAffiliate
              will take the following actions:
            </p>

            <ul>
              <li>
                <b>Review the Reported Material:</b> Promptly examine the
                material in question.
              </li>
              <li>
                <b>Action:</b> Remove or disable access to the material alleged
                to be infringing.
              </li>
              <li>
                <b>Notify the Uploader:</b> Inform the content provider or user
                about the claim and any action taken.
              </li>
              <li>
                <b>Repeat Infringers:</b> Users who are found to repeatedly
                infringe copyright may face termination or suspension of their
                access to the Site.
              </li>
            </ul>
            <h3 className="h5 fw-bold pt-4 mb-4">Counter-Notification:</h3>
            <p>
              If you believe your content was mistakenly removed due to a
              copyright claim, you may submit a counter-notification including:
            </p>
            <ul className="mt-0">
              <li className="mb-0">Your signature (digital or physical).</li>
              <li className="mb-0">
                Identification of the material removed and its prior location.
              </li>
              <li className="mb-0">
                A statement under penalty of perjury that you believe the
                material was removed by mistake or misidentification.
              </li>
              <li className="mb-0">Your contact information.</li>
              <li>
                Consent to the jurisdiction of your local federal court,
                agreeing to accept service of process from the complainant.
              </li>
            </ul>
            <h3 className="h5 fw-bold pt-4 mb-4">Legal Liability:</h3>
            <p>
              Filing false claims of copyright infringement can result in legal
              liability. We advise seeking legal counsel if you are unsure of
              your material's copyright protection.
            </p>
            <h3 className="h5 fw-bold pt-4 mb-4">Policy Updates:</h3>
            <p>
              This policy may be updated to reflect new legal requirements or
              changes in our procedures. We encourage users to review it
              periodically. Updates will be posted on our Site and, when
              appropriate, communicated directly to our users.
            </p>
            <h3 className="h5 fw-bold pt-4 mb-4">Acknowledgment</h3>
            <p className="fw-semibold">
              By using AutoAffiliate.ai, you agree to comply with this Copyright
              Infringement and DMCA Notice. Our goal is to ensure a legally
              compliant and respectful environment for all users.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dmca;
