/* eslint-disable no-console */
import React, { useCallback, useState } from 'react';
import {
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaTelegramPlane,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { Modal, CopyAlert, CopyButton } from './styles';

import copy from '~/assets/icons/copy-blue.svg';
import logo from '~/assets/logos/logo.svg';

interface IModal {
  show: boolean;
  slug: string;
  reference: string;
  onHide(): void;
}

const ModalShareDesk: React.FC<IModal> = ({
  show,
  slug,
  reference,
  onHide,
}) => {
  const [copied, setCopied] = useState(false);

  const copyUrl = useCallback(() => {
    const el = document.createElement('textarea');
    el.value = `https://autoaffiliate.ai/blog/${slug}/i/${reference}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [reference, slug]);
  return (
    <>
      <Modal
        className="modal-verify-email"
        centered
        size="xl"
        show={show}
        onHide={onHide}
      >
        <Modal.Header className="border-0 justify-content-center mt-3 pt-0">
          <button
            type="button"
            className="h4 modal-close m-2 mb-0 ms-auto border-0 bg-transparent"
            onClick={onHide}
          >
            <IoMdClose color="#151517" size={24} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-4">
            <img src={logo} alt="Logo" className="text-center" />
          </div>
          <h2 className="text-center">Thanks for sharing!</h2>
          <h3 className="mx-auto w-75 text-center my-3">
            Simply click one of the buttons below or copy the link
          </h3>

          <div className="w-100 pt-5 mx-auto">
            <div className="d-lg-flex justify-content-center social-media mb-5 mt-3">
              <div className="d-flex mb-3 mb-lg-0 pr-lg-1">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://autoaffiliate.ai/blog/${slug}/i/${reference}`}
                  className="d-flex align-items-center justify-content-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="icon d-flex align-items-center justify-content-center me-2">
                    <FaFacebookF size={17} color="" />
                  </div>
                  <span>Share</span>
                </a>
                <a
                  href={`https://api.whatsapp.com/send?text=https://autoaffiliate.ai/blog/${slug}/i/${reference}`}
                  className="d-flex align-items-center justify-content-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="icon d-flex align-items-center justify-content-center me-2">
                    <FaWhatsapp size={17} color="" />
                  </div>
                  <span>Share</span>
                </a>
                <a
                  href={`https://telegram.me/share/url?url=https://autoaffiliate.ai/blog/${slug}/i/${reference}`}
                  className="d-flex align-items-center justify-content-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="icon d-flex align-items-center justify-content-center me-2">
                    <FaTelegramPlane size={17} color="" />
                  </div>
                  <span>Share</span>
                </a>
              </div>
              <div className="d-flex ps-lg-3">
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=https://autoaffiliate.ai/blog/${slug}/i/${reference}`}
                  className="d-flex align-items-center justify-content-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="icon d-flex align-items-center justify-content-center me-2">
                    <FaLinkedinIn size={17} color="" />
                  </div>
                  <span>Share</span>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=https://autoaffiliate.ai/blog/${slug}/i/${reference}`}
                  className="d-flex align-items-center justify-content-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="icon d-flex align-items-center justify-content-center me-2">
                    <FaTwitter size={17} color="" />
                  </div>
                  <span>Tweet</span>
                </a>
                <a
                  href={`mailto:?subject=https://autoaffiliate.ai/blog/${slug}/i/${reference}`}
                  className="d-flex align-items-center justify-content-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="icon d-flex align-items-center justify-content-center me-2">
                    <FaEnvelope size={17} color="" />
                  </div>
                  <span>Email</span>
                </a>
              </div>
            </div>
            <div className="w-100 justify-content-center d-flex pt-4 mb-5">
              <CopyButton
                type="button"
                className="position-relative d-flex justify-content-between align-items-center my-lg-2"
                onClick={copyUrl}
              >
                <span>{`https://autoaffiliate.ai/blog/${slug}/i/${reference}`}</span>
                <div className="border-0 bg-transparent">
                  <img src={copy} alt="Copy" />
                </div>
                <CopyAlert show={copied} className="card card-success">
                  <p className="h4 m-2">Copied!</p>
                </CopyAlert>
              </CopyButton>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 my-4" />
      </Modal>
    </>
  );
};

export default ModalShareDesk;
