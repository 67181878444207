import styled from 'styled-components';
import banner1 from '~/assets/banners/banner-blog-1.png';
import banner2 from '~/assets/banners/banner-blog-2.png';
import avatar from '~/assets/defaults/avatar.png';
import quote from '~/assets/icons/quote-black.svg';

interface IBackground {
  bgImage: string;
}

export const Container = styled.div`
  margin-top: 16px;

  .container {
    max-width: 1400px;
  }
`;

export const BgImage = styled.div<IBackground>`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.64) 94.79%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url(${(props) => props.bgImage}),
    lightgray -1.071px -274.552px / 102.138% 187.716% no-repeat;
  height: 502px;
  width: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px;

  box-shadow: -1px 31px 99px -73px rgb(0 0 0 / 75%);

  @media screen and (max-width: 1700px) {
  }
`;

export const ArticleBody = styled.div`
  h1 {
    color: #000;
    font-family: 'Archivo';
    font-size: 58px;
    font-style: italic;
    font-weight: 600;
    line-height: 100%;
  }

  h1[id='1'] {
    display: none;
  }

  h2 {
    color: #000;
    font-family: 'Source Sans Pro';
    font-size: 30px;
    font-weight: 600;
    margin-top: 3rem;
  }

  h3,
  li {
    color: #000;
    font-family: 'Source Sans Pro';
    font-size: 22px;
    line-height: 160%;
  }

  h3 {
    font-weight: 700;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }

  li {
    font-weight: 300;
  }

  p {
    color: #000;
    font-family: 'Source Sans Pro';
    font-size: 22px;
    font-weight: 300;
    line-height: 160%;
  }

  .bg-marketing1 {
    border-radius: 30px;
    background-image: url(${banner1});
    box-shadow: 0px 19px 70px 0px rgba(0, 0, 0, 0.15) !important;
    width: 800px;
    max-width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-family: 'Poppins';
    font-size: 60px;
    font-weight: 400;
    line-height: 129%;
    letter-spacing: -0.9px;
    padding: 57px 46px;

    span {
      color: #65ffbe;
      font-weight: 700;
    }
  }

  .bg-marketing2 {
    border-radius: 30px;
    background-image: url(${banner2});
    box-shadow: 0px 19px 70px 0px rgba(0, 0, 0, 0.15) !important;
    width: 800px;
    max-width: 100%;
    height: 381px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .bg-testimony {
      border-radius: 25px;
      background: #fff;
      top: -70px;
      width: 75%;
      display: block;
      margin: 0 auto;
      position: relative;
      padding: 3rem 4rem;

      svg {
        position: absolute;
        top: 23px;
        left: 64px;
        width: 76px;
        height: 66px;
      }

      .testimony-title {
        color: #151517;
        text-align: center;
        font-family: 'Quicksand';
        font-size: 28px;
        font-weight: 700;
        line-height: 32.5px;
        display: block;
        z-index: 1;
        position: relative;
      }

      .testimony {
        color: #676767;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
      }

      .image {
        height: 74px;
        width: 74px;
        background-image: url(${avatar});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 20px;
      }

      .name {
        color: #000;
        font-family: 'Quicksand';
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }

      .member {
        color: #000;
        font-family: 'Source Sans Pro';
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
      }
    }
  }

  .captions {
    color: #000;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 300;
    line-height: 160%;
    width: 800px;
    max-width: 100%;
  }

  .fw-600 {
    font-weight: 600 !important;
  }

  .body {
    div {
      text-align: center;
    }
    i {
      position: relative;
      right: -50px;

      color: #000;
      font-family: 'Source Serif Pro';
      font-size: 40px;
      font-style: italic;
      font-weight: 600;
      line-height: 140%;
      margin-top: 67px;
      margin-bottom: 67px;
      margin-right: 50px;
      display: block;
    }

    i::after {
      content: '';
      position: absolute;
      left: -48px;
      top: 0;
      width: 48px;
      height: 38px;
      background-image: url(${quote});
      background-size: contain;
      background-repeat: no-repeat;
    }

    blockquote {
      color: #000;
      font-family: 'Source Sans Pro';
      font-size: 24px;
      font-style: italic;
      font-weight: 400;
      line-height: 160%;
      border-left: solid 4px #000;
      padding-left: 20px;

      margin-top: 67px;
      margin-bottom: 67px;
      p {
        margin: 0;
        padding: 20px 0;
      }
    }
  }

  .bg {
    background-color: #fafafa;
    border-radius: 25px;
    margin-top: -130px;
    padding: 45px 90px;
  }

  .bg-avatar {
    background-color: #000;
    padding: 1.5px;
    border-radius: 24px;
    width: 65px;
    height: 65px;
  }

  .hashtag {
    color: #116bff;
    font-family: 'Source Sans Pro';
    font-size: 22px;
    font-weight: 600;
    line-height: 160%;
  }

  .min {
    color: #021027;

    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 300;
    line-height: 160%;
  }

  .num-clap {
    color: #4c4c4c;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 300;
    line-height: 160%;
  }

  .by {
    span {
      color: #021027;
      font-family: 'Source Sans Pro';

      line-height: 130%;
    }
    span:nth-child(1) {
      font-size: 22px;
      font-weight: 600;
    }

    span:nth-child(2) {
      font-size: 16px;
      font-weight: 300;
    }
  }

  .btn-share {
    font-family: 'Archivo';
    border-radius: 16px;
    border: 1px solid #116bff;
    color: #116bff;
    padding: 12px;
    width: 209px;
    font-size: 24px;
    font-weight: 500;
    transition-duration: 0.2s;
    svg {
      color: #116bff;
      font-size: 30px;
    }
    :hover {
      opacity: 0.8;
    }
  }

  .btn-share-it {
    font-family: 'Archivo';
    border: none;
    color: #116bff;
    padding: 12px;

    font-size: 18px;
    font-weight: 500;
    transition-duration: 0.2s;
    :hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1400px) {
    h1 {
      font-size: 45px;
    }

    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 18px;
    }
    .bg {
      padding: 45px;
    }

    .btn-share {
      font-size: 21px;
    }

    .hashtag {
      font-size: 20px;
    }

    .by {
      span:nth-child(1) {
        font-size: 19px;
        font-weight: 600;
      }

      span:nth-child(2) {
        font-size: 14px;
        font-weight: 300;
      }
    }

    .captions {
      font-size: 14px;
    }

    .bg-marketing1 {
      font-size: 50px;
    }

    .bg-marketing2 .bg-testimony svg {
      left: 40px;
      width: 60px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 30px;
    }
    h3,
    p {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 32px;
    }
    .body {
      i {
        font-size: 30px;
        right: -30px;
      }

      i::after {
        width: 27px;
        height: 20px;
        left: -28px;
      }
    }

    .bg {
      margin-top: 0px;
      background-color: #fff;
      padding: 25px 10px;
    }

    .hashtag {
      font-size: 22px;
    }

    .bg-avatar {
      width: 52px;
      height: 52px;
      border-radius: 18px;
    }

    .btn-share {
      border-radius: 12px;
      width: 125px;
      font-size: 16px;
      background-color: #116bff !important;
      color: #fff;
      border: none;
      svg {
        color: #fff;
        font-size: 18px;
      }
    }

    .bg-marketing1 {
      font-size: 37px;
      padding: 40px 22px;
    }
  }

  @media screen and (max-width: 575px) {
    .bg {
      padding: 25px 0px;
    }

    .bg-marketing2 .bg-testimony {
      width: 90%;
      padding: 2rem;
    }

    .bg-marketing2 .bg-testimony .testimony-title {
      font-size: 24px;
    }

    .bg-marketing2 .bg-testimony .testimony {
      font-size: 15px;
    }

    .bg-marketing2 .bg-testimony .image {
      height: 50px;
      width: 50px;
    }

    .bg-marketing2 .bg-testimony .name {
      font-size: 18px;
      line-height: 22px;
    }

    .bg-marketing2 .bg-testimony .member {
      font-size: 14px;
      line-height: 22px;
    }

    .bg-marketing2 .bg-testimony svg {
      left: 15px;
      top: 4px;
      width: 19px;
    }
  }
`;

export const Others = styled.div`
  h2 {
    color: #000;
    text-align: center;
    font-family: 'Archivo';
    font-size: 28px;
    font-weight: 500;
    line-height: 125%;
  }

  h3 {
    color: #151517;
    font-family: 'Archivo';
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    min-height: 52px;
  }

  p {
    color: #676767;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    min-height: 116px;
  }

  a {
    color: #000;
    font-family: 'Archivo';
    font-size: 18px;
    font-weight: 500;
    line-height: 125%;
    transition-duration: 0.2s;
    :hover {
      opacity: 0.8;
    }
  }

  button {
    color: #116bff;
    font-family: 'Archivo';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.72px;
    transition-duration: 0.2s;
    :hover {
      opacity: 0.8;
    }
  }

  .num-clap {
    color: #4c4c4c;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 300;
    line-height: 160%;
  }

  .thumb {
    border-radius: 20px;
    background: #f5f3ed;
    box-shadow: 0px 19px 70px 0px rgba(0, 0, 0, 0.15);
  }

  .bg-gradient-others::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }

  @media screen and (max-width: 1400px) {
    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    p,
    a,
    button {
      font-size: 16px;
    }
  }
`;

export const Discover = styled.div`
  .zoom {
    zoom: 0.6;
  }

  .bg-discover button {
    font-family: 'Archivo', sans-serif;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: #fff;
    background: #116bff;
    border-radius: 20px;
    padding: 22px 50px;
    border: none;
    transition-duration: 0.3s;

    :hover {
      opacity: 0.8;
    }
  }

  .bg-discover {
    background: #021027;
    border-radius: 65px;
  }

  .bg-discover {
    h2,
    h3,
    p {
      font-family: 'Poppins', sans-serif;
    }
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #37ebaa;
    }
    h2 {
      font-weight: 600;
      font-size: 56px !important;
      line-height: 60px;
      color: #f3f4f8;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #b1b1b5;
    }
  }

  .discover1 {
    top: 5%;
    left: 5%;
  }

  .discover2 {
    top: 42%;
    left: 8%;
  }

  .discover3 {
    top: 73%;
    left: 20%;
  }

  .discover4 {
    top: 5%;
    right: 5%;
  }

  .discover5 {
    top: 35%;
    right: 3%;
  }

  .discover6 {
    top: 67%;
    right: 10%;
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-weight: 300;
      font-size: 38px !important;
      span:first-child {
        font-size: 56px;
      }
      span:nth-child(2) {
        font-weight: 500;
        font-size: 53px;
      }
    }

    h3 {
      font-size: 22px;
    }
    button {
      font-size: 24px;
      padding: 22px 69px;
    }

    .bg-discover h2 {
      font-size: 46px !important;
      line-height: 60px;
    }

    .bg-discover p {
      font-size: 18px;
      line-height: 32px;
    }

    .discover1 {
      top: 8%;
      left: 5%;
      width: 130px;
    }

    .discover4 {
      top: 2%;
      right: 2%;
    }

    .discover5 {
      top: 67%;
      right: 0%;
      width: 172px;
    }

    .discover6 {
      top: 51%;
      right: auto;
      left: 2%;
      width: 130px;
    }

    .disclaimer p {
      font-size: 16px;
    }

    .disclaimer h2 {
      font-size: 26px;
    }
  }

  @media screen and (min-width: 576px) {
    h2 {
      font-weight: 300;
      font-size: 38px;
      span:first-child {
        font-size: 56px;
      }
      span:nth-child(2) {
        font-weight: 500;
        font-size: 53px;
      }
    }

    h3 {
      font-size: 22px;
    }

    .bg-discover h2 {
      font-size: 40px;
    }

    .discover3 {
      left: 7%;
    }
  }

  @media screen and (min-width: 768px) {
    button {
      padding: 22px 82px;
    }

    .disclaimer {
      border: none;
    }

    .disclaimer p {
      font-family: 'Inter', sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 133.34%;
      color: #797979;
      span {
        font-weight: 500;
      }
    }
  }

  @media screen and (min-width: 992px) {
    h2 {
      font-weight: 500;
      font-size: 53px;
      line-height: 65px;
    }

    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }
`;
