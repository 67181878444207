import React from 'react';
import { IoArrowDown } from 'react-icons/io5';
import { Container } from './styles';

import facebook from '~/assets/icons/facebook-hero.svg';
import instagram from '~/assets/icons/instagram-hero.svg';
import x from '~/assets/icons/x-hero.svg';
import youtube from '~/assets/icons/youtube-hero.svg';
import tiktok from '~/assets/icons/tiktok-hero.svg';
import threads from '~/assets/icons/threads-hero.svg';

interface LogosProps {
  className?: string;
}

const socials = [
  { social: 'Facebook', logoSocial: facebook },
  { social: 'Instagram', logoSocial: instagram },
  { social: 'X', logoSocial: x },
  { social: 'Youtube', logoSocial: youtube },
  { social: 'TikTok', logoSocial: tiktok },
  { social: 'Threads', logoSocial: threads },
  { social: 'Facebook', logoSocial: facebook },
  { social: 'Instagram', logoSocial: instagram },
  { social: 'X', logoSocial: x },
  { social: 'Youtube', logoSocial: youtube },
  { social: 'TikTok', logoSocial: tiktok },
  { social: 'Threads', logoSocial: threads },
  { social: 'Facebook', logoSocial: facebook },
  { social: 'Instagram', logoSocial: instagram },
  { social: 'X', logoSocial: x },
  { social: 'Youtube', logoSocial: youtube },
];

const Logos: React.FC<LogosProps> = ({ className }) => {
  return (
    <Container className={`${className}`}>
      <div className="bg-socials">
        <h5 className="tagline mb-6 text-center text-n-1/50 ">
          <span className="d-block text-white opacity-50 pt-4 px-10 flex-inline justify-content-center align-items-center">
            HELPING PEOPLE POST BEAUTIFUL CONTENT DAILY AT
          </span>
        </h5>
        <div className="overflow-hidden position-relative">
          <div className="left-side" />
          <div className="right-side" />
          <ul className="d-flex track mb-0">
            {socials.map((item, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="text-white text-size fw-bold d-flex align-items-center mx-10 justify-content-center mb-0"
              >
                <img
                  src={item.logoSocial}
                  width={70}
                  height={70}
                  alt={item.social}
                />
                {item.social}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Logos;
