import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;

  button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  video {
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
  }

  @media screen and (max-width: 1799px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    video {
      border-radius: 25px;
    }
  }
`;
