import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import free from '~/assets/banners/banner-free-new.png';
import bannerNew from '~/assets/banners/banner-lp.png';

export const Container = styled.div`
  font-family: 'Quicksand', sans-serif;

  button.modal-works {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #116bff;
    font-size: 28px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    line-height: 45px;
    color: #fff;
    border: none;
    padding: 5px;
    z-index: 10;
    transition: all 1s ease;

    span {
      margin: -13px 2rem;
    }
  }

  .zoom {
    zoom: 0.6;
  }

  .banner-group {
    min-height: 1080px;
    background-image: url(${bannerNew});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -360px;
    margin-bottom: -823px;
  }

  @media screen and (max-width: 991px) {
    button.modal-works {
      font-size: 16px;
      line-height: 29px;
    }
  }

  @media screen and (max-width: 575px) {
    overflow-x: hidden;
    .container {
      max-width: 400px;
    }

    button.modal-works {
      span {
        margin: -13px 4rem -13px 0rem;
      }
    }
    /* .banner-group {
      margin-top: -325px;
      margin-bottom: -823px;
    } */
  }

  @media screen and (min-width: 992px) {
    .zoom {
      zoom: 0.8;
    }

    .active {
      top: -100px;
    }

    .not-active {
      top: 0px;
    }

    .banner-group {
      margin-top: -505px;
      margin-bottom: -620px;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }

  @media screen and (min-width: 1700px) {
    .banner-group {
      background-position: bottom;
    }
  }
`;

export const Join = styled.div`
  h1 {
    font-weight: 400;
    font-size: 41px;

    line-height: 119.5%;
    color: #000;
    margin-top: 30px;

    img.circle {
      top: -57px;
      left: 30px;
    }
  }

  p {
    color: #021027;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 20px;
    font-weight: 400;
    line-height: 119.5%;
  }

  .master {
    font-weight: 700;
    color: #116bff;
  }

  .fw-semibold {
    font-weight: 600 !important;
  }

  .archivo {
    font-family: 'Archivo';
  }

  form {
    background: #fafafa;
    border: 1px solid #116bff;
    border-radius: 24px;
    img {
      min-width: auto;
    }
    > div {
      border: none;
      width: 49%;
    }

    input {
      font-family: 'Archivo', sans-serif;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: -0.025em;
      color: #151517;
    }

    input::placeholder {
      font-weight: 300 !important;
      font-size: 16px;
      line-height: 24px;
      color: #b0b0b0;
    }

    button {
      font-family: 'Archivo', sans-serif;
      font-size: 24px;
      line-height: 110%;
      letter-spacing: -0.04em;
      color: #fff;
      background: #116bff;
      border-radius: 20px;
      padding: 17px 28px;
      border: none;
      transition-duration: 0.3s;
      font-weight: 500;

      :hover {
        opacity: 0.8;
      }
    }
  }

  .text-danger {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
  }

  .discover {
    bottom: 4%;
    left: 50%;
    margin-top: 3rem;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
  }

  .banner-free {
    background-image: url(${free});
    background-position: center;
    background-size: cover;

    padding: 5px 20px;
    line-height: 79px;
    color: #fff;

    text-align: center;
    font-family: 'Quicksand';
    font-size: 27px;
    font-weight: 700;
    line-height: 119.5%;
    display: inline-block;
  }

  @media screen and (max-width: 575px) {
    form > div {
      width: 74%;
    }

    h1 img.circle {
      top: -57px;
      left: 45px;
    }

    .discover {
      font-size: 20px;
    }

    .person1 {
      left: -4px;
      bottom: auto;
      top: 262px;
    }

    .person2 {
      right: -14px;
      bottom: 401px;
    }

    .person3 {
      left: -7px;
      bottom: 360px;
      width: 80px;
    }

    .cube {
      right: -90px;
      bottom: auto;
    }

    .sphere {
      bottom: -130px;
      left: -50px;
      z-index: -1;
      width: 200px;
    }
  }

  @media screen and (min-width: 576px) {
    h1 {
      font-size: 47px;
    }
    h1 img.circle {
      top: -57px;
      left: -35px;
    }

    p {
      font-size: 24px;
    }

    .banner-free {
      font-size: 27px;
      padding: 5px 37px;
    }
    .person1 {
      left: -52px;
      bottom: 498px;
    }

    .person2 {
      right: -26px;
      bottom: 341px;
    }

    .person3 {
      left: -180px;
      bottom: -120px;
      width: auto;
    }

    .cube {
      right: 43px;
      bottom: -304px;
    }

    .sphere {
      bottom: -89px;
      left: -15px;
      z-index: -1;
      width: auto;
    }
  }

  @media screen and (min-width: 769px) {
    h1 img.circle {
      top: -57px;
      left: 47px;
    }

    .person3 {
      left: -310px;
      bottom: -120px;
    }

    .banner-free {
      font-size: 35px;
    }
  }

  @media screen and (min-width: 992px) {
    h1 {
      font-size: 50px;
      margin-top: 30px;
    }
    h1 img.circle {
      top: -57px;
      left: 47px;
    }

    p {
      font-size: 22px;
    }

    form button {
      padding: 25px 28px;
    }

    .person1 {
      left: -30px;
      bottom: 250px;
    }

    .person2 {
      right: 0px;
      bottom: 172px;
    }

    .person3 {
      left: -310px;
      bottom: -120px;
    }

    .cube {
      right: 43px;
      bottom: -304px;
    }

    .sphere {
      bottom: -89px;
      left: -15px;
      z-index: -1;
    }

    .discover {
      zoom: 0.8;
      bottom: 0%;
    }

    .banner-free {
      font-size: 40px;
    }
  }

  @media screen and (min-width: 1200px) {
    .person1 {
      left: 5px;
      bottom: 278px;
    }

    .person2 {
      right: -10px;
      bottom: 176px;
    }

    h1 {
      font-size: 55px;
    }

    p {
      font-size: 25px;
    }

    .banner-free {
      font-size: 50px;
    }
  }

  @media screen and (min-width: 1400px) {
    h1 {
      font-size: 66px;
    }
    h1 img.circle {
      top: -57px;
      left: 111px;
    }

    p {
      font-size: 24px;
    }

    form button {
      padding: 30px 28px;
    }

    .person1 {
      left: -20px;
      bottom: 243px;
    }
    .person2 {
      right: -20px;
      bottom: 180px;
    }

    .banner-free {
      padding: 6px 42px;
      font-size: 60px;
    }

    .discover {
      zoom: 1;
      bottom: 4%;
    }
  }
`;

export const Free = styled.div`
  h2 {
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-size: 60px;
    line-height: 125%;
    color: #000000;
    span {
      font-weight: 800;
      background: linear-gradient(
        270.04deg,
        #fb5a43 0.03%,
        #c341ae 70.44%,
        #9445c2 80.35%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 160%;
    color: #021027;
  }
  .thumb,
  video {
    border: 10px solid #116bff;
    -webkit-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
  }

  .btn-yes {
    font-family: 'Archivo', sans-serif;
    font-size: 34px;
    line-height: 26px;
    -webkit-letter-spacing: -0.04em;
    -moz-letter-spacing: -0.04em;
    -ms-letter-spacing: -0.04em;
    letter-spacing: -0.04em;
    color: #fff;
    background: #116bff;
    border-radius: 20px;
    padding: 28px 45px;
    border: none;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .btn-no {
    color: #ff1a50;

    text-align: center;
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 500;
    border: none;
    background: transparent;
  }

  .thumb {
    width: 100%;
    border-radius: 25px;
  }

  .video-container {
    position: fixed;
    max-width: 500px;
    top: 126px;
    right: 0px;
    padding: 0 20px;
    z-index: 99;
  }

  .btn-mute {
    z-index: 100;
    background: transparent;
    border-radius: 20px;
    left: 0px;
  }

  .mute {
    position: absolute;
    z-index: 100;
    height: 400px;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 8px;
    background: transparent;
    border-radius: 20px;
    border: 1px solid #fff;
    background: rgba(17, 107, 255, 0.8);
    backdrop-filter: blur(5px);
    filter: drop-shadow(0px 4px 40px rgba(17, 107, 255, 0.36));
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 43.5px;
  }

  @media screen and (max-width: 991px) {
    .mute {
      height: 370px;
    }
    video {
      border: 5px solid #116bff;
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 56px;
    }
    h3 {
      font-size: 20px;
    }
    .btn-yes {
      color: #fff;
      text-align: center;
      font-family: 'Archivo';
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -1.36px;
      border-radius: 22px;
      border: 2px solid #fff;
      width: 100%;
      background: #116bff;
      padding: 14px;
    }
    .btn-no {
      font-size: 19px;
    }
    .mute {
      height: 280px;
      width: 290px;
      font-size: 24px;
    }
  }

  @media screen and (max-width: 575px) {
    .mute {
      height: 200px;
      width: 240px;
      font-size: 20px;
      line-height: 25px;
    }
  }
`;

export const PowerAi = styled.div`
  h2 {
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-size: 60px;
    line-height: 125%;
    color: #000000;
    span {
      font-weight: 800;
      background: linear-gradient(270.04deg, #9445c2 0%, #079cfe 55.73%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 160%;
    color: #797979;
  }

  h4 {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #151517;
    padding: 3rem 3rem 0.5rem 3rem;
  }

  p {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #676767;
    padding: 0 3rem;
  }

  img {
    border-radius: 25px;
    -webkit-box-shadow: 0px 87px 97px -21px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 87px 97px -21px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 87px 97px -21px rgba(0, 0, 0, 0.16);
  }

  .box {
    position: absolute;
    width: 57%;
    height: 52%;
    max-height: 235px;
    max-width: 230px;
    background: #fafafa;
    border-radius: 26px;
    z-index: -1;
  }

  .box-left {
    top: -30px;
    left: -30px;
  }

  .box-center {
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }

  .box-right {
    top: -30px;
    right: -30px;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 46px;
    }
    h3 {
      font-size: 18px;
    }
    h4 {
      padding: 2rem 1rem 0.5rem 1rem;
    }

    p {
      padding: 0 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 56px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 24px;
      padding: 3rem 2rem 0.5rem 2rem;
    }
    p {
      font-size: 16px;
      line-height: 28px;
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 575px) {
    .mr-negative {
      margin-right: -1rem;
    }

    .ml-negative {
      margin-left: -1rem;
    }
  }
`;

export const Feeling = styled.div`
  h2 {
    font-family: 'Archivo', sans-serif;

    font-size: 44px;
    line-height: 62px;
    color: #151517;
  }
  h2 span {
    font-weight: 700;
  }

  h3 {
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    line-height: 30px;
    color: #676767;
  }

  h4 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #151517;
  }

  p,
  h6 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #676767;
  }

  h6 {
    color: #151517;
    font-size: 18px;
    span {
      font-weight: 700;
    }
  }

  img {
    position: relative;
    z-index: 1;
    border-radius: 25px;
    -webkit-box-shadow: 0px 87px 97px -21px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 87px 97px -21px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 87px 97px -21px rgba(0, 0, 0, 0.16);
  }

  button {
    font-family: 'Archivo', sans-serif;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: #fff;
    background: #116bff;
    border-radius: 20px;
    padding: 23px 45px;
    border: none;
    transition-duration: 0.3s;

    :hover {
      opacity: 0.8;
    }
  }

  .box {
    position: absolute;
    top: 0;
    right: 0;
    width: 58%;
    height: 62%;
    max-height: 466px;
    max-width: 317px;
    background: #fafafa;
    border-radius: 26px;
    z-index: 0;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 26px;
      line-height: 38px;
    }
    h3 {
      font-size: 12px;
      line-height: 18px;
    }

    h4 {
      font-size: 14px;
      line-height: 18px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
    }

    h6 {
      font-size: 12px;
      line-height: 18px;
    }
    button {
      font-size: 14px;
      padding: 13px 45px;
      border-radius: 12px;
    }
  }

  @media screen and (max-width: 575px) {
    background: #f6f6f6;

    h2 {
      font-size: 40px;
      font-weight: 300;
      line-height: 60px;
      span {
        font-size: 44px;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 30px;
    }

    h4 {
      font-size: 22px;
      margin-bottom: 12px;
    }

    h6 {
      font-size: 24px;
      line-height: 28px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
    }
    button {
      font-size: 24px;
      padding: 25px 27px;
    }

    .box {
      background: #fff;
      top: 20px;
      right: 20px;
    }
  }
`;

export const Honest = styled.div`
  background: rgb(246, 246, 246);
  padding: 4rem 0px;
  h2 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: #151517;
  }
  h2 span {
    font-weight: 700;
    font-size: 48px;
  }

  span.gradient {
    background: linear-gradient(
      270.04deg,
      #fb5a43 0.03%,
      #c341ae 70.44%,
      #9445c2 80.35%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  h3 {
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    line-height: 30px;
    color: #676767;
  }

  h4 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #151517;
  }

  p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #676767;
    span:nth-child(1) {
      color: #116bff;
      font-weight: 600;
    }
  }

  h6 {
    font-family: 'Archivo', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #151517;
  }

  img {
    border-radius: 25px;
    -webkit-box-shadow: 0px 87px 97px -21px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 87px 97px -21px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 87px 97px -21px rgba(0, 0, 0, 0.16);
  }

  button {
    font-family: 'Archivo', sans-serif;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: #fff;
    background: #116bff;
    border-radius: 20px;
    padding: 23px 45px;
    border: none;
    transition-duration: 0.3s;

    :hover {
      opacity: 0.8;
    }
  }

  .box {
    position: absolute;
    top: 0;
    left: 0;
    width: 58%;
    height: 62%;
    max-height: 466px;
    max-width: 317px;
    background: #fff;
    border-radius: 26px;
    z-index: 0;
  }
  .stamp {
    top: 16px;
    right: 16px;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 26px;
      line-height: 38px;
      span {
        font-size: 30px;
      }
    }
    h3 {
      font-size: 12px;
      line-height: 18px;
    }

    h4 {
      font-size: 14px;
      line-height: 18px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
    }

    h6 {
      font-size: 12px;
      line-height: 18px;
    }
    button {
      font-size: 14px;
      padding: 13px 45px;
      border-radius: 12px;
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 38px;
      line-height: 56px;
    }
    h2 span {
      font-size: 46px;
    }

    h3 {
      font-size: 18px;
      line-height: 30px;
    }

    h4 {
      font-size: 22px;
      line-height: 26px;
    }

    h6 {
      font-size: 22px;
      line-height: 26px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
    }

    button {
      font-size: 24px;
      padding: 25px 27px;
    }

    .box {
      top: 10px;
      left: 10px;
    }
  }
`;

export const Members = styled.div`
  h2 {
    font-family: 'Archivo', sans-serif;
    font-size: 60px;
    line-height: 74px;
    color: #151517;
  }

  h3,
  h4 {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    line-height: 30px;
    color: #676767;
  }

  h4 {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #151517;
  }

  p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #676767;
  }

  .box-member {
    max-width: 496px;
    max-height: 430px;
    border-radius: 25px;
    background: #fff;
    position: relative;
    z-index: 3;
    filter: drop-shadow(0px 10px 18px rgba(91, 91, 91, 0.13));
  }

  .member-info img {
    width: 74px;
    height: 74px;
  }

  .member-info {
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      color: #000000;
    }
  }

  .result-text {
    color: #021027;

    font-family: 'Architects Daughter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .member-banner {
    min-width: 63%;
  }

  .member-banner img,
  .member-banner video {
    border-radius: 25px;
    -webkit-box-shadow: 0px 87px 35px -47px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 87px 35px -47px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 87px 35px -47px rgba(0, 0, 0, 0.16);
  }

  .bg-shadow {
    //background: #f6f6f6;
  }

  @media screen and (max-width: 1399px) {
    h4 {
      font-size: 22px;
    }

    p {
      font-size: 14px;
    }
    .member-info img {
      width: 61px;
      height: 61px;
    }
    .box-member {
      max-width: 400px;
    }

    .member-info h3 {
      font-size: 16px;
    }

    .member-info p {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 46px;
      line-height: 45px;
    }
    h3 {
      font-size: 15px;
      line-height: 18px;
    }

    h4 {
      font-size: 18px;
      line-height: 20px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
    }

    .box-member {
      max-width: 300px;
    }

    .member-info img {
      width: 45px;
      height: 45px;
    }

    .member-info h3 {
      font-size: 14px;
      line-height: 16px;
    }

    .member-info p {
      font-size: 12px;
      line-height: 14px;
    }
  }

  @media screen and (max-width: 767px) {
    .box-member {
      max-width: 456px;
    }
  }

  @media screen and (max-width: 575px) {
    background: #f6f6f6;

    h2 {
      font-size: 56px;
      line-height: 74px;
    }

    h3 {
      font-size: 24px;
      line-height: 30px;
    }

    h4 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-size: 15px;
      line-height: 25px;
    }

    .box-member {
      max-width: 350px;
    }

    .member-info {
      h3 {
        font-size: 18px;
        line-height: 14px;
      }
      p {
        font-size: 13px;
      }
    }

    .member-info img {
      width: 50px;
      height: 50px;
    }
  }
`;

export const Questions = styled.div`
  h2 {
    font-family: 'Archivo', sans-serif;
    font-size: 48px;
    color: #242526;
    top: 2rem;
    z-index: 100;
  }

  span {
    font-family: 'Quicksand', sans-serif;
    color: #1f1f1f;
    font-weight: 700;
  }

  .btn-plus {
    top: 0px;
    left: 0px;
  }

  .padding-y {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .btn-see-more {
    height: 46px;
    width: 46px;
  }

  .hover-top:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 20px 20px 0 0;
  }

  .hover-bottom:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 0 0 20px 20px;
  }

  .hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .answer {
    overflow: hidden;
    height: 0px;
    transition-duration: 0.3s;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #585656;
  }

  .answer.active {
    height: 175px;
  }

  .answer button {
    position: relative;
    z-index: 100;
    color: #116bff;
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    :hover {
      text-decoration: underline;
    }
  }

  .bg-answer {
    background-color: rgba(249, 249, 249, 0.6);
  }

  .text-grey {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #151517;
  }

  .border-questions {
    border: solid 1px #e6e7e8;
    border-radius: 20px;
  }

  @media screen and (max-width: 1199px) {
    .answer.active {
      height: 225px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 46px;
    }

    .answer.active {
      height: 265px;
    }
  }

  @media screen and (max-width: 767px) {
    .text-grey {
      font-size: 20px;
    }

    .answer.active {
      height: 310px;
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 40px;
    }

    span {
      font-size: 36px;
    }

    .text-grey {
      font-size: 24px;
    }

    .padding-y {
      padding-top: 1rem;
      padding-bottom: 1.25rem;
    }

    .answer.active {
      height: 550px;
    }

    .answer {
      font-size: 18px;
      line-height: 26px;
    }
  }

  @media screen and (max-width: 390px) {
    .answer.active {
      height: 640px;
    }
  }
`;

export const DontWait = styled.div`
  h2 {
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-size: 60px;
    line-height: 50px;
    color: #000000;
    span:first-child {
      font-weight: 800;
      background: linear-gradient(270.04deg, #9445c2 0%, #079cfe 55.73%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 160%;
    color: #797979;
  }

  button {
    font-family: 'Archivo', sans-serif;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: #fff;
    background: #116bff;
    border-radius: 20px;
    padding: 22px 50px;
    border: none;
    transition-duration: 0.3s;

    :hover {
      opacity: 0.8;
    }
  }

  .bg-discover {
    background: #18191a;
    border-radius: 65px;
  }

  .bg-discover {
    h2,
    h3,
    p {
      font-family: 'Poppins', sans-serif;
    }
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #37ebaa;
    }
    h2 {
      font-weight: 600;
      font-size: 56px;
      line-height: 50px;
      color: #f3f4f8;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #b1b1b5;
    }
  }

  .discover1 {
    top: 5%;
    left: 5%;
  }

  .discover2 {
    top: 42%;
    left: 4%;
  }

  .discover3 {
    top: 73%;
    left: 20%;
  }

  .discover4 {
    top: 5%;
    right: 5%;
  }

  .discover5 {
    top: 35%;
    right: 3%;
  }

  .discover6 {
    top: 67%;
    right: 10%;
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-weight: 300;
      font-size: 38px;
      span:first-child {
        font-size: 56px;
      }
      span:nth-child(2) {
        font-weight: 500;
        font-size: 53px;
      }
    }

    h3 {
      font-size: 22px;
    }
    button {
      font-size: 24px;
      padding: 22px 69px;
    }

    .bg-discover h2 {
      font-size: 46px;
      line-height: 60px;
    }

    .bg-discover p {
      font-size: 18px;
      line-height: 32px;
    }

    .discover1 {
      top: 8%;
      left: 5%;
      width: 130px;
    }

    .discover4 {
      top: 2%;
      right: 2%;
    }

    .discover5 {
      top: 67%;
      right: 0%;
      width: 172px;
    }

    .discover6 {
      top: 51%;
      right: auto;
      left: 2%;
      width: 130px;
    }
  }

  @media screen and (min-width: 576px) {
    h2 {
      font-weight: 300;
      font-size: 38px;
      span:first-child {
        font-size: 56px;
      }
      span:nth-child(2) {
        font-weight: 500;
        font-size: 53px;
      }
    }

    h3 {
      font-size: 22px;
    }

    .bg-discover h2 {
      font-size: 40px;
    }

    .discover3 {
      left: 7%;
    }
  }

  @media screen and (min-width: 768px) {
    button {
      padding: 22px 82px;
    }

    .disclaimer {
      border: none;
    }
  }

  @media screen and (min-width: 992px) {
    h2 {
      font-weight: 500;
      font-size: 53px;
      line-height: 65px;
    }
  }

  @media screen and (min-width: 1400px) {
    .bg-discover h2 {
      font-size: 56px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  background: #00000055;

  h2 {
    color: #116bff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 36px;
    font-weight: 700;
    line-height: 38px;
    margin: 10px 0px;
  }

  h3 {
    color: #116bff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }

  p {
    color: #ff1a50;
    margin: 15px 0px;
    text-align: center;
    font-family: 'Architects Daughter';
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
  }

  input {
    height: 50px;
    color: #021027;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 134.023%;

    ::placeholder {
      color: #ababab;
    }
  }

  .bd-input {
    border-radius: 18px;
    border: 1px solid #dedede;
    background: #fff;
    height: 65px;
    padding: 5px 25px;
  }

  .modal-content {
    border: 7px solid #116bff !important;
    box-shadow: 0px 4px 90px 0px rgba(17, 107, 255, 0.5);
  }

  .body {
    padding: 0rem 40px 1rem 40px;
  }

  .input-button {
    background: transparent !important;
    border: none !important;
    height: 30px !important;
    top: 17px !important;
    left: 17px !important;
    transform: scale(1.5) !important;
  }

  .btn-submit {
    width: 100%;
    border-radius: 18px;
    background: #116bff;
    color: #fff;
    font-family: 'Archivo';
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    transition-duration: 0.2s;
    :hover {
      opacity: 0.8;
    }
  }

  .modal-content {
    border-radius: 25px;
    background: #fffdfd;
    border: none;
  }

  .progress {
    height: 100%;
    padding: 0px;
    background: transparent;
    border-radius: 18px;
    border: 1px solid #dedede;
  }

  .progress-bar {
    font-size: 18px;
    font-weight: 700;
    height: 55px;
    border-radius: 18px 0 0 18px;
    transition: 0.4s linear;
    transition-property: width, background-color;
    span {
      font-family: 'Inter';
      text-align: left;
      padding-left: 15px;
      position: relative;
      z-index: 10;
    }
  }

  .progress-striped .progress-bar {
    overflow: hidden;
    background-color: rgba(17, 107, 255, 1);
    width: 80%;
    animation: progressAnimationStrike 3s;
  }

  .progress-animation {
    height: 55px;
    width: 1000px;
    background-image: linear-gradient(
      135deg,
      rgb(20, 80, 245) 5%,
      transparent 5%,
      transparent 10%,
      rgb(20, 80, 245) 10%,
      rgb(20, 80, 245) 15%,
      transparent 15%,
      transparent 20%,
      rgb(20, 80, 245) 20%,
      rgb(20, 80, 245) 25%,
      transparent 25%,
      transparent 30%,
      rgb(20, 80, 245) 30%,
      rgb(20, 80, 245) 35%,
      transparent 35%,
      transparent 40%,
      rgb(20, 80, 245) 40%,
      rgb(20, 80, 245) 45%,
      transparent 45%,
      transparent 50%,
      rgb(20, 80, 245) 50%,
      rgb(20, 80, 245) 55%,
      transparent 55%,
      transparent 60%,
      rgb(20, 80, 245) 60%,
      rgb(20, 80, 245) 65%,
      transparent 65%,
      transparent 70%,
      rgb(20, 80, 245) 70%,
      rgb(20, 80, 245) 75%,
      transparent 75%,
      transparent 80%,
      rgb(20, 80, 245) 80%,
      rgb(20, 80, 245) 85%,
      transparent 85%,
      transparent 90%,
      rgb(20, 80, 245) 90%,
      rgb(20, 80, 245) 95%,
      transparent 95%,
      transparent
    );
    animation: progressAnimationBar 2s infinite;
  }

  @keyframes progressAnimationStrike {
    from {
      width: 0;
    }
    to {
      width: 80%;
    }
  }

  @keyframes progressAnimationBar {
    from {
      right: -500px;
    }
    to {
      right: 0px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 34px;
      color: #ff1a50;
    }
    h3 {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 32px;
    }
    .body {
      padding: 0rem 25px 1rem 25px;
    }

    .modal-content {
      border: 4px solid #116bff !important;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 23px;
      line-height: 30px;
      margin: 26px 0px;
    }
    h3 {
      font-size: 22px;
    }
  }
`;

export const Loading = styled.span`
  //margin-top: 0px !important;
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  border-radius: 20px;
  background-color: #116bff;
  width: 207px !important; */

  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #fff 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #fff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
