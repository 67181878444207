import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FaExclamationCircle } from 'react-icons/fa';
import Lottie from 'react-lottie';
import { BsDash, BsPlus } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';

import { ScrollParallax } from 'react-just-parallax';
import { Helmet } from 'react-helmet';
import { useResize } from '~/hooks/Resize';

import {
  Container,
  Attention,
  Gpt,
  Converting,
  Breakthrough,
  Perfect,
  Easy,
  Unlimited,
  All,
  Bonus,
  Questions,
  Current,
  Modal,
} from './styles';
import Player from '~/components/Player';
import Payment from '~/components/Payment';

import thumbFree from '~/assets/defaults/thumb-free.webp';
import thumbHand from '~/assets/defaults/thumb-hand.png';
import papperTop from '~/assets/defaults/papper-bonus.png';
import trafficIcon from '~/assets/defaults/traffic-icon.png';
import mastering from '~/assets/defaults/traffic-text.svg';
import cards from '~/assets/defaults/cards.png';
import getAll from '~/assets/defaults/get-all.png';
import moneyBack from '~/assets/defaults/money-back.png';
import messages1 from '~/assets/defaults/messages_1.png';
import messages2 from '~/assets/defaults/messages_2.png';
import devices from '~/assets/defaults/devices-guide.png';
import devicesMob from '~/assets/defaults/devices-guide-mob-new.png';
import table1 from '~/assets/defaults/table-1.png';
import table1Mob from '~/assets/defaults/table-1-mob.png';
import table2 from '~/assets/defaults/table-2.png';
import table2Mob from '~/assets/defaults/table-2-mob.png';
import powerful from '~/assets/defaults/powerful.png';
import all1 from '~/assets/defaults/all-1.png';
import all2 from '~/assets/defaults/all-2.png';
import all3 from '~/assets/defaults/all-3.png';
import all4 from '~/assets/defaults/all-4.png';
import all5 from '~/assets/defaults/all-5.png';
import all6 from '~/assets/defaults/all-6.png';
import all7 from '~/assets/defaults/all-7.png';
import all8 from '~/assets/defaults/all-8.png';
import all9 from '~/assets/defaults/all-9.png';
import all10 from '~/assets/defaults/all-10.png';
import all11 from '~/assets/defaults/all-11.png';
import all12 from '~/assets/defaults/all-12.png';
import all13 from '~/assets/defaults/all-13.png';
import all14 from '~/assets/defaults/all-14.png';
import all15 from '~/assets/defaults/all-15.png';
import all16 from '~/assets/defaults/all-16.png';
import all17 from '~/assets/defaults/all-17.png';
import all18 from '~/assets/defaults/all-18.png';
import all19 from '~/assets/defaults/all-19.png';
import all20 from '~/assets/defaults/all-20.png';
import all21 from '~/assets/defaults/all-21.png';
import all22 from '~/assets/defaults/all-22.png';
import all23 from '~/assets/defaults/all-23.png';
import all24 from '~/assets/defaults/all-24.png';
import all25 from '~/assets/defaults/all-25.png';
import bonus1 from '~/assets/defaults/bonus-1.png';
import bonus2 from '~/assets/defaults/bonus-2.png';
import bonus3 from '~/assets/defaults/bonus-3.png';
import current from '~/assets/defaults/current.png';
import circle from '~/assets/defaults/circle-title-ultimate.png';
import hand from '~/assets/defaults/device-guide-hero.png';
import checkBlue from '~/assets/animations/check-blue.json';
import checkGreen from '~/assets/animations/check-green.json';
import btnDownload from '~/assets/animations/btn-download.json';
import ArrowDown from '~/assets/animations/arrow-down.json';
import ArrowYellow from '~/assets/animations/arrow-yellow.json';
import plus from '~/assets/animations/plus.json';
import chatGptMastery from '~/assets/defaults/chat-gpt-mastery-new.png';
import muteImg from '~/assets/icons/mute.svg';
import waves from '~/assets/animations/waves-sound.json';
import ModalEnterEmail from '~/components/ModalEnterEmail';
import Logos from './Logos';

import facebook from '~/assets/icons/facebook-hero.svg';
import instagram from '~/assets/icons/instagram-hero.svg';
import x from '~/assets/icons/x-hero.svg';
import youtube from '~/assets/icons/youtube-hero.svg';
import threads from '~/assets/icons/threads-hero.svg';
import avatar1 from '~/assets/defaults/image-2.png';
import avatar2 from '~/assets/defaults/image-3.png';
import avatar3 from '~/assets/defaults/image-4.png';
import robot from '~/assets/defaults/robot.png';
import robotMob from '~/assets/defaults/robot-mobile1.png';
import kathy from '~/assets/defaults/kathy.png';
import tee from '~/assets/defaults/tee-testemonial.png';
import randy from '~/assets/defaults/randy-testemonial.png';
import danielle from '~/assets/defaults/danielle-testemonials.png';

import quote from '~/assets/defaults/quote-testemonials.svg';

import DisclaimerNew from '~/components/DisclaimerNew';
import Logo from '~/components/Logo';

interface ILocation {
  from: Location;
}

const Agency: React.FC = () => {
  const history = useHistory();
  const location = useLocation<ILocation>();
  const { width } = useResize();
  const [answer, setAnswer] = useState('');
  const [show, setShow] = useState(false);
  const [mute, setMute] = useState(true);
  const [showModalNo, setShowModalNo] = useState(false);
  const [video, setVideo] = useState('');

  const handleClickQuestion = useCallback(
    (data) => {
      if (answer === data) {
        setAnswer('');
      } else {
        setAnswer(data);
      }
    },
    [answer]
  );

  const handleClick = useCallback(() => {
    console.log('click');
  }, []);

  return (
    <>
      <Helmet>
        <title>
          AI Content Mastery: The Ultimate Guide to Revolutionize Your Social
          Media
        </title>
        <meta
          name="title"
          content="AI Content Mastery: The Ultimate Guide to Revolutionize Your Social Media"
        />
        <meta
          name="description"
          content="AI Content Mastery: The Ultimate Guide to Revolutionize Your Social Media"
        />
        <meta
          property="og:url"
          content="https://autoaffiliate.ai/ultimate-guide"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AUTOAFFILIATE" />
        <meta
          property="og:description"
          content="Supercharge your social media with AI! Our Ultimate Guide offers expert prompts and AI insights to revolutionize your content creation. Download now to transform your strategy!"
        />
        <meta
          property="og:image"
          content="https://cdn.autoaffiliate.ai/files/metatag-ultimate-guide.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="autoaffiliate.ai" />
        <meta
          property="twitter:url"
          content="https://autoaffiliate.ai/ultimate-guide"
        />
        <meta name="twitter:title" content="AUTOAFFILIATE" />
        <meta
          name="twitter:description"
          content="Supercharge your social media with AI! Our Ultimate Guide offers expert prompts and AI insights to revolutionize your content creation. Download now to transform your strategy!"
        />
      </Helmet>
      <Container>
        <Gpt>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-12 px-lg-5">
                <h1 className="text-center mt-5 mb-4">
                  <span className="cl-blue">Grow</span> Your Agency{' '}
                  <span className="cl-blue">Revenue</span> with{' '}
                  <span className="cl-blue">Effortless</span> Social Media
                  Automation
                </h1>
                <h3 className="text-center px-lg-5 mb-5">
                  Offer Done-for-You Social Media Services to Clients—
                  <b className="fw-bold">We Do the Work,</b>{' '}
                  <b className="fw-bold cl-blue fst-italic">
                    You Enjoy the Profits!
                  </b>
                </h3>
              </div>
              <div className="col-lg-8">
                <div className="mb-5 mb-lg-4">
                  <button
                    type="button"
                    className="w-100 btn-download-hero"
                    onClick={handleClick}
                  >
                    <Lottie
                      options={{
                        animationData: btnDownload,
                        autoplay: true,
                        loop: true,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      height={90}
                      width={90}
                    />
                    <span>Book A Call To Claim Your Free 7 Days Trial</span>
                  </button>
                </div>
                <h4 className="text-center mb-5">
                  <span className="fw-bold">
                    This is For Agencies Looking to Scale:
                  </span>{' '}
                  Expand your service offerings, drive more revenue, and delight
                  clients without adding extra work
                </h4>
              </div>
            </div>
          </div>
        </Gpt>

        <Converting>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-lg-6 pe-lg-4">
                <h2 className="mb-4">Here is why...</h2>
                <div className="bd-left ps-5 pe-5">
                  <p>
                    Managing client social media is{' '}
                    <span className="fw-medium">time-consuming</span> and{' '}
                    <span className="fw-bold fst-italic cl-red">
                      labor-intensive, limiting your agency’s growth.
                    </span>
                  </p>
                  <p className="mb-0">
                    Imagine if you could offer complete social media management
                    to clients with no extra workload—giving you more time to
                    focus on what truly grows your business.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 ps-lg-4">
                <h2 className="mb-4 cl-blue">But here is the deal...</h2>
                <div className="bd-right ps-5 pe-4">
                  <p>
                    <span className="fw-semibold text-white">
                      We handle everything:
                    </span>{' '}
                    Providing your clients with expertly crafted,{' '}
                    <span className="fw-bold fst-italic cl-blue">
                      branded content that’s posted daily, tailored to each
                      business
                    </span>
                    , driving growth & engagement.
                  </p>
                  <p className="mb-0">
                    <span className="fw-semibold text-white">We help you</span>{' '}
                    add high-value social media services to your portfolio,{' '}
                    <span className="fw-bold fst-italic cl-blue">
                      increasing revenue without needing additional staff or
                      tools.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Converting>

        <Logos className="mb-5" />

        <Perfect>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="my-5 pt-5 px-4 px-md-0">
                  Try PostEASE <span className="cl-blue">Free For 7 Days</span>{' '}
                  & Discover the Revenue Boost
                </h2>
              </div>
              <div className="col-lg-6">
                <div className="mb-5 mb-lg-4">
                  <button
                    type="button"
                    className="w-100 btn-download-hero ps-3"
                    onClick={handleClick}
                  >
                    <Lottie
                      options={{
                        animationData: btnDownload,
                        autoplay: true,
                        loop: true,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      height={90}
                      width={90}
                    />
                    <span>Book Your Free Setup Call Now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Perfect>

        <Unlimited>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-12 mt-5 pt-5">
                <h2 className="mb-3 pt-5">SEE WHY BUSINESS OWNERS TRUST US</h2>
                <h3>To handle their social presence.</h3>
              </div>
              <div className="col-lg-11 px-lg-5 mt-5 pt-5">
                <div className="bd-testemonials">
                  <img src={quote} alt="Quote" className="quote" />
                  <svg width="72" height="72" viewBox="0 0 72 72">
                    <path
                      fill="#ffffff"
                      stroke="#FFC876"
                      strokeWidth="2"
                      strokeOpacity="1"
                      d="M-1176,1 L6.15,1 C13.89,1 21.35,3.89547 27.06,9.11714 L60.91,40.0541 C67.34,45.9271 71,54.2315 71,62.937 L71,444 C71,461.121 57.12,475 40,475 L-1176,475 C-1193.1209,475 -1207,461.121 -1207,444 L-1207,32 C-1207,14.8792 -1193.1208,1 -1176,1 Z"
                    />
                  </svg>
                  <div className="d-flex align-items-center">
                    <p>
                      PostEASE has been a game changer for my social media. It
                      takes care of everything, automatically. I don’t have to
                      worry about scheduling post or managing my content
                      manually.
                    </p>
                    <div>
                      <img src={kathy} alt="Avatar" className="avatar" />
                      <span className="d-block mt-4">
                        Kathy J.
                        <br />
                        <span>Digital Marketer</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 px-lg-5 mt-5 pt-5">
                <div className="bd-testemonials mt-5">
                  <img src={quote} alt="Quote" className="quote" />
                  <svg width="72" height="72" viewBox="0 0 72 72">
                    <path
                      fill="#ffffff"
                      stroke="#FFC876"
                      strokeWidth="2"
                      strokeOpacity="1"
                      d="M-1176,1 L6.15,1 C13.89,1 21.35,3.89547 27.06,9.11714 L60.91,40.0541 C67.34,45.9271 71,54.2315 71,62.937 L71,444 C71,461.121 57.12,475 40,475 L-1176,475 C-1193.1209,475 -1207,461.121 -1207,444 L-1207,32 C-1207,14.8792 -1193.1208,1 -1176,1 Z"
                    />
                  </svg>
                  <div className="d-flex align-items-center">
                    <p>
                      I was skeptical about letting AI handle our social media,
                      but PostEASE blew me away! The quality of the posts, the
                      timing—it's all spot-on. My social media has never been
                      this active or effective.
                    </p>
                    <div>
                      <img src={tee} alt="Avatar" className="avatar" />
                      <span className="d-block mt-4">
                        Tee H.
                        <br />
                        <span>Travel Agency</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 px-lg-5 mt-5 pt-5">
                <div className="bd-testemonials mt-5">
                  <img src={quote} alt="Quote" className="quote" />
                  <svg width="72" height="72" viewBox="0 0 72 72">
                    <path
                      fill="#ffffff"
                      stroke="#FFC876"
                      strokeWidth="2"
                      strokeOpacity="1"
                      d="M-1176,1 L6.15,1 C13.89,1 21.35,3.89547 27.06,9.11714 L60.91,40.0541 C67.34,45.9271 71,54.2315 71,62.937 L71,444 C71,461.121 57.12,475 40,475 L-1176,475 C-1193.1209,475 -1207,461.121 -1207,444 L-1207,32 C-1207,14.8792 -1193.1208,1 -1176,1 Z"
                    />
                  </svg>
                  <div className="d-flex align-items-center">
                    <p>
                      The best investment I've made this year? Definitely
                      PostEASE. It’s not just about posting content, it’s about
                      posting the right content at the right time. My engagement
                      rates have doubled since I started using it!
                    </p>
                    <div>
                      <img src={randy} alt="Avatar" className="avatar" />
                      <span className="d-block mt-4">
                        Randy M.
                        <br />
                        <span>Software Developer</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 px-lg-5 mt-5 pt-5">
                <div className="bd-testemonials mt-5">
                  <img src={quote} alt="Quote" className="quote" />
                  <svg width="72" height="72" viewBox="0 0 72 72">
                    <path
                      fill="#ffffff"
                      stroke="#FFC876"
                      strokeWidth="2"
                      strokeOpacity="1"
                      d="M-1176,1 L6.15,1 C13.89,1 21.35,3.89547 27.06,9.11714 L60.91,40.0541 C67.34,45.9271 71,54.2315 71,62.937 L71,444 C71,461.121 57.12,475 40,475 L-1176,475 C-1193.1209,475 -1207,461.121 -1207,444 L-1207,32 C-1207,14.8792 -1193.1208,1 -1176,1 Z"
                    />
                  </svg>
                  <div className="d-flex align-items-center">
                    <p>
                      I was skeptical about PostEase at first, but decided to
                      give it a shot. It’s like having a tireless personal
                      assistant for social media. The time saved is invaluable,
                      and my engagement rates have soared. If you’re on the
                      fence, just dive in. PostEase is the real deal—this is one
                      risk you won’t regret.
                    </p>
                    <div>
                      <img src={danielle} alt="Avatar" className="avatar" />
                      <span className="d-block mt-4">
                        Danielle D.
                        <br />
                        <span>Business Owner</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Unlimited>

        <Perfect>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-lg-10 mt-5 pt-5">
                <h2 className="my-5 pt-5 px-4 px-md-0">
                  But Don’t Take Our Word
                  <br /> For It, Or Theirs...
                </h2>
                <h3>
                  WE BELIEVE IN RESULTS FIRST. TRY 7 DAYS OF CUSTOM SOCIAL MEDIA
                  CONTENT FOR YOUR AGENCY,{' '}
                  <span className="fw-bold">
                    COMPLETELY FREE. NO COMMITMENT, NO HASSLE.
                  </span>
                </h3>
                <p className="mt-4 mb-5">
                  Make your own conclusions, you have nothing to loose and
                  everything to gain.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="mb-5 mb-lg-4">
                  <button
                    type="button"
                    className="w-100 btn-download-hero ps-3"
                    onClick={handleClick}
                  >
                    <Lottie
                      options={{
                        animationData: btnDownload,
                        autoplay: true,
                        loop: true,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      height={90}
                      width={90}
                    />
                    <span>Book Your Free Setup Call Now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-imagine">
            <div className="container zoom">
              <div className="row justify-content-center">
                <div className="col-lg-11">
                  <h4>
                    Imagine offering your clients daily, high-quality social
                    media content with zero effort from your team.{' '}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </Perfect>

        <Easy>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="my-5 pt-5 text-center">
                  <span className="fw-bold fst-italic cl-blue">
                    Start your free trial today
                  </span>{' '}
                  to see how PostEASE can{' '}
                  <span className="fw-bold">
                    transform your agency’s revenue.
                  </span>
                </h2>
              </div>
              <div className="col-lg-6">
                <div className="mt-5 mb-5 mb-lg-4">
                  <button
                    type="button"
                    className="w-100 btn-download-hero ps-3"
                    onClick={handleClick}
                  >
                    <Lottie
                      options={{
                        animationData: btnDownload,
                        autoplay: true,
                        loop: true,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      height={90}
                      width={90}
                    />
                    <span>Book Your Free Setup Call Now</span>
                  </button>
                </div>
              </div>
              <div className="col-lg-10">
                <h3 className="my-5 text-center">
                  <span className="fw-medium cl-blue">
                    Try us out, risk-free.
                  </span>{' '}
                  No strings attached, just results.
                </h3>
              </div>
            </div>
          </div>
        </Easy>

        <Questions>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-lg-10 mt-5 pt-5">
                <h2 className=" pt-5">Frequently Asked Questions...</h2>
              </div>
              <div className="col-11 my-sm-5 border-questions">
                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover-top ${
                    answer === 'question_1' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_1')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">01</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h5-sm h4-xl mb-0 text-grey">
                      How does PostEASE help my agency grow?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_1' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_1' ? 'active mt-4' : ''
                      }`}
                    >
                      PostEASE allows you to expand your offerings with fully
                      managed social media services, freeing up your team while
                      increasing revenue.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_2' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_2')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">02</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h6 h5-sm h4-xl mb-0 text-grey ">
                      Can content be customized for each client?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_2' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_2' ? 'active mt-4' : ''
                      }`}
                    >
                      Yes, we tailor each client’s content to fit their unique
                      brand voice and goals, ensuring consistent, high-quality
                      engagement.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_3' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_3')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">03</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h5-sm h4-xl mb-0 text-grey">
                      How much time will we save?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_3' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_3' ? 'active mt-4' : ''
                      }`}
                    >
                      Your team saves hours per client each week, freeing up
                      time for acquisition and strategy.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_4' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_4')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">04</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h6 h5-sm h4-xl mb-0 text-grey ">
                      Why is the 7-day trial free?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_4' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_4' ? 'active mt-4' : ''
                      }`}
                    >
                      We believe you’ll see the value quickly. Our trial lets
                      you experience the results risk-free.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_5' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_5')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">05</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h5-sm h4-xl mb-0 text-grey">
                      How many clients can we manage with PostEASE?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_5' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_5' ? 'active mt-4' : ''
                      }`}
                    >
                      PostEASE scales seamlessly, allowing you to manage
                      multiple clients without adding workload.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_6' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_6')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">06</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h6 h5-sm h4-xl mb-0 text-grey ">
                      What platforms does PostEASE cover?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_6' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_6' ? 'active mt-4' : ''
                      }`}
                    >
                      PostEASE integrates with major platforms like Instagram,
                      Facebook, LinkedIn, and more, so your clients are visible
                      where it matters.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 hover-bottom ${
                    answer === 'question_7' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_7')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">07</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 text-center text-sm-start my-4 my-sm-0">
                    <p className="h5-sm h4-xl mb-0 text-grey">
                      What happens after the free trial?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0 px-xl-3">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_7' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_7' ? 'active mt-4' : ''
                      }`}
                    >
                      After the trial, choose a plan that best fits your
                      agency’s goals and client needs. Our goal is to help you
                      scale profitably.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>
              </div>
            </div>
          </div>
        </Questions>

        <Current>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="mb-5 mb-lg-4">
                  <button
                    type="button"
                    className="w-100 btn-download-hero mt-5"
                    onClick={handleClick}
                  >
                    <Lottie
                      options={{
                        animationData: btnDownload,
                        autoplay: true,
                        loop: true,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      height={90}
                      width={90}
                    />
                    <span>Book A Call To Claim Your Free 7 Days Trial</span>
                  </button>
                </div>
              </div>

              <div className="col-sm-11 my-5 py-5">
                <DisclaimerNew />
              </div>
              <div className="col-lg-11 mb-5 pb-5">
                <Logo />
              </div>
            </div>
          </div>
        </Current>
      </Container>
    </>
  );
};

export default Agency;
