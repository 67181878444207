import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import free from '~/assets/banners/banner-free-new.png';
import bannerNew from '~/assets/banners/banner-lp.png';

export const Container = styled.div`
  font-family: 'Quicksand', sans-serif;

  button.modal-works {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #116bff;
    font-size: 28px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    line-height: 45px;
    color: #fff;
    border: none;
    padding: 5px;
    z-index: 10;
    transition: all 1s ease;

    span {
      margin: -13px 2rem;
    }
  }

  .zoom {
    zoom: 0.6;
  }

  .banner-group {
    min-height: 1080px;
    background-image: url(${bannerNew});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -505px;
    margin-bottom: -620px;
  }

  @media screen and (max-width: 991px) {
    button.modal-works {
      font-size: 16px;
      line-height: 29px;
    }
  }

  @media screen and (max-width: 575px) {
    overflow-x: hidden;
    .container {
      max-width: 400px;
    }

    button.modal-works {
      span {
        margin: -13px 4rem -13px 0rem;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .zoom {
      zoom: 0.8;
    }

    .active {
      top: -100px;
    }

    .not-active {
      top: 0px;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }

  @media screen and (min-width: 1700px) {
    .banner-group {
      background-position: bottom;
    }
  }
`;

export const Attention = styled.div`
  background: #ff1a50;
  box-shadow: 0px 4px 4px rgba(255, 26, 80, 0.15);

  h2 {
    font-weight: 400;
    font-family: 'Inter';
  }

  svg {
    height: 30px;
    width: 30px;
  }

  @media screen and (max-width: 991px) {
    svg {
      height: 25px;
      width: 25px;
    }
  }
`;

export const Join = styled.div`
  h1 {
    font-weight: 400;
    font-size: 35px;

    line-height: 119.5%;
    color: #000;
    margin-top: 90px;

    img.circle {
      top: -57px;
      left: 30px;
    }
  }

  p {
    color: #021027;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 20px;
    font-weight: 400;
    line-height: 119.5%;
  }

  .master {
    font-weight: 600;
    color: #116bff;
  }

  .fw-semibold {
    font-weight: 600 !important;
  }

  .archivo {
    font-family: 'Archivo';
  }

  form {
    background: #fafafa;
    border: 1px solid #116bff;
    border-radius: 24px;
    img {
      min-width: auto;
    }
    > div {
      border: none;
      width: 49%;
    }

    input {
      font-family: 'Archivo', sans-serif;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: -0.025em;
      color: #151517;
    }

    input::placeholder {
      font-weight: 300 !important;
      font-size: 16px;
      line-height: 24px;
      color: #b0b0b0;
    }

    button {
      font-family: 'Archivo', sans-serif;
      font-size: 24px;
      line-height: 110%;
      letter-spacing: -0.04em;
      color: #fff;
      background: #116bff;
      border-radius: 20px;
      padding: 17px 28px;
      border: none;
      transition-duration: 0.3s;
      font-weight: 500;

      :hover {
        opacity: 0.8;
      }
    }
  }

  .text-danger {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
  }

  .discover {
    bottom: 4%;
    left: 50%;
    margin-top: 3rem;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
  }

  .banner-free {
    background-image: url(${free});
    background-position: center;
    background-size: cover;

    padding: 5px 20px;
    line-height: 79px;
    color: #fff;

    text-align: center;
    font-family: 'Quicksand';
    font-size: 27px;
    font-weight: 700;
    line-height: 119.5%;
    display: inline-block;
  }

  @media screen and (max-width: 575px) {
    form > div {
      width: 74%;
    }

    h1 img.circle {
      top: -57px;
      left: 45px;
    }

    .discover {
      font-size: 20px;
    }

    .person1 {
      left: -4px;
      bottom: auto;
      top: 262px;
    }

    .person2 {
      right: -14px;
      bottom: 401px;
    }

    .person3 {
      left: -7px;
      bottom: 360px;
      width: 80px;
    }

    .cube {
      right: -90px;
      bottom: auto;
    }

    .sphere {
      bottom: -130px;
      left: -50px;
      z-index: -1;
      width: 200px;
    }
  }

  @media screen and (min-width: 576px) {
    h1 {
      font-size: 47px;
    }
    h1 img.circle {
      top: -57px;
      left: -35px;
    }

    p {
      font-size: 24px;
    }

    .banner-free {
      font-size: 27px;
      padding: 5px 37px;
    }
    .person1 {
      left: -52px;
      bottom: 498px;
    }

    .person2 {
      right: -26px;
      bottom: 341px;
    }

    .person3 {
      left: -180px;
      bottom: -120px;
      width: auto;
    }

    .cube {
      right: 43px;
      bottom: -304px;
    }

    .sphere {
      bottom: -89px;
      left: -15px;
      z-index: -1;
      width: auto;
    }
  }

  @media screen and (min-width: 769px) {
    h1 img.circle {
      top: -57px;
      left: 47px;
    }

    .person3 {
      left: -310px;
      bottom: -120px;
    }

    .banner-free {
      font-size: 35px;
    }
  }

  @media screen and (min-width: 992px) {
    h1 {
      font-size: 50px;
      margin-top: 30px;
    }
    h1 img.circle {
      top: -57px;
      left: 47px;
    }

    p {
      font-size: 22px;
    }

    form button {
      padding: 25px 28px;
    }

    .person1 {
      left: -30px;
      bottom: 250px;
    }

    .person2 {
      right: 0px;
      bottom: 172px;
    }

    .person3 {
      left: -310px;
      bottom: -120px;
    }

    .cube {
      right: 43px;
      bottom: -304px;
    }

    .sphere {
      bottom: -89px;
      left: -15px;
      z-index: -1;
    }

    .discover {
      zoom: 0.8;
      bottom: 0%;
    }

    .banner-free {
      font-size: 40px;
    }
  }

  @media screen and (min-width: 1200px) {
    .person1 {
      left: 5px;
      bottom: 278px;
    }

    .person2 {
      right: -10px;
      bottom: 176px;
    }

    h1 {
      font-size: 55px;
    }

    p {
      font-size: 25px;
    }

    .banner-free {
      font-size: 50px;
    }
  }

  @media screen and (min-width: 1400px) {
    h1 {
      font-size: 66px;
    }
    h1 img.circle {
      top: -57px;
      left: 111px;
    }

    p {
      font-size: 24px;
    }

    form button {
      padding: 30px 28px;
    }

    .person1 {
      left: -20px;
      bottom: 243px;
    }
    .person2 {
      right: -20px;
      bottom: 180px;
    }

    .banner-free {
      padding: 6px 42px;
      font-size: 60px;
    }

    .discover {
      zoom: 1;
      bottom: 4%;
    }
  }
`;

export const Free = styled.div`
  h2 {
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-size: 60px;
    line-height: 125%;
    color: #000000;
    span {
      font-weight: 800;
      background: linear-gradient(
        270.04deg,
        #fb5a43 0.03%,
        #c341ae 70.44%,
        #9445c2 80.35%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 160%;
    color: #021027;
  }
  .thumb,
  video {
    border: 10px solid #116bff;
    -webkit-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
  }

  .btn-yes {
    font-family: 'Archivo', sans-serif;
    font-size: 24px;
    line-height: 26px;
    -webkit-letter-spacing: -0.04em;
    -moz-letter-spacing: -0.04em;
    -ms-letter-spacing: -0.04em;
    letter-spacing: -0.04em;
    color: #fff;
    background: #116bff;
    border-radius: 20px;
    padding: 28px 45px;
    border: none;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .btn-no {
    color: #ff1a50;
    text-align: center;
    font-family: 'Archivo';
    font-size: 20px;
    font-weight: 700;
  }

  .thumb {
    width: 100%;
    border-radius: 25px;
  }

  .video-container {
    position: fixed;
    max-width: 500px;
    top: 126px;
    right: 0px;
    padding: 0 20px;
    z-index: 99;
  }

  .btn-yes {
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    letter-spacing: -1.36px;
    border-radius: 22px;
    border: 2px solid #fff;
    width: 100%;
    background: #116bff;
  }

  .btn-mute {
    z-index: 100;
    background: transparent;
    border-radius: 20px;
    left: 0px;
  }

  .mute {
    position: absolute;
    z-index: 100;
    height: 400px;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 8px;
    background: transparent;
    border-radius: 20px;
    border: 1px solid #fff;
    background: rgba(17, 107, 255, 0.8);
    backdrop-filter: blur(5px);
    filter: drop-shadow(0px 4px 40px rgba(17, 107, 255, 0.36));
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 43.5px;
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 56px;
    }
    h3 {
      font-size: 20px;
    }
  }
`;

export const DontWait = styled.div`
  .fw-500 {
    font-weight: 500;
  }
  .terms {
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    line-height: 164.5%;
    letter-spacing: 0.64px;
  }
`;
