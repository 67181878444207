/* eslint-disable no-console */
import React, {
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
  useRef,
  useMemo,
} from 'react';
import Lottie from 'react-lottie';
import { Form } from '@unform/web';
import { FaExclamationCircle } from 'react-icons/fa';
import { BsDash, BsPlus } from 'react-icons/bs';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import Fade from 'react-reveal/Fade';

import { Link, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';

import { Attention, Container, DontWait, Free, Join } from './styles';

import Input from '~/components/Input';
import Player from '~/components/Player';

import swalError from '~/utils/swalError';
import discover1 from '~/assets/defaults/discover1.png';
import discover2 from '~/assets/defaults/discover2.png';
import discover3 from '~/assets/defaults/discover3.png';
import discover4 from '~/assets/defaults/discover4.png';
import discover5 from '~/assets/defaults/discover5.png';
import discover6 from '~/assets/defaults/discover6.png';
import thumbFree from '~/assets/defaults/thumb-free.webp';
import ai from '~/assets/defaults/ai.png';
import automated from '~/assets/defaults/automated.png';
import advanced from '~/assets/defaults/advanced.png';
import family from '~/assets/defaults/feeling.png';
import street from '~/assets/defaults/person-street.png';
import stamp from '~/assets/defaults/stamp.svg';

import arrow from '~/assets/icons/arrow.svg';
import muteImg from '~/assets/icons/mute.svg';
import waves from '~/assets/animations/waves-sound.json';

import Carousel from '~/components/Carousel';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import Disclaimer from '~/components/Disclaimer';
import { useResize } from '~/hooks/Resize';
import ModalSaveMoney from '~/components/ModalSaveMoney';
import Payment, { IOption } from '~/components/Payment';

interface ILocation {
  products: any;
}

const Home: React.FC = () => {
  const location = useLocation<ILocation>();
  const [show, setShow] = useState(false);

  const options = useMemo(() => {
    const hasAffiliateProduct = location.state.products?.find(
      (product: any) => {
        return (
          product.order.product_id === 'b060defe-695a-4ddd-9827-5350c3302f00'
        );
      }
    );
    if (!hasAffiliateProduct) {
      return [
        {
          price: 47,
          src: 'https://cdn.autoaffiliate.ai/files/become-affiliate.png',
          period: 1,
          type: 'subscription',
          interval: 'month',
          name: 'affiliate',
          nameSummary: 'Affiliate Program Community Access & AI Tools',
          productSlug: 'affiliate',
          selected: true,
          unSelectable: true,
          secondary: true,
        },
        {
          price: 97,
          src: 'https://cdn.autoaffiliate.ai/files/upgrade-vip-97.png',
          type: 'lifetime',
          name: 'vip',
          nameSummary: 'VIP Affiliate Upgrade',
          productSlug: 'vip-upgrade',
          selected: true,
          unSelectable: true,
        },
      ];
    }
    return [
      {
        price: 97,
        src: 'https://cdn.autoaffiliate.ai/files/upgrade-vip-97.png',
        type: 'lifetime',
        name: 'vip',
        nameSummary: 'VIP Affiliate Upgrade',
        selected: true,
        productSlug: 'vip-upgrade',
        unSelectable: true,
      },
    ];
  }, [location.state]);

  const handleSuccessfullyPaid = useCallback(async () => {
    const userData = localStorage.getItem('@AutoAffiliate:userData');

    if (userData) {
      const user = JSON.parse(userData);

      const response = await api.post('users/sessions/set-data', {
        user_id: user.id,
        data: 'auto-login',
      });

      window.location.href = `${process.env.REACT_APP_APP_URL}/check-login/${response.data.token}`;
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image:secure_url"
          itemProp="image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="autoaffiliate.ai" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <Container>
        <Attention className="">
          <div className="container zoom">
            <div className="row">
              <div className="col zoom">
                <h2 className="h6 h5-sm h4-xl text-white mb-0 py-3 text-center">
                  <FaExclamationCircle />
                  <b className="ms-3">ATTENTION: </b>
                  You{' '}
                  <b>
                    <u>Must Watch This Video</u>
                  </b>{' '}
                  To Complete Your Order!
                </h2>
              </div>
            </div>
          </div>
        </Attention>
        <Join>
          <div id="home" className="container position-relative">
            <div className="row justify-content-center zoom-lg">
              <div className="col-12 text-center position-relative">
                <Fade>
                  <h1 className="position-relative mb-3">
                    If Your Goal is to{' '}
                    <span className="master">Make $5K per Month or More</span>{' '}
                    Online,{' '}
                    <span className="archivo">
                      <b className="fw-bold text-underline">
                        Do Not Skip This Video!
                      </b>
                    </span>
                  </h1>
                  <p className="archivo pb-2 mb-4 px-xxl-5">
                    <b>Discover Exactly How</b> Our Members Can Achieve These
                    Results with Our VIP Affiliate Program.
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </Join>

        <Free>
          <div id="works" className="container">
            <div className="row justify-content-center">
              <div className="col-md-11 mb-0">
                <Fade>
                  <div className="px-5">
                    <Player
                      id="video-modal"
                      src="https://cdn.autoaffiliate.ai/videos/AA-2.0-VIP-v3.mp4"
                      thumbnail={thumbFree}
                      autoPlay
                    />
                  </div>
                </Fade>
                <div className="text-center px-5">
                  <button
                    type="button"
                    onClick={() => setShow(true)}
                    className="mt-5 btn-yes"
                  >
                    YES... I Want to become a VIP Affiliate, Add it to my order!
                  </button>
                  <button
                    type="button"
                    onClick={handleSuccessfullyPaid}
                    className="mt-5 btn-no text-center border-0 bg-transparent"
                  >
                    No thanks, just take me to the masterclass
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Free>

        <DontWait>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-11 my-4 py-4 my-lg-5 py-lg-5">
                <Disclaimer />
                <p className="terms text-center text-center mb-0 mt-5">
                  <span className="fw-500">
                    © 2024 AutoAffiliate. All right reserved
                  </span>{' '}
                  <span className="d-none d-sm-inline-block mx-3">|</span>{' '}
                  <Link
                    to={`${process.env.PUBLIC_URL}/privacy-policy`}
                    className="fw-light"
                  >
                    Privacy Policy
                  </Link>{' '}
                  <span className="mx-3">|</span>{' '}
                  <Link
                    to={`${process.env.PUBLIC_URL}/terms-service`}
                    className="fw-light"
                  >
                    Terms of Service
                  </Link>{' '}
                  <span className="mx-3">|</span>{' '}
                  <Link
                    to={`${process.env.PUBLIC_URL}/cookie-policy`}
                    className="fw-light"
                  >
                    Cookies
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </DontWait>
        <Payment
          show={show}
          onHide={() => setShow(false)}
          productName="VIP Affiliate Upgrade"
          btnText="Yes, Process My Order Now!"
          onSuccessfullyPaid={handleSuccessfullyPaid}
          options={options as any}
        />
      </Container>
    </>
  );
};

export default Home;
