import React, { useMemo } from 'react';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link, useLocation, useParams } from 'react-router-dom';

import Fade from 'react-reveal/Fade';
import { Container } from './styles';

import logo from '~/assets/logos/logo-footer.svg';
import twitter from '~/assets/icons/twitter.svg';
import facebook from '~/assets/icons/facebook.svg';
import telegram from '~/assets/icons/telegram.svg';
import instagram from '~/assets/icons/instagram.svg';

import logoBlack from '~/assets/logos/logo-footer-black.svg';
import twitterBlack from '~/assets/icons/twitter-black.svg';
import facebookBlack from '~/assets/icons/facebook-black.svg';
import telegramBlack from '~/assets/icons/telegram-black.svg';
import instagramBlack from '~/assets/icons/instagram-black.svg';
import ModalEnterEmail from '../ModalEnterEmail';
import PeWelcome from '~/pages/PeWelcome';

interface IParams {
  origin?: string;
}

const Footer: React.FC = () => {
  const params = useParams<IParams>();
  const location = useLocation();
  const peWelcomePage = '/pe-welcome';

  const origin = useMemo(() => {
    const data = location.pathname.slice(1).split('/');

    if (params.origin) {
      let originData = '';
      switch (params.origin) {
        case 'rf':
          originData = 'AI Bot Facebook';
          break;
        case 'uf':
          originData = 'User Facebook';
          break;
        case 'ue':
          originData = 'User Email';
          break;
        case 'ux':
          originData = 'User Twitter (X)';
          break;
        case 'uw':
          originData = 'User Whatsapp';
          break;
        case 'utt':
          originData = 'User Tiktok ';
          break;
        case 'ul':
          originData = 'User Linkedin';
          break;
        case 'ut':
          originData = 'User Traffic';
          break;
        case 'aat':
          originData = 'AutoAffiliate Traffic';
          break;

        default:
          originData = '';
          break;
      }

      if (data.length > 1) {
        return `${originData}, ${data[0]}, ${data[1]}`;
      }
      if (data.length > 0) {
        return `${originData}, ${data[0]}`;
      }

      return `${originData}, ${location.pathname.slice(1)}`;
    }
    if (data.length > 1) {
      return `${data[0]}, ${data[1]}`;
    }
    if (data.length > 0) {
      if (data[0].length > 0) {
        return data[0];
      }
      return 'Main Site';
    }

    return location.pathname.slice(1);
  }, [location.pathname, params.origin]);

  return (
    <Container>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-10 py-5 my-sm-5">
            <div className="row">
              <div className="col-6 col-md-3 ps-lg-5 pt-5 pt-sm-4 pt-md-0">
                <Fade>
                  <img
                    src={
                      location.pathname.includes(peWelcomePage)
                        ? logoBlack
                        : logo
                    }
                    alt="Logo"
                    className="zoom-footer"
                  />
                </Fade>
              </div>
              <Fade cascade>
                <div className="col-6 col-md-3 ps-lg-5 pb-5 pb-md-0">
                  <h2 className="mb-3">Home</h2>
                  {location.pathname === '/' ? (
                    <>
                      <AnchorLink href="#works" offset={20} className="d-block">
                        How it works
                      </AnchorLink>
                      <AnchorLink
                        href="#Stories"
                        offset={70}
                        className="d-block"
                      >
                        Success Stories
                      </AnchorLink>
                      <AnchorLink href="#faq" offset={100} className="d-block">
                        FAQs
                      </AnchorLink>
                    </>
                  ) : (
                    <>
                      <Link
                        to={`${process.env.PUBLIC_URL}/`}
                        className="d-block"
                      >
                        How it works
                      </Link>
                      <Link
                        to={`${process.env.PUBLIC_URL}/`}
                        className="d-block"
                      >
                        Success Stories
                      </Link>
                      <Link
                        to={`${process.env.PUBLIC_URL}/`}
                        className="d-block"
                      >
                        FAQs
                      </Link>
                    </>
                  )}
                  <a href="https://app.autoaffiliate.ai/" className="d-block">
                    Members Area
                  </a>
                </div>
              </Fade>
              <Fade cascade>
                <div className="col-6 col-md-3 ps-lg-5">
                  <h2 className="mb-3">Legal</h2>
                  <Link
                    to={`${process.env.PUBLIC_URL}/privacy-policy`}
                    className="d-block"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/terms-service`}
                    className="d-block"
                  >
                    Terms of Service
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/refund-policy`}
                    className="d-block"
                  >
                    Refund Policy
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/contact-consent`}
                    className="d-block"
                  >
                    Security
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/cookie-policy`}
                    className="d-block"
                  >
                    Cookies
                  </Link>
                </div>
              </Fade>

              <Fade cascade>
                <div className="col-6 col-md-3 ps-lg-5">
                  <h2 className="mb-3">Social</h2>
                  <Link
                    to={`${process.env.PUBLIC_URL}/blog`}
                    className="d-block"
                  >
                    Blog
                  </Link>
                  <Link to="/" className="d-block">
                    Social Media
                  </Link>
                  <Link to="/" className="d-block">
                    Learning
                  </Link>
                </div>
              </Fade>
              <div className="col-12">
                <Fade>
                  <hr />
                </Fade>
              </div>
              <Fade>
                <div className="col-md-6 ps-4 text-center text-md-start">
                  <span>
                    © 2024 AutoAffiliate. <br className="d-md-none" />
                    All right reserved
                  </span>
                </div>
              </Fade>
              <Fade>
                <div className="col-md-6">
                  <div className="d-flex d-md-block justify-content-around text-center text-md-end mt-5 mt-md-0 pe-md-4">
                    <a
                      href="https://www.twitter.com/AutoAffiliateHQ"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mx-md-2"
                    >
                      <img
                        src={
                          location.pathname.includes(peWelcomePage)
                            ? twitterBlack
                            : twitter
                        }
                        alt="Twitter"
                      />
                    </a>

                    <a
                      href="https://www.facebook.com/AutoAffiliatePage"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mx-md-2"
                    >
                      <img
                        src={
                          location.pathname.includes(peWelcomePage)
                            ? facebookBlack
                            : facebook
                        }
                        alt="Facebook"
                      />
                    </a>

                    <a
                      href="https://t.me/AutoAffiliateHQ"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mx-md-2"
                    >
                      <img
                        src={
                          location.pathname.includes(peWelcomePage)
                            ? telegramBlack
                            : telegram
                        }
                        alt="Telegram"
                      />
                    </a>

                    <a
                      href="https://www.instagram.com/AutoAffiliateHQ"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mx-md-2"
                    >
                      <img
                        src={
                          location.pathname.includes(peWelcomePage)
                            ? instagramBlack
                            : instagram
                        }
                        alt="Instagram"
                      />
                    </a>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
