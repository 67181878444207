import React from 'react';

import { Helmet } from 'react-helmet';
import { Container } from './styles';

const ContactConsent: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col pt-5 my-5">
            <h1>AutoAffiliate Contact Consent and Liability Waiver</h1>
            <h2>Contact Consent and Liability Waiver</h2>
            <p>
              <b>Effective Date: June 15, 2023</b>
            </p>
            <p>
              Welcome to AutoAffiliate, a service provided by SmartTech Labs,
              Inc. This Contact Consent and Liability Waiver Policy ("Policy")
              is designed to inform you of our practices regarding the
              collection, use, and protection of your contact information and to
              establish a mutual understanding of rights and responsibilities
              associated with the use of our services.
            </p>
            <p>
              By signing up for AutoAffiliate's services and providing your
              phone number and email address, you acknowledge and agree to the
              following Contact Consent and Liability Waiver ("Policy").
            </p>
            <h2>Contact Consent</h2>
            <p>
              <b>Provision of Contact Information: </b>By providing your phone
              number and email address upon signing up for AutoAffiliate's
              services, you explicitly consent to receive marketing
              communications, updates, and other related messages from us. These
              may be delivered through text messages, phone calls, emails, and
              automated dialing systems.
            </p>
            <p>
              <b>Voluntary Provision:</b> Your decision to provide contact
              information is entirely voluntary. You affirm the accuracy of the
              information provided and assume responsibility for updating it as
              necessary.
            </p>
            <p>
              <b>Withdrawal of Consent:</b> You may withdraw your consent at any
              time. Our communications will include straightforward instructions
              for opting out, or you may contact our Data Protection Officer at{' '}
              <a
                href="mailto:DPO@autoaffiliate.ai"
                target="_blank"
                rel="noreferrer"
              >
                DPO@autoaffiliate.ai
              </a>{' '}
              for assistance.
            </p>
            <h2>Clear Identification</h2>
            <p>
              Every communication sent to you will clearly identify
              AutoAffiliate as the sender. It will include our contact
              information and the purpose of the message, ensuring transparency
              and accountability in our communications.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
              AutoAffiliate takes great care in managing and securing your
              contact information. However, we recognize that no system is
              infallible. This section outlines the scope of our liability
              concerning the communications we send:
            </p>
            <ul className="my-4">
              <li>
                <b>Risks and Technical Issues:</b> We acknowledge the inherent
                risks associated with digital communication, including potential
                disruptions and technical issues. AutoAffiliate is not liable
                for damages arising from such risks beyond our control.
              </li>
              <li>
                <b>Content Accuracy:</b> While we strive to ensure the accuracy
                and reliability of the content we provide, AutoAffiliate is not
                liable for errors or omissions within these communications.
              </li>
              <li>
                <b>Third-Party Interference:</b> We cannot be held responsible
                for actions or conduct by third parties that may disrupt or
                interfere with our communications.
              </li>
              <li>
                <b>Reliance on Information:</b> AutoAffiliate disclaims
                liability for any actions taken based on the information
                provided in our communications.
              </li>
              <li>
                <b>Compliance with Laws:</b> Our liability in connection with
                the use of text messages, phone calls, emails, or automated
                dialing systems is limited to the extent permitted by applicable
                laws.
              </li>
            </ul>
            <p>
              I acknowledge that the use of text messages, phone calls, emails,
              and automated dialing systems involves inherent risks, including
              but not limited to potential disruptions, errors, delays, and
              technical issues.
            </p>
            <p>
              I understand and agree that AutoAffiliate and its affiliates,
              officers, directors, employees, and agents shall not be held
              liable for any damages, losses, or expenses arising out of or
              related to the use of text messages, phone calls, emails, or
              automated dialing systems, including but not limited to:
            </p>
            <p>
              a) Any errors, omissions, or inaccuracies in the content of the
              communications.
            </p>
            <p>
              b) Technical malfunctions or failures, including network outages
              or interruptions, that may result in the delay or non-delivery of
              communications.
            </p>
            <p>
              c) Any unauthorized access to or alteration of my contact
              information or communications.
            </p>
            <p>
              d) Any actions or conduct of third parties that may interfere with
              or disrupt the communications.
            </p>
            <p>
              e) Any reliance on the information or content provided through the
              communications.
            </p>
            <p>
              f) Any consequences resulting from my failure to promptly update
              my contact information or opt-out preferences.
            </p>

            <p className="fw-semibold">
              I agree that AutoAffiliate's liability for any claims, damages,
              losses, or expenses arising out of or related to the use of text
              messages, phone calls, emails, or automated dialing systems shall
              be limited to the extent permitted by applicable laws.
            </p>
            <h2>Indemnification</h2>
            <p>
              I agree to indemnify, defend, and hold harmless AutoAffiliate and
              its affiliates, officers, directors, employees, and agents from
              any claims, damages, losses, or expenses, including reasonable
              attorney's fees, arising out of or related to my violation of this
              Policy or any applicable laws or regulations.
            </p>
            <h2>Data Protection and Security:</h2>
            <p>
              AutoAffiliate is committed to protecting your privacy and ensuring
              compliance with the General Data Protection Regulation (GDPR) and
              other relevant privacy laws. We have implemented robust security
              measures to protect your information from unauthorized access,
              disclosure, alteration, or destruction.
            </p>
            <h2>Governing Law and Jurisdiction</h2>
            <p>
              This Policy is governed by the laws of the jurisdiction where
              AutoAffiliate is headquartered, without regard to its conflict of
              law principles. Disputes arising under this Policy will be
              resolved in the courts located in the jurisdiction of our
              headquarters.
            </p>
            <h2>Severability</h2>
            <p>
              If any provision of this Policy is deemed invalid, illegal, or
              unenforceable, the remaining provisions shall continue in full
              force and effect.
            </p>
            <p>
              By signing up for AutoAffiliate's services and providing my
              contact information, I acknowledge that I have read, understood,
              and agreed to the terms and conditions outlined in this Contact
              Consent and Liability Waiver. I confirm that I am of legal age and
              have the authority to enter into this agreement. I understand that
              by agreeing to this policy, I am granting AutoAffiliate permission
              to contact me using the provided contact information for marketing
              and communication purposes. I also acknowledge that AutoAffiliate
              has outlined the limitations of liability regarding the use of
              text messages, phone calls, emails, and automated dialing systems.
            </p>
            <p>
              I understand that while AutoAffiliate will make efforts to provide
              accurate and timely communications, there may be risks associated
              with technical issues, disruptions, or unauthorized access. I
              agree that AutoAffiliate, its affiliates, officers, directors,
              employees, and agents shall not be held liable for any damages,
              losses, or expenses arising from these risks.
            </p>
            <p>
              I further agree to indemnify, defend, and hold harmless
              AutoAffiliate and its affiliates, officers, directors, employees,
              and agents from any claims, damages, losses, or expenses resulting
              from my violation of this Policy or any applicable laws or
              regulations.
            </p>
            <p>
              I acknowledge that this Policy is governed by the laws of the
              jurisdiction in which AutoAffiliate is headquartered, and any
              disputes arising from this Policy shall be subject to the
              exclusive jurisdiction of the courts in that jurisdiction.
            </p>
            <p>
              If any provision of this Policy is deemed invalid, illegal, or
              unenforceable, the remaining provisions shall remain in full force
              and effect.
            </p>
            <p>
              By accepting this policy, I confirm that I have read, understood,
              and agreed to the terms and conditions set forth in this
              AutoAffiliate Contact Consent and Liability Waiver.
            </p>
            <h2>Acceptance of Terms:</h2>
            <p>
              By using AutoAffiliate's services and providing your contact
              information, you confirm that you have read, understood, and
              agreed to this Policy. You acknowledge that this consent allows
              AutoAffiliate to use your contact information for the purposes
              outlined herein.
            </p>
            <h2>Changes to This Policy:</h2>
            <p>
              AutoAffiliate reserves the right to update or modify this Policy
              at any time. Changes will be communicated through our website or
              directly to you as required by applicable law.
            </p>
            <h2>Contact Us:</h2>
            <p>
              For questions or concerns about this Policy, please contact us at:
            </p>
            <ul className="my-4">
              <li>
                <b>Email: </b>

                <a
                  href="mailto:support@AutoAffiliate.ai"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@AutoAffiliate.ai
                </a>
              </li>
              <li>
                <b>Address:</b> SmartTech Labs Inc. DBA AutoAffiliate, 30 N
                Gould St Ste R, Sheridan, WY, 92801
              </li>
            </ul>
            <p>
              Your privacy and trust are paramount to us, and we are dedicated
              to handling your information responsibly and with respect.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactConsent;
