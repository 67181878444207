import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Inter';
  h1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    color: #000000;
    margin-top: 4rem;
  }
  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #151515;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #151515;
    margin-bottom: 1.5rem;
  }

  ul {
    margin: 3rem 0;
  }
  p,
  li,
  a {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #151515;
  }

  p {
    margin-bottom: 1.5rem;
  }

  a {
    text-decoration: underline !important;
  }

  u {
    font-weight: 700;
  }

  @media screen and (max-width: 991px) {
    h1 {
      font-size: 40px;
      line-height: 50px;
    }

    h2 {
      font-size: 30px;
    }
  }
`;
