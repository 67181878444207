import styled from 'styled-components';

export const Container = styled.div`
  h2.title {
    color: #606060;

    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 133.336%;
  }

  p {
    color: #9490a7;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
    line-height: 143%;
    margin-bottom: 10px;
    span {
      font-weight: 600;
    }
  }

  @media screen and (max-width: 768px) {
    border: 1px solid #bcb2b2;
    border-radius: 40px;
  }
`;
