import React, { useEffect, useMemo, useState } from 'react';

import Lottie from 'react-lottie';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Container, DontWait, Join } from './styles';
import ArrowDown from '~/assets/animations/arrow-down.json';

import person1 from '~/assets/defaults/person1.png';
import person1Mobile from '~/assets/defaults/person1-mobile.png';
import person2 from '~/assets/defaults/person2.png';
import person2Mobile from '~/assets/defaults/person2-mobile.png';
import person3 from '~/assets/defaults/person3.png';

import cube from '~/assets/defaults/cube.png';
import person4 from '~/assets/defaults/person4.png';
import Disclaimer from '~/components/Disclaimer';
import ModalEnterEmail from '~/components/ModalEnterEmail';
import { useResize } from '~/hooks/Resize';

interface IParams {
  reference?: string;
  origin?: string;
}

const Lp: React.FC = () => {
  const { width } = useResize();
  const params = useParams<IParams>();
  const [reference, setReference] = useState('');

  useEffect(() => {
    if (params.reference) {
      localStorage.setItem('@AutoAffiliate:reference', params.reference);
      setReference(params.reference);
    } else {
      const referenceData = localStorage.getItem('@AutoAffiliate:reference');
      if (referenceData) {
        setReference(referenceData);
      }
    }
  }, [params.reference]);

  return (
    <Container>
      <Join>
        <div id="home" className="container position-relative">
          <div className="row justify-content-center zoom-lg">
            <div className="col-12 text-center position-relative">
              <h1 className="position-relative">
                {' '}
                <b className="fw-semibold">Discover How</b> to{' '}
                <span className="master">Make Money Online Faster</span>{' '}
                <span className="archivo">
                  Than <b className="fw-semibold">EVER before!</b>
                </span>
              </h1>
              <p className="archivo pb-2 mb-5 px-xl-5">
                <span className="master">Using</span> <b>proven strategies,</b>{' '}
                cutting-edge <b>AI tools, </b>
                and a <b>LEAD Generation Bot</b> that works for you 24/7!
              </p>

              <ModalEnterEmail
                btnText={`FREE VIDEO REVEALS ALL ${
                  width > 768 ? '-' : ''
                } CLICK HERE TO WATCH`}
                className="text-white banner-free rounded-pill border-0"
                origin="Simple Landing Page"
              />

              <Lottie
                options={{
                  animationData: ArrowDown,
                  autoplay: true,
                  loop: true,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={150}
                width={150}
              />

              <img
                src={width > 991 ? person1 : person1Mobile}
                alt="person1"
                className="pe-none position-absolute person1 zoom"
              />

              <img
                src={width > 991 ? person2 : person2Mobile}
                alt="person2"
                className="pe-none position-absolute person2 zoom"
              />

              <img
                src={width > 991 ? cube : person4}
                alt="cube"
                className="pe-none position-absolute cube zoom"
              />
              <img
                src={person3}
                alt="person3"
                className="pe-none position-absolute person3 zoom"
              />
            </div>
          </div>
        </div>
      </Join>
      <DontWait>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-11 my-4 py-4 my-lg-5 py-lg-5">
              <Disclaimer />
              <p className="terms text-center text-center mb-0 mt-4">
                <span className="fw-500">
                  © 2024 AutoAffiliate. All right reserved
                </span>{' '}
                <span className="d-none d-sm-inline-block mx-3">|</span>{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/privacy-policy`}
                  className="fw-light"
                >
                  Privacy Policy
                </Link>{' '}
                <span className="mx-3">|</span>{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/terms-service`}
                  className="fw-light"
                >
                  Terms of Service
                </Link>{' '}
                <span className="mx-3">|</span>{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/cookie-policy`}
                  className="fw-light"
                >
                  Cookies
                </Link>
              </p>
            </div>
          </div>
        </div>
      </DontWait>
    </Container>
  );
};

export default Lp;
